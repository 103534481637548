import { Fragment, useEffect, useState } from "react";

import emptyFolderImg from "../../../img/empty-folder.svg";

import "./customersSalesRecords.scss";

import upDarkIcon from "../../../icons/up-dark.png";

import ConnectionError from "../../../components/connection-error/connectionError";
import Spinner from "../../../components/spinner/spinner";
import SaleMiniCard from "../saleMiniCard/saleMiniCard";
import Spinner2 from "../../../components/spinner2/spinner2";

const CustomersSalesRecords = ({
    headerHeight,
    salesDisplaying, 
    loadingRecords, 
    recordsConnectionError, 
    tryRecordsAgain,
    noTransactionsTitle,
    noTransactionsMsg,
    loadingMoreSales,
    loadMoreSales,
    lessThan10Sales,
    noMoreSales,
}) => {
    const [ empty, setEmpty ] = useState(() => {
        if (!salesDisplaying.length > 0) {
            return true;
        } else {
            return false;
        }
    });

    useEffect(() => {
        if (!salesDisplaying.length > 0) {
            setEmpty(true);
        } else {
            setEmpty(false);
        }
    }, [salesDisplaying]);

    const onClickLink = (id) => {
        return;
    }

    const [ onTop, setOnTop ] = useState(true);
    const [ topRef, setTopRef ] = useState(null);

    const [ onBottom, setOnBottom ] = useState(false);
    const [ bottomRef, setBottomRef ] = useState(null);

    useEffect(() => {
        if((!lessThan10Sales) && onBottom && (!loadingMoreSales) && (!noMoreSales)) {
            if (salesDisplaying.length > 0) {
                loadMoreSales();
            }
        }
    // eslint-disable-next-line    
    }, [onBottom]);

    const setTopReference = (inputElement)=>{
        setTopRef(inputElement);
    }

    const setBottomReference = (inputElement)=>{
        setBottomRef(inputElement);
    }

    const handleClickTop = () => {
        if (topRef) {
            topRef.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const toObserver = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
                setOnTop(true);
            } else {
                setOnTop(false);
            }
          },
          {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
          }
        );
    
        if (topRef) {
            toObserver.observe(topRef);
        }
    
        return () => {  
          if (topRef) { 
            // eslint-disable-next-line
            toObserver.unobserve(topRef);
          }
        };
    }, [topRef]);

    useEffect(() => {
        const bottomObserver = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
                setOnBottom(true);
            } else {
                setOnBottom(false);
            }
          },
          {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
          }
        );
    
        if (bottomRef) {
            bottomObserver.observe(bottomRef);
        }
    
        return () => {  
          if (bottomRef) { 
            // eslint-disable-next-line
            bottomObserver.unobserve(bottomRef);
          }
        };
    }, [bottomRef]);

    return (
        <div className="customer-sales-rec-main-container">
            <div 
                className="cust-account-sales-rec-records"
                style={{
                    minHeight: `calc(100vh - ${headerHeight + 175 - 40 + (onTop ? 0 : 40) }px)`, 
                    maxHeight: `calc(100vh - ${headerHeight + 175 - 40 + (onTop ? 0 : 40)}px)`,
                }}   
            >
                {loadingRecords ?
                    <div 
                        className="cust-account-history-spinner-cont"
                    >
                        <Spinner />
                    </div> 
                :
                 <Fragment>
                    {recordsConnectionError ? <ConnectionError onTryAgain={tryRecordsAgain} /> : <Fragment>
                        {empty ? 
                            <div className="no-sales-rec-yet-main-container">
                                <img className="no-sales-rec-yet-main-img" src={emptyFolderImg} alt="No sales yet" />
                                <p><b>{noTransactionsTitle}</b></p>
                                <p>{noTransactionsMsg}</p>
                            </div>
                        : 
                            <div className="cust-sales-rec-all-cards-main-cont">

                                <div style={{height: "10px"}} ref={setTopReference} />

                                {salesDisplaying.map((sale) => {
                                    return (
                                        <SaleMiniCard
                                            key={sale.id}
                                            sale={sale}
                                            activeId={null}
                                            setActiveReference={null}
                                            onClickLink={onClickLink}
                                            width={null}
                                        />
                                    );
                                })}

                                <div ref={setBottomReference} style={loadingMoreSales ? {height: "80px"} : {height: "20px"}} >
                                    {loadingMoreSales ? 
                                        <div className="cust-account-history-con-more-notes-con">
                                            <Spinner2 />
                                        </div>
                                    : null}
                                </div>

                            </div>
                        }
                    </Fragment>}
                </Fragment>}
            </div>
            <div className="cust-account-history-con-buttons">
                {onTop ? null : 
                    <button 
                        onClick={handleClickTop} 
                        className="cust-account-history-con-buttons-elem"
                        style={{minWidth: "60px"}}
                    >
                        <img src={upDarkIcon} alt="Go Up" />
                    </button>
                }
            </div>
        </div>
    );
}

export default CustomersSalesRecords;