import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { AuthContext } from "../../../firebase/context";
import { AppContext } from "../../../context/AppContext";

import { SideMenuContext } from "../../../context/SideMenuContext";
import { SoftAlertContext } from '../../../components/soft-alert/softAlertContext';
import { AlertContext } from "../../../components/alert/alertContext";

import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";

import crossWhiteIcon from "../../../icons/cross-white.png";
import saveIcon from "../../../icons/check-dark.png";

import warningIcon from "../../../icons/warning.png";

import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';

import SupplierCreateTable from "../../../appTools/appToolsComponents/supplierCreateTable/supplierCreateTable";

import firebase from "../../../firebase";
import { doc, getDocFromServer, getCountFromServer, collection } from "firebase/firestore";

import { useLocation, useNavigate } from "react-router-dom";

const AddingSuppliersPage = () => {
    let observedRef = useRef(null);

    const { accountData, accessTo } = useContext(AuthContext);
    const { appTheme, appLanguage, setDropDownCartOpen, setDropDownProfileMenuOpen, dropDownCartOpen, dropDownProfileMenuOpen, } = useContext(AppContext);

    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    const { setAlertData, setAlertActive, getErrorDescription } = useContext(AlertContext);

    const { desktopView, windowDimension, isSideMenuMinimized } = useContext(SideMenuContext);

    const [ fieldAlert, setFieldAlert ] = useState(null);

    const [makeSticky, setMakeSticky] = useState(true);

    useEffect(() => {
        if (dropDownCartOpen || dropDownProfileMenuOpen) {
            setMakeSticky(false);
        } else {
            setMakeSticky(true);
        }
    }, [dropDownCartOpen, dropDownProfileMenuOpen]);

    const [ fieldAlertRef, setFieldAlertRef ] = useState(null);
    const fieldAlertReference = (inputElement)=>{
        setFieldAlertRef(inputElement);
    }

    const [ maximumQuantity, setMaximumQuantity ] = useState(30);

    const [ duplicatedRNCs, setDuplicatedRNCs ] = useState([]);
    const [ duplicatedInTable, setDuplicatedInTables ] = useState([]);

    const removeThisDuplicatedFromTable = (rncToRemoved) => {
        let temDuplicatedInTable = [...duplicatedInTable];
        let newDuplicatedInTable = temDuplicatedInTable.filter(item => item !== rncToRemoved);
        setDuplicatedInTables(newDuplicatedInTable);
    }

    const location = useLocation();
    const [newRecords, setNewRecords] = useState(null);

    useEffect(() => {
        if (newRecords) {
            setNewRecords(() => {
                const records = [];
                for (let i = 0; i < newRecords.length && i < maximumQuantity; i++) {
                    records.push(newRecords[i]);
                }
                return records;
            });
        } else {
            setNewRecords(() => {
                const records = [];
                for (let i = 0; i < 30 && i < maximumQuantity; i++) {
                    const record = {
                        "number": i + 1,
                        "name": "",
                        "phone": "",
                        "email": "",
                        "rnc": "",
                        "address": "",
                    }
                    records.push(record);
                }
                return records;
            });
        }
    // eslint-disable-next-line
    }, [maximumQuantity]);

    useEffect(() => {
        if (location.state) {
            if (location.state.newRecords) {
                const tempRecords = location.state.newRecords;
                setNewRecords(tempRecords);
            }
        }
        // eslint-disable-next-line 
    }, []);

    const [ supplierLimits, setSupplierLimits ] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showConnectionError, setShowConnectionError] = useState(false);

    const [width, setWidth] = useState(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            } else {
                try {
                    const width = observedRef.current.offsetWidth - 11
                    return width;
                } catch {
                    return;
                }
            }
        } else {
            return;
        }
    });

    useEffect(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (observedRef.current.offsetWidth !== width) {
                        setWidth(observedRef.current.offsetWidth - 11);
                    }
                } catch { }
            });

            resizeObserver.observe(observedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [observedRef.current, windowDimension]);

    let headerObservedRef = useRef(null);

    const [headerHeight, setHeaderHeight] = useState();

    useEffect(() => {
        if (headerObservedRef) {
            if (!headerObservedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (headerObservedRef.current.offsetHeight !== headerHeight) {
                        setHeaderHeight(headerObservedRef.current.offsetHeight);
                    }
                } catch { }
            });

            resizeObserver.observe(headerObservedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [headerObservedRef.current, windowDimension, width, isSideMenuMinimized]);

    const onScroll = () => {
        setDropDownCartOpen(false);
        setDropDownProfileMenuOpen(false);
    }

    const navigate = useNavigate();

    const goBack = () => {
        if (window.history.state && window.history.state.idx > 0) {
          navigate(-1);
        } else {
          navigate('/', { replace: true });
        }
    }

    const getColumName = (y) => {
        switch (y) {
            case 1:
                return "name"
            case 2:
                return "phone"
            case 3:
                return "email"
            case 4:
                return "rnc"
            case 5:
                return "address"
            default:
                break;
        }
    }

    const checkIfRecordEmpty = (recordToCheck) => {
        if (recordToCheck) {
            const name = recordToCheck[getColumName(1)];
            const phon = recordToCheck[getColumName(2)];
            const emai = recordToCheck[getColumName(3)];
            const crnc = recordToCheck[getColumName(4)];
            const addr = recordToCheck[getColumName(5)];

            if (
                name === "" &&
                phon === "" &&
                emai === "" &&
                crnc === "" &&
                addr === ""
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return true
        }
    }

    useEffect(() => {
        if (fieldAlertRef) {
            fieldAlertRef.scrollIntoView({
                block: 'center',
                inline: 'nearest',
                behavior: "smooth"
            });
        }
    // eslint-disable-next-line 
    }, [fieldAlertRef]);

    const isRNCAlert = (rnc) => {
        if (duplicatedRNCs.includes(rnc)) {
            return true;
        } else {
            return false;
        }
    }

    const isAnyRNCAlert = (newRecords) => {
        if (newRecords) {
            let isAnyRNC = false
            newRecords.forEach(record => {
                if (isRNCAlert(record.rnc.trim())) {
                    isAnyRNC = true;
                }
            });
            return isAnyRNC;
        } else {
            return false
        }
    }

    async function getSuppliersLimit() {
        setLoading(true);
        setShowConnectionError(false);

        try {
            const planRef = doc(firebase.db, "plans", accountData.plan);
            const planSnap = await getDocFromServer(planRef);

            const coll = collection(firebase.db, `accounts/${accountData.id}/suppliers`);
            const snapshot = await getCountFromServer(coll);

            setMaximumQuantity(snapshot.data().count >= planSnap.data().suppliers ? 0 : planSnap.data().suppliers - snapshot.data().count);

            setSupplierLimits({
                "limit": planSnap.data().suppliers,
                "current": snapshot.data().count,
                "createNew": snapshot.data().count >= planSnap.data().suppliers ? false : true
            });

            
        } catch (error) {
            console.log(error);
            setShowConnectionError(true);
        }

        setLoading(false);
    }

    async function createSuppliers(recordsToCreate) {
        setLoading(true);

        try {
            const res = await firebase.useCreateSuppliers({
                "recordsToCreate": recordsToCreate,
                "businessId": accountData.id
            });
            
            if (res.msg === "Limit exceeded") {
                setSupplierLimits({
                    "limit": res.limit,
                    "current": res.current,
                    "createNew": false
                });
                setMaximumQuantity(res.current >= res.limit ? 0 : res.limit - res.current);
                setAlertData({
                    type: 'error',
                    title: {
                        en: `Error trying to create the suppliers`,
                        es: `Error al intentar crear los proveedores`
                    },
                    code: "Limit exceeded",
                    description: {
                        en : "You can't add more suppliers to this business account because it has exceeded the established limit.",
                        es : "No puedes agregar más proveedores a esta cuenta de negocios porque has excedido el límite establecido."
                    }
                });
                setAlertActive(true);
                setLoading(false);
            } else {
                if (res.msg === "Quota exceeded") {
                    setSupplierLimits({
                        "limit": res.limit,
                        "current": res.current,
                        "createNew": true
                    });
                    setMaximumQuantity(res.current >= res.limit ? 0 : res.limit - res.current);
                    setAlertData({
                        type: 'error',
                        title: {
                            en: `Error trying to create the suppliers`,
                            es: `Error al intentar crear los proveedores`
                        },
                        code: "Quota exceeded",
                        description: {
                            en : 'The number of suppliers you are trying to add exceeds the established limit.',
                            es : 'La cantidad de proveedores que estás intentando agregar excede el límite establecido.'
                        }
                    });
                    setAlertActive(true);
                    setLoading(false);
                } else {
                    if (res.msg === "Duplicate RNC") {
                        setSupplierLimits({
                            "limit": res.limit,
                            "current": res.current,
                            "createNew": true
                        });
                        
                        setDuplicatedRNCs(res.duplicatedRNCs);
    
                        setAlertData({
                            type: 'error',
                            title: {
                                en: 'Error trying to create the supplier records',
                                es: 'Error al intentar crear los registro de proveedores'
                            },
                            code: "Duplicate RNCs",
                            description: {
                                en : 'It is not possible to assign a cédula or RNC that is already associated with another supplier.',
                                es : 'No es posible asignar una cédula o RNC que ya esté asociada a otro proveedor.'
                            }
                        });
                        setAlertActive(true);
                        setLoading(false);

                        let errorRecordNumber = null;
                
                        recordsToCreate.every(record => {
                            if (res.duplicatedRNCs.includes(record.rnc)) {
                                errorRecordNumber = record.number;
                                return false;
                            } 
                            return true;
                        });

                        if (errorRecordNumber) {
                            setFieldAlert({
                                x: errorRecordNumber,
                                y: 4,
                                en: "The cédula or RNC cannot be duplicated",
                                es: "La cédula o RNC no puede estar repetida",
                            });
                        }
                    } else {
                        setAlertData({
                            type : 'success',
                            title: {
                                en : 'Suppliers created successfully',
                                es : 'Proveedores creados de manera exitosa'
                            },
                            code : '',
                            description : {
                                en : `All suppliers have been created successfully`,
                                es : `Todos los proveedores han sido creados de manera exitosa`
                            }
                        });
                        setAlertActive(true);
                        navigate('/proveedores', { replace: true });
                    }
                }
            }
        } catch (error) {
            setLoading(false);
            const { code } = JSON.parse(JSON.stringify(error));
            console.log(error);
            setAlertData({
                type: 'error',
                title: {
                    en: `Error trying to create the suppliers`,
                    es: `Error al intentar crear los proveedores`
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
        }
    }

    const onTryAgain = e => {
        e.preventDefault();
        getSuppliersLimit();
    }

    useEffect(() => {
        getSuppliersLimit();
        // eslint-disable-next-line
    }, []);

    const validateEmail = (email) => {
        return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };

    const invalidPhone = (text) => {
        if (text.length < 10 ) {
            return true;
        } else {
            return false;
        }
    }

    const invalidRNC = (text) => {
        if (text.length === 9 || text.length === 10 || text.length === 11) {
            return false;
        } else {
            return true;
        }
    }

    const getPhoneError = (phone) => {
        if (phone.trim() !== "") {
            if (invalidPhone(phone.trim())) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
        
    const getEmailError = (email) => {
        if (email.trim() !== "") {
            if(!validateEmail(email.trim())) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
        
    const getRNCError = (rnc) => {
        if (rnc.trim() !== "") {
            if (invalidRNC(rnc.trim())) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const checkIfDuplicated = (rnc) => {
        if (rnc.trim() !== "") {
            let rncCounter = 0;
            for (let i = 0; i < newRecords.length; i++) {
                const rncToCheck = newRecords[i].rnc.trim();
                if (rncToCheck !== ""){
                    if (rncToCheck === rnc.trim()) {
                        rncCounter++;
                    }
                }
            }
            if (rncCounter > 1) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const getLastRecordWithRNCRepeted = (rncDuplicatedInTable) => {
        const array1 = [...duplicatedRNCs];
        const array2 = [...rncDuplicatedInTable];

        const combinedArray = array1.concat(array2)
        const allRepetedRNCs = [...new Set(combinedArray)];

        for (let i = newRecords.length - 1; i >= 0; i--) {
            // Retorna el numero primer elemento con codigo de barra repetido
            if (allRepetedRNCs.includes(newRecords[i].rnc.trim())) {
                return newRecords[i].number;
            }
        }
        
        return false;
    }

    const onClickSave = () => {
        setFieldAlert(null);
        fieldAlertReference(null);

        const recordsToCreate = [];
        const rncDuplicatedInTable = []

        for (let i = 0; i < newRecords.length; i++) {
            const record = newRecords[i];
            if (!checkIfRecordEmpty(record)) {

                if (record.name.trim() === "") {
                    setFieldAlert({
                        x: i + 1,
                        y: 1,
                        en: "The name cannot be empty",
                        es: "El nombre no puede estar vacío", 
                    });

                    setMakeSticky(false);

                    setTimeout(() => {
                        if (!(dropDownCartOpen || dropDownProfileMenuOpen)) {
                            setMakeSticky(true);
                        }
                    }, 100);

                    return;
                }

                if (getPhoneError(record.phone)) {
                    setFieldAlert({
                        x: i + 1,
                        y: 2,
                        en: "Phone is not valid",
                        es: "El número de teléfono no es válido", 
                    });
                    return;
                }
                
                if (getEmailError(record.email)) {
                    setFieldAlert({
                        x: i + 1,
                        y: 3,
                        en: "Email is not valid",
                        es: "El correo no es válido", 
                    });
                    return;
                } 

                if (getRNCError(record.rnc)) {
                    setFieldAlert({
                        x: i + 1,
                        y: 4,
                        en: "Cédula or RNC is not valid",
                        es: "La cédula o RNC no es válido", 
                    });
                    return;
                }

                if (checkIfDuplicated(record.rnc)) {
                    rncDuplicatedInTable.push(record.rnc.trim());
                    // To trigger the alert from later code
                    break;
                }

                recordsToCreate.push(record);
            }
        }

        if (rncDuplicatedInTable.length > 0) {
            setDuplicatedInTables(rncDuplicatedInTable);

            setFieldAlert({
                x: getLastRecordWithRNCRepeted(rncDuplicatedInTable),
                y: 4,
                en: "The cédula or RNC cannot be duplicated",
                es: "La cédula o RNC no puede estar repetida",
            });

            return;
        }

        if (isAnyRNCAlert(newRecords)) {
            return;
        }

        if (recordsToCreate.length > 0) {
            createSuppliers(recordsToCreate);
        } else {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'No records to save',
                    es: 'No hay registros para guardar',
                }
            });
        }
    }

    useEffect(() => {
        const newState = {
            "newRecords": newRecords,
        }

        navigate(null, { replace: true, state: newState });

        // eslint-disable-next-line
    }, [newRecords]);

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Add suppliers' : 'Agregar proveedores'}</title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && accessTo ? <Fragment>
                    {accountData.active ?
                        <div className={`page-choose-account-item`}>
                            {accessTo.suppliers ? <Fragment>
                                <div ref={headerObservedRef}>
                                    {desktopView ?
                                        <ToolTitle
                                            icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                            text={appLanguage.en ? 'Add suppliers' : 'Agregar proveedores'}
                                        />
                                    : null}
                                    <div className="inven-first-secct-conte">
                                        {supplierLimits && supplierLimits.createNew ?
                                            <div className="inven-first-secct-adding-buttons">
                                                {fieldAlert ? 
                                                    <div
                                                        className="invent-adding-product-alert-text-p" 
                                                        style={{flexGrow: 4}}
                                                    >
                                                        <img src={warningIcon} alt="" />
                                                        <p>{appLanguage.en ? fieldAlert.en : fieldAlert.es}</p>
                                                    </div>
                                                :
                                                    supplierLimits ? 
                                                        <div
                                                            className="invent-adding-product-alert-text-p" 
                                                            style={{flexGrow: 4}}
                                                        >
                                                            <p>{supplierLimits.current.toLocaleString()} {appLanguage.en ? "of" : "de"} {supplierLimits.limit.toLocaleString()} {appLanguage.en ? "suppliers" : "proveedores"}</p>
                                                        </div>
                                                    : null
                                                }

                                                {loading ? null :
                                                    <div className="inven-first-section-div-btn" style={{ marginBottom: "15px"}}>
                                                        <button
                                                            onClick={goBack}
                                                            className="new-customer-bottons-button-save"
                                                        >
                                                            <img src={crossWhiteIcon} alt="Cancel" />
                                                            {appLanguage.en ? <p>Cancel</p> : <p>Cancelar</p>}
                                                        </button>
                                                        <button 
                                                            onClick={onClickSave}
                                                            className="new-customer-bottons-button-save"
                                                        >
                                                            <img src={saveIcon} alt="Save" />
                                                            {appLanguage.en ? <p>Save</p> : <p>Guardar</p>}
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        : null}
                                    </div>
                                </div>
                                <SupplierCreateTable
                                    onScroll={onScroll}
                                    makeSticky={makeSticky}
                                    headerHeight={headerHeight}
                                    loading={loading}
                                    fieldAlert={fieldAlert} 
                                    setFieldAlert={setFieldAlert}
                                    newRecords={newRecords} 
                                    setNewRecords={setNewRecords}
                                    fieldAlertReference={fieldAlertReference}
                                    getColumName={getColumName}
                                    supplierLimits={supplierLimits}
                                    showConnectionError={showConnectionError}
                                    onTryAgain={onTryAgain}
                                    duplicatedRNCs={duplicatedRNCs}
                                    duplicatedInTable={duplicatedInTable}
                                    removeThisDuplicatedFromTable={removeThisDuplicatedFromTable}
                                />
                            </Fragment> : null}
                        </div>
                        : null}
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

export default AddingSuppliersPage;