import { Fragment, useContext, useEffect, useRef, useState } from "react";

import { Helmet } from "react-helmet-async";
import { AuthContext } from "../../../firebase/context";
import { AppContext } from "../../../context/AppContext";

import { SideMenuContext } from "../../../context/SideMenuContext";
import { SoftAlertContext } from '../../../components/soft-alert/softAlertContext';
import { AlertContext } from "../../../components/alert/alertContext";

import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";

import crossWhiteIcon from "../../../icons/cross-white.png";
import saveIcon from "../../../icons/check-dark.png";

import warningIcon from "../../../icons/warning.png";
import warningIcon2 from "../../../img/warning.svg";

import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';

import SupplierEditTable from "../../../appTools/appToolsComponents/supplierEditTable/supplierEditTable";

import firebase from "../../../firebase";
import { doc, getDocFromServer, getCountFromServer, collection } from "firebase/firestore";

import { useLocation, useNavigate } from "react-router-dom";
import SupplierUpload from "../../../appTools/appToolsComponents/supplierUpload/supplierUpload";
import ConnectionError from "../../../components/connection-error/connectionError";
import Spinner from "../../../components/spinner/spinner";

const UploadingSuppliersPage = () => {
    let observedRef = useRef(null);

    const navigate = useNavigate();

    const { accountData, accessTo } = useContext(AuthContext);
    const { appTheme, appLanguage, setDropDownCartOpen, setDropDownProfileMenuOpen, dropDownCartOpen, dropDownProfileMenuOpen, } = useContext(AppContext);

    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    const { setAlertData, setAlertActive, getErrorDescription } = useContext(AlertContext);

    const { desktopView, windowDimension, isSideMenuMinimized } = useContext(SideMenuContext);

    const [showingTable, setShowingTable] = useState(null);

    const [fieldAlert, setFieldAlert] = useState(null);

    const [makeSticky, setMakeSticky] = useState(true);

    useEffect(() => {
        if (dropDownCartOpen || dropDownProfileMenuOpen) {
            setMakeSticky(false);
        } else {
            setMakeSticky(true);
        }
    }, [dropDownCartOpen, dropDownProfileMenuOpen]);

    const [fieldAlertRef, setFieldAlertRef] = useState(null);
    const fieldAlertReference = (inputElement) => {
        setFieldAlertRef(inputElement);
    }

    const [duplicatedRNCs, setDuplicatedRNCs] = useState([]);
    const [duplicatedInTable, setDuplicatedInTables] = useState([]);

    const removeThisDuplicatedFromTable = (rncToRemoved) => {
        let temDuplicatedInTable = [...duplicatedInTable];
        let newDuplicatedInTable = temDuplicatedInTable.filter(item => item !== rncToRemoved);
        setDuplicatedInTables(newDuplicatedInTable);
    }

    const location = useLocation();

    const [editingRecords, setEditingRecords] = useState(null);
    const [currentRecords, setCurrentRecords] = useState(null);
    const [recordsBeenDisplay, setRecordsBeenDisplay] = useState([]);

    const [pageNumber, setPageNumber] = useState(1);
    const [navControlers, setNavControlers] = useState(null);
    const [showPageNav, setShowPageNav] = useState(false);

    const blink = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 100);
    }

    const onClickPrevious = () => {
        blink();

        const tempRecordsBeenDisplay = [];

        const startAtIndex = ((pageNumber - 1) * 30) - 30;
        const endAtIndex = startAtIndex + 30;

        for (let i = startAtIndex; ((i < editingRecords.length) && (i < endAtIndex)); i++) {
            tempRecordsBeenDisplay.push(editingRecords[i]);
        }

        setPageNumber(pageNumber - 1);
        setRecordsBeenDisplay(tempRecordsBeenDisplay);

        setNavControlers({
            ...navControlers,
            "page": pageNumber - 1,
            "isPrevious": startAtIndex === 0 ? false : true,
            "isNext": true,
        });
    }

    const onClickNext = () => {
        blink();

        const tempRecordsBeenDisplay = [];

        const startAtIndex = ((pageNumber + 1) * 30) - 30;
        const endAtIndex = startAtIndex + 30;

        for (let i = startAtIndex; ((i < editingRecords.length) && (i < endAtIndex)); i++) {
            tempRecordsBeenDisplay.push(editingRecords[i]);
        }

        setPageNumber(pageNumber + 1);
        setRecordsBeenDisplay(tempRecordsBeenDisplay);

        setNavControlers({
            ...navControlers,
            "page": pageNumber + 1,
            "isPrevious": true,
            "isNext": endAtIndex >= editingRecords.length ? false : true,
        });
    }

    useEffect(() => {
        if (location.state) {
            if (location.state.currentRecords) {
                setShowingTable(true);
                setCurrentRecords(location.state.currentRecords);
            } else {
                setShowingTable(false);
            }

            if (location.state.editingRecords) {
                const tempRecords = location.state.editingRecords;

                const newTempRecords = [];

                for (let i = 0; i < tempRecords.length; i++) {
                    const newRecord = {
                        ...tempRecords[i],
                        "number": i + 1,
                    }
                    newTempRecords.push(newRecord)
                }

                setEditingRecords(newTempRecords);

                if (newTempRecords.length > 30) {
                    setShowPageNav(true);
                } else {
                    setShowPageNav(false);
                }

                const tempRecordsBeenDisplay = [];

                if (location.state.recordsBeenDisplay) {

                    const startAtIndex = 0;
                    const endAtIndex = startAtIndex + 30;

                    for (let i = startAtIndex; ((i < newTempRecords.length) && (i < endAtIndex)); i++) {
                        tempRecordsBeenDisplay.push(newTempRecords[i]);
                    }

                    setRecordsBeenDisplay(tempRecordsBeenDisplay);

                } else {

                    if (location.state.pageNumber) {
                        const startAtIndex = (location.state.pageNumber * 30) - 30;
                        const endAtIndex = startAtIndex + 30;

                        for (let i = startAtIndex; ((i < newTempRecords.length) && (i < endAtIndex)); i++) {
                            tempRecordsBeenDisplay.push(newTempRecords[i]);
                        }

                    } else {

                        const startAtIndex = 0;
                        const endAtIndex = startAtIndex + 30;

                        for (let i = startAtIndex; ((i < newTempRecords.length) && (i < endAtIndex)); i++) {
                            tempRecordsBeenDisplay.push(newTempRecords[i]);
                        }

                    }

                    setRecordsBeenDisplay(tempRecordsBeenDisplay);
                }
            } else {
                setShowPageNav(false)
            }

            if (location.state.pageNumber) {
                setPageNumber(location.state.pageNumber);
            } else {
                setPageNumber(1)
            }

            if (location.state.navControlers) {
                setNavControlers(location.state.navControlers);
            } else {
                setNavControlers({
                    "page": 1,
                    "totalPage": location.state.editingRecords ? Math.ceil(location.state.editingRecords.length / 30) : 1,
                    "isPrevious": false,
                    "isNext": location.state.editingRecords ? (Math.ceil(location.state.editingRecords.length / 30) > 1 ? true : false) : false,
                });
            }

        } else {
            setShowingTable(false);
        }
        // eslint-disable-next-line 
    }, []);

    const [supplierLimits, setSupplierLimits] = useState(null);
    const [loading, setLoading] = useState(false);

    const [longProcess, setLongProcess] = useState(false);

    const [loadingLimits, setLoadingLimits] = useState(true);
    const [showConnectionError, setShowConnectionError] = useState(false);

    const [width, setWidth] = useState(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            } else {
                try {
                    const width = observedRef.current.offsetWidth - 11
                    return width;
                } catch {
                    return;
                }
            }
        } else {
            return;
        }
    });

    useEffect(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (observedRef.current.offsetWidth !== width) {
                        setWidth(observedRef.current.offsetWidth - 11);
                    }
                } catch { }
            });

            resizeObserver.observe(observedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [observedRef.current, windowDimension]);

    let headerObservedRef = useRef(null);

    const [headerHeight, setHeaderHeight] = useState();

    useEffect(() => {
        if (headerObservedRef) {
            if (!headerObservedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (headerObservedRef.current.offsetHeight !== headerHeight) {
                        setHeaderHeight(headerObservedRef.current.offsetHeight);
                    }
                } catch { }
            });

            resizeObserver.observe(headerObservedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [headerObservedRef.current, windowDimension, width, isSideMenuMinimized, showingTable]);

    const onScroll = () => {
        setDropDownCartOpen(false);
        setDropDownProfileMenuOpen(false);
    }

    const goBack = () => {
        setEditingRecords(null);
        setCurrentRecords(null);
        setRecordsBeenDisplay([]);
        setPageNumber(1);
        setNavControlers(null);
        setShowPageNav(false);
        setShowingTable(false);
    }

    const getColumName = (y) => {
        switch (y) {
            case 1:
                return "name"
            case 2:
                return "phone"
            case 3:
                return "email"
            case 4:
                return "rnc"
            case 5:
                return "address"
            default:
                break;
        }
    }

    useEffect(() => {
        if (fieldAlertRef) {
            fieldAlertRef.scrollIntoView({
                block: 'center',
                inline: 'end', // nearest
                behavior: "smooth"
            });

        }
        // eslint-disable-next-line 
    }, [fieldAlertRef]);

    async function getSuppliersLimit() {
        setLoadingLimits(true);
        setShowConnectionError(false);

        try {
            const planRef = doc(firebase.db, "plans", accountData.plan);
            const planSnap = await getDocFromServer(planRef);

            const coll = collection(firebase.db, `accounts/${accountData.id}/suppliers`);
            const snapshot = await getCountFromServer(coll);

            setSupplierLimits({
                "limit": planSnap.data().suppliers,
                "current": snapshot.data().count,
                "createNew": snapshot.data().count >= planSnap.data().suppliers ? false : true
            });


        } catch (error) {
            console.log(error);
            setShowConnectionError(true);
        }

        setLoadingLimits(false);
    }

    async function createSuppliers(recordsToCreate) {
        setLoading(true);

        if (recordsToCreate.length > 1) {
            setLongProcess(true);
        }

        try {
            const res = await firebase.useCreateSuppliers({
                "recordsToCreate": recordsToCreate,
                "businessId": accountData.id
            });

            if (res.msg === "Limit exceeded") {
                setSupplierLimits({
                    "limit": res.limit,
                    "current": res.current,
                    "createNew": false
                });
                setAlertData({
                    type: 'error',
                    title: {
                        en: `Error trying to create the suppliers`,
                        es: `Error al intentar crear los proveedores`
                    },
                    code: "Limit exceeded",
                    description: {
                        en: "You can't add more suppliers to this business account because it has exceeded the established limit.",
                        es: "No puedes agregar más proveedores a esta cuenta de negocios porque has excedido el límite establecido."
                    }
                });
                setAlertActive(true);
                setLoading(false);
            } else {
                if (res.msg === "Quota exceeded") {
                    setSupplierLimits({
                        "limit": res.limit,
                        "current": res.current,
                        "createNew": true
                    });
                    setAlertData({
                        type: 'error',
                        title: {
                            en: `Error trying to create the suppliers`,
                            es: `Error al intentar crear los proveedores`
                        },
                        code: "Quota exceeded",
                        description: {
                            en: 'The number of suppliers you are trying to add exceeds the established limit.',
                            es: 'La cantidad de proveedores que estás intentando agregar excede el límite establecido.'
                        }
                    });
                    setAlertActive(true);
                    setLoading(false);
                } else {
                    if (res.msg === "Duplicate RNC") {
                        setDuplicatedRNCs(res.duplicatedRNCs);

                        setAlertData({
                            type: 'error',
                            title: {
                                en: 'Error trying to create the supplier records',
                                es: 'Error al intentar crear los registros de proveedores'
                            },
                            code: "Duplicate RNCs",
                            description: {
                                en: 'It is not possible to assign a cédula or RNC that is already associated with another supplier.',
                                es: 'No es posible asignar una cédula o RNC que ya esté asociada a otro proveedor.'
                            }
                        });
                        setAlertActive(true);
                        setLoading(false);

                        let errorRecordNumber = null;

                        editingRecords.every(record => {
                            if (res.duplicatedRNCs.includes(record.rnc)) {
                                errorRecordNumber = record.number;
                                return false;
                            }
                            return true;
                        });

                        if (errorRecordNumber) {
                            setFieldAlert({
                                x: errorRecordNumber,
                                y: 4,
                                en: "The cédula or RNC cannot be duplicated",
                                es: "La cédula o RNC no puede estar repetida",
                            });
                            const pageToNum = Math.floor((errorRecordNumber - 1) / 30) + 1;
                            if (pageToNum !== pageNumber) {
                                goToPage(pageToNum);
                            }
                        }
                    } else {
                        setAlertData({
                            type: 'success',
                            title: {
                                en: 'Suppliers created successfully',
                                es: 'Proveedores creados de manera exitosa'
                            },
                            code: '',
                            description: {
                                en: `All suppliers have been created successfully`,
                                es: `Todos los proveedores han sido creados de manera exitosa`
                            }
                        });
                        setAlertActive(true);
                        navigate('/proveedores', { replace: true });
                    }
                }
            }
        } catch (error) {
            setLoading(false);
            setLongProcess(false);
            const { code } = JSON.parse(JSON.stringify(error));
            console.log(error);
            setAlertData({
                type: 'error',
                title: {
                    en: `Error trying to create the suppliers`,
                    es: `Error al intentar crear los proveedores`
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
        }
    }

    const goToPage = (pageToNum) => {
        blink();

        const tempRecordsBeenDisplay = [];

        const startAtIndex = (pageToNum * 30) - 30;
        const endAtIndex = startAtIndex + 30;

        for (let i = startAtIndex; ((i < editingRecords.length) && (i < endAtIndex)); i++) {
            tempRecordsBeenDisplay.push(editingRecords[i]);
        }

        setPageNumber(pageToNum);
        setRecordsBeenDisplay(tempRecordsBeenDisplay);

        setNavControlers({
            ...navControlers,
            "page": pageToNum,
            "isPrevious": startAtIndex === 0 ? false : true,
            "isNext": endAtIndex >= editingRecords.length ? false : true,
        });
    }

    const onTryAgain = e => {
        e.preventDefault();
        getSuppliersLimit();
    }

    useEffect(() => {
        getSuppliersLimit();
        // eslint-disable-next-line
    }, []);

    const isRecordEmpty = (record) => {
        if (
            record.name.trim() === "" &&
            record.phone.trim() === "" &&
            record.email.trim() === "" &&
            record.rnc.trim() === "" &&
            record.address.trim() === ""
        ) {
            return true;
        } else {
            return false;
        }
    }

    const validateEmail = (email) => {
        return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };

    const invalidPhone = (text) => {
        if (text.length < 10 ) {
            return true;
        } else {
            return false;
        }
    }

    const invalidRNC = (text) => {
        if (text.length === 9 || text.length === 10 || text.length === 11) {
            return false;
        } else {
            return true;
        }
    }

    const getPhoneError = (phone) => {
        if (phone.trim() !== "") {
            if (invalidPhone(phone.trim())) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
        
    const getEmailError = (email) => {
        if (email.trim() !== "") {
            if(!validateEmail(email.trim())) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
        
    const getRNCError = (rnc) => {
        if (rnc.trim() !== "") {
            if (invalidRNC(rnc)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const checkIfDuplicated = (rnc) => {
        if (rnc.trim() !== "") {
            let rncCounter = 0;
            for (let i = 0; i < editingRecords.length; i++) {
                const rncToCheck = editingRecords[i].rnc.trim();
                if (rncToCheck !== "") {
                    if (rncToCheck === rnc.trim()) {
                        rncCounter++;
                    }
                }
            }
            if (rncCounter > 1) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const getLastRecordWithRNCRepeted = (rncDuplicatedInTable) => {
        const array1 = [...duplicatedRNCs];
        const array2 = [...rncDuplicatedInTable];

        const combinedArray = array1.concat(array2)
        const allRepetedRNCs = [...new Set(combinedArray)];

        for (let i = editingRecords.length - 1; i >= 0; i--) {
            // Retorna el numero primer elemento con codigo de barra repetido
            if (allRepetedRNCs.includes(editingRecords[i].rnc.trim())) {
                return editingRecords[i].number;
            }
        }

        return false;
    }

    const onClickSave = () => {
        setFieldAlert(null);
        fieldAlertReference(null);

        let errorRecordNumber = null;

        const rncDuplicatedInTable = []

        const recordsToCreate = [];

        editingRecords.every(record => {
            if (duplicatedRNCs.includes(record.rnc)) {
                errorRecordNumber = record.number;
                return false;
            } else {
                if (!isRecordEmpty(record)) {
                    if (record.name.trim() === "") {
                        errorRecordNumber = record.number;
                        setFieldAlert({
                            x: errorRecordNumber,
                            y: 1,
                            en: "The name cannot be empty",
                            es: "El nombre no puede estar vacío", 
                        });
                        setMakeSticky(false);
                        setTimeout(() => {
                            if (!(dropDownCartOpen || dropDownProfileMenuOpen)) {
                                setMakeSticky(true);
                            }
                        }, 100);
                        return false;
                    } else {
                        if (getPhoneError(record.phone)) {
                            errorRecordNumber = record.number;
                            setFieldAlert({
                                x: errorRecordNumber,
                                y: 2,
                                en: "Phone is not valid",
                                es: "El número de teléfono no es válido", 
                            });
                            return false;
                        } else {
                            if (getEmailError(record.email)) {
                                errorRecordNumber = record.number;
                                setFieldAlert({
                                    x: errorRecordNumber,
                                    y: 3,
                                    en: "Email is not valid",
                                    es: "El correo no es válido", 
                                });
                                return false;
                            } else {
                                if (checkIfDuplicated(record.rnc)) {
                                    rncDuplicatedInTable.push(record.rnc.trim());
                                } else {
                                    if (getRNCError(record.rnc)) {
                                        errorRecordNumber = record.number;
                                        setFieldAlert({
                                            x: errorRecordNumber,
                                            y: 4,
                                            en: "Cédula or RNC is not valid",
                                            es: "La cédula o RNC no es válido", 
                                        });
                                        return false;
                                    }
                                }
                            }
                        }
                    }
                    recordsToCreate.push(record);
                }

                return true;
            }
        });

        if (errorRecordNumber) {
            setFieldAlert({
                x: errorRecordNumber,
                y: 4,
                en: "The cédula or RNC cannot be duplicated",
                es: "La cédula o RNC no puede estar repetida",
            });
            const pageToNum = Math.floor((errorRecordNumber - 1) / 30) + 1;
            if (pageToNum !== pageNumber) {
                goToPage(pageToNum);
            }
            return;
        }

        if (rncDuplicatedInTable.length > 0) {
            errorRecordNumber = getLastRecordWithRNCRepeted(rncDuplicatedInTable);
            setDuplicatedInTables(rncDuplicatedInTable);
            setFieldAlert({
                x: errorRecordNumber,
                y: 4,
                en: "The cédula or RNC cannot be duplicated",
                es: "La cédula o RNC no puede estar repetida",
            });
            const pageToNum = Math.floor((errorRecordNumber - 1) / 30) + 1;
            if (pageToNum !== pageNumber) {
                goToPage(pageToNum);
            }
            return;
        }

        if (recordsToCreate.length > 0) {
            createSuppliers(recordsToCreate);
        } else {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'No records to save',
                    es: 'No hay registros para guardar',
                }
            });
        }
    }

    const showTable = (productLimits) => {
        if (productLimits) {
            if (productLimits.createNew) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    useEffect(() => {
        const newState = {
            "editingRecords": editingRecords,
            "pageNumber": pageNumber,
            "navControlers": navControlers,
            "showPageNav": showPageNav,
            "currentRecords": currentRecords,
        }
        navigate(null, { replace: true, state: newState });
        // eslint-disable-next-line
    }, [editingRecords, pageNumber, navControlers, showPageNav, currentRecords]);

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Import suppliers from Excel' : 'Importar proveedores desde Excel'}</title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && accessTo ? <Fragment>
                    {accountData.active ?
                        <div className={`page-choose-account-item`}>
                            {accessTo.suppliers ? <Fragment>
                                <div ref={headerObservedRef}>
                                    {desktopView ?
                                        <ToolTitle
                                            icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                            text={appLanguage.en ? 'Import suppliers from Excel' : 'Importar proveedores desde Excel'}
                                        />
                                        : null}
                                    <div className="inven-first-secct-conte">
                                        {showingTable ?
                                            <div className="inven-first-secct-adding-buttons">
                                                {fieldAlert ?
                                                    <div
                                                        className="invent-adding-product-alert-text-p"
                                                        style={{ flexGrow: 4 }}
                                                    >
                                                        <img src={warningIcon} alt="" />
                                                        <p>{appLanguage.en ? fieldAlert.en : fieldAlert.es}</p>
                                                    </div>
                                                    :
                                                    supplierLimits ?
                                                        <div
                                                            className="invent-adding-product-alert-text-p"
                                                            style={{ flexGrow: 4 }}
                                                        >
                                                            <p>{supplierLimits.current.toLocaleString()} {appLanguage.en ? "of" : "de"} {supplierLimits.limit.toLocaleString()} {appLanguage.en ? "suppliers" : "proveedores"}</p>
                                                        </div>
                                                        : null
                                                }
                                                {loading ? null :
                                                    <div className="inven-first-section-div-btn" style={{ marginBottom: "15px" }}>
                                                        <button
                                                            onClick={goBack}
                                                            className="new-customer-bottons-button-save"
                                                        >
                                                            <img src={crossWhiteIcon} alt="Cancel" />
                                                            {appLanguage.en ? <p>Cancel</p> : <p>Cancelar</p>}
                                                        </button>
                                                        <button
                                                            onClick={onClickSave}
                                                            className="new-customer-bottons-button-save"
                                                        >
                                                            <img src={saveIcon} alt="Save" />
                                                            {appLanguage.en ? <p>Save</p> : <p>Guardar</p>}
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                            : null}
                                    </div>
                                </div>

                                {loadingLimits ? <Spinner /> :
                                    (showConnectionError ? <ConnectionError onTryAgain={onTryAgain} /> :
                                        (showTable(supplierLimits) ?
                                            (showingTable ?
                                                <SupplierEditTable
                                                    onScroll={onScroll}
                                                    makeSticky={makeSticky}
                                                    headerHeight={headerHeight}
                                                    loading={loading}
                                                    longProcess={longProcess}
                                                    fieldAlert={fieldAlert}
                                                    setFieldAlert={setFieldAlert}
                                                    editingRecords={editingRecords}
                                                    setEditingRecords={setEditingRecords}
                                                    recordsBeenDisplay={recordsBeenDisplay}
                                                    fieldAlertReference={fieldAlertReference}
                                                    getColumName={getColumName}
                                                    duplicatedRNCs={duplicatedRNCs}
                                                    duplicatedInTable={duplicatedInTable}
                                                    removeThisDuplicatedFromTable={removeThisDuplicatedFromTable}
                                                    pageNumber={pageNumber}
                                                    navControlers={navControlers}
                                                    showPageNav={showPageNav}
                                                    onClickNext={onClickNext}
                                                    onClickPrevious={onClickPrevious}
                                                />
                                                :
                                                <SupplierUpload
                                                    headerHeight={headerHeight}
                                                    isRecordEmpty={isRecordEmpty}
                                                    supplierLimits={supplierLimits}
                                                    setShowPageNav={setShowPageNav}
                                                    setNavControlers={setNavControlers}
                                                    setShowingTable={setShowingTable}
                                                    setEditingRecords={setEditingRecords}
                                                    setCurrentRecords={setCurrentRecords}
                                                    setRecordsBeenDisplay={setRecordsBeenDisplay}
                                                />
                                            )
                                            :
                                            <LimitExceeded supplierLimits={supplierLimits} showConnectionError={showConnectionError} onTryAgain={onTryAgain} />
                                        )
                                    )
                                }
                            </Fragment> : null}
                        </div>
                        : null}
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

const LimitExceeded = ({ supplierLimits, showConnectionError, onTryAgain }) => {
    const { appLanguage } = useContext(AppContext);

    return (
        <Fragment>
            {showConnectionError ?
                <ConnectionError onTryAgain={onTryAgain} />
                :
                <div className="no-customer-yet-main-container">
                    {supplierLimits ? <Fragment>
                        <img className="no-customer-yet-main-img" src={warningIcon2} alt="No suppliers yet" />
                        <p><b>{appLanguage.en ? "You cannot add more suppliers" : "No puedes agregar más proveedores"}</b></p>
                        <p><b>{supplierLimits.current.toLocaleString()} {appLanguage.en ? "of" : "de"} {supplierLimits.limit.toLocaleString()} {appLanguage.en ? "suppliers" : "proveedores"}</b></p>
                        <p>{appLanguage.en ? `Only ${supplierLimits.limit.toLocaleString()} suppliers can be created per basic account. You can delete an existing supplier to add another or upgrade to a more advanced plan.` : `Sólo se pueden crear ${supplierLimits.limit.toLocaleString()} clientes por plan básica. Puedes eliminar un proveedor existente para agregar otro o actualizarte a un plan más avanzado.`}</p>
                    </Fragment> : null}
                </div>
            }
        </Fragment>
    )
}

export default UploadingSuppliersPage;