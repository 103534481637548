import { Fragment, useContext, useEffect, useState } from "react";
import { SideMenuContext } from "../../context/SideMenuContext";
import { AppContext } from '../../context/AppContext';
import { AlertContext } from "../../components/alert/alertContext";
import { useNavigate, useLocation } from "react-router-dom";

import firebase from "../../firebase";
import { AuthContext } from "../../firebase/context";
import { Helmet } from 'react-helmet-async';

import './createBusinessAccount.page.scss';

import addDark from '../../icons/add-dark.png';
import checkDark from "../../icons/check-dark.png";

import reportIcon from '../../img/report.svg';
import questionIcon from '../../img/question.svg';

import Spinner from "../../components/spinner/spinner";

const CreateBusinessAccountPage = () => {

    const { appTheme, appLanguage, formatRationalNumber, getBusinessCategory, getBusinessCategories } = useContext(AppContext);
    const [showComfirmation, setShowComfirmation] = useState(false);
    const {
        user,
        userData,
        setAccountData,
        setAccountSelected,
        partiallyEliminateCustomers
    } = useContext(AuthContext);

    const [loading, setLoading] = useState(false);
    const { setAlertData, setAlertActive, getErrorDescription } = useContext(AlertContext);
    const { desktopView } = useContext(SideMenuContext);

    const navigate = useNavigate();
    const location = useLocation();

    const [plan, setPlan] = useState(null);

    const [account, setAccount] = useState({
        "businessName": "",
        "plan": {},
        "businessEmail": user ? user.email : '',
        "businessCategory": "null",
    });

    useEffect(() => {
        try {
            setPlan(location.state);
        } catch (error) {
            navigate('/precios-disponibles', { replace: true });
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setAccount({
            ...account,
            "plan": location.state,
        });
        // eslint-disable-next-line
    }, [plan]);

    useEffect(() => {
        setAccount({
            ...account,
            businessEmail: user ? user.email : ''
        });
        // eslint-disable-next-line
    }, [user]);

    const [businessNameAlert, setBusinessNameAlert] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [businessCategoryAlert, setBusinessCategoryAlert] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const onChange = e => {
        if (e.target.name === "businessName") {
            setBusinessNameAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        } else {
            if (e.target.name === "businessCategory") {
                setBusinessCategoryAlert({
                    alert: false,
                    enText: '',
                    esText: ''
                });
            }
        }

        setAccount({
            ...account,
            [e.target.name]: e.target.value
        });
    }

    async function createBusinessAccount() {
        setLoading(true);

        try {
            const response = await firebase.useCreateBusinessAccount(account);

            setAccountData(response);
            setAccountSelected({
                "doc": "admin",
                "id": response.id,
            });

            localStorage.setItem("isAccountSelected", JSON.stringify({
                "doc": "admin",
                "id": response.id,
            }));

            localStorage.removeItem("itemsDeleted");

            partiallyEliminateCustomers();

            setLoading(false);

            setAlertData({
                type: 'success',
                title: {
                    en: 'Account created successfully',
                    es: 'Cuenta creada con éxito'
                },
                code: '',
                description: {
                    en: `You have accessed the "${account.businessName}" account from this device.`,
                    es: `Has accedido a la cuenta de "${account.businessName}" desde este dispositivo.`
                }
            });
            setAlertActive(true);

            if (plan.freeTrial) {
                navigate('/', { replace: true });
            } else {
                navigate('/facturas', { replace: true, state: { payment: false } });
            }

        } catch (error) {
            setLoading(false);
            const { code } = JSON.parse(JSON.stringify(error));
            console.log(error);
            setAlertData({
                type: 'error',
                title: {
                    en: 'Error trying to create account',
                    es: 'Error al intentar crear cuenta'
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
            navigate('/elegir-cuenta', { replace: true });
        }
    }

    const validar = e => {
        e.preventDefault();

        if (account.businessName.trim() === "") {
            setBusinessNameAlert({
                alert: true,
                enText: '* Required field',
                esText: '* Campo requerido'
            });
            return;
        } else {
            if (account.businessCategory.trim() === "null") {
                setBusinessCategoryAlert({
                    alert: true,
                    enText: '* Required field',
                    esText: '* Campo requerido'
                });
                return;
            }
        }

        setShowComfirmation(true);
    }

    const sendAccoutnData = e => {
        e.preventDefault();
        createBusinessAccount();
    }

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Create business account' : 'Crear cuenta de negocios'} </title>
            </Helmet>

            <div
                className={`page-create-account-container ${appTheme.dark ? 'dark-text' : 'light-text'}`}
                style={{
                    colorScheme: appTheme.dark ? "dark" : "light"
                }}
            >
                {loading ? <Spinner /> : <Fragment>
                    <div className="page-create-account-item">
                        <div className="page-create-accout-header">
                            <img src={reportIcon} alt="Create account icon" />
                            <h3 className="page-create-account-tittle">{appLanguage.en ? 'Company details' : 'Datos de su negocio'} </h3>
                        </div>
                        {showComfirmation ?
                            <div className="crea-accou-form-container">
                                <div className={`${desktopView ? 'page-cre-acc-card-mobile-desktop' : 'page-cre-acc-card-mobile-view'}  ${appTheme.dark ? 'dar-sec-bg dar-box-sha-keep-hover input-border-box-dark' : 'lig-sec-bg lig-box-sha-keep-hover input-border-box-light'}`}>
                                    <form className={` ${desktopView ? 'page-cre-acc-form-desktop' : 'page-cre-acc-form-mobile'}`} >
                                        <div className={` ${desktopView ? 'form-cre-acc-desktop-first-section' : 'form-cre-acc-mobile-first-section'}`}>
                                            <div className="crea-accou-form-container-title">
                                                <img className="crea-accou-form-img" src={questionIcon} alt="Confirm" />
                                                <p><b>{appLanguage.en ? "Account details" : "Detalles de la cuenta "}</b></p>
                                            </div>
                                            <br />
                                            <p style={{ paddingBottom: "8px" }}><b>{appLanguage.en ? "Business" : "Negocio "}:</b> {account.businessName}</p>
                                            <p style={{ paddingBottom: "8px" }}><b>{appLanguage.en ? "Category" : "Categoria "}:</b> {getBusinessCategory(account.businessCategory)}</p>
                                            <p style={{ paddingBottom: "8px" }}><b>{appLanguage.en ? "Email " : "Correo"}: </b>{account.businessEmail}</p>
                                            <p style={{ paddingBottom: "8px" }}><b>{appLanguage.en ? "Administrador " : "Administrador "}: </b>{userData ? userData.displayName : ""}</p>
                                            <p style={{ paddingBottom: "8px" }}><b>{appLanguage.en ? "Product" : "Producto "}: </b>{plan ? (appLanguage.en ? plan.name.en : plan.name.es) : null}</p>
                                            <p style={{ paddingBottom: "8px" }}><b>{appLanguage.en ? "Monthly price " : "Precio mensual "}: </b> RD$ {formatRationalNumber(plan.price)}</p>
                                            <p style={{ paddingBottom: "8px" }}><b>{appLanguage.en ? "Charge extra profiles " : "Cargo perfiles extras "}: </b> RD$ {formatRationalNumber(plan.extraUserPrice)}</p>
                                            {plan ? (plan.freeTrial ? <p style={{ paddingBottom: "8px" }}><b>{appLanguage.en ? "Promotion " : "Promoción "}: </b>{appLanguage.en ? "14 days free trial" : "14 días de prueba gratis"}</p> : null) : null}
                                        </div>

                                        <div className={` ${desktopView ? 'form-cre-acc-desktop-second-section' : 'form-cre-acc-mobile-second-section '}`}>

                                            {plan.freeTrial ?
                                                <PlanDetailsWithFreeTrial plan={plan} />
                                                :
                                                <PlanDetails plan={plan} />
                                            }

                                            <button
                                                className={`continue-cre-acc-button-container`}
                                                type="submit"
                                                onClick={sendAccoutnData}
                                            >
                                                <img
                                                    src={checkDark}
                                                    alt="Next button"
                                                    className='outside-cre-acc-button-container-img'
                                                />
                                                <p className='outside-cre-acc-button-container-text'>{appLanguage.en ? 'Confirm' : 'Confirmar'}</p>
                                            </button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                            :
                            <div className="crea-accou-form-container">
                                <div className={`page-cre-acc-card-mobile-view  ${appTheme.dark ? 'dar-sec-bg dar-box-sha-keep-hover input-border-box-dark' : 'lig-sec-bg lig-box-sha-keep-hover input-border-box-light'}`}>
                                    <form className={`page-cre-acc-form-mobile`} >
                                        <div className={`form-cre-acc-mobile-first-section`}>

                                            <label
                                                className="input-cre-acc-description"
                                                style={{ marginBottom: "30px"}}
                                            >
                                                <h3>{plan ? (appLanguage.en ? plan.name.en : plan.name.es) : null}</h3> {plan ? (appLanguage.en ? `RD$ ${formatRationalNumber(plan.price)} monthly` : `RD$ ${formatRationalNumber(plan.price)} mensual`) : null}
                                            </label>

                                            <label
                                                className="input-cre-acc-description"
                                                htmlFor="businessName"
                                            >
                                                {appLanguage.en ? 'Business name: ' : 'Nombre del negocio: '}
                                                <b>{businessNameAlert.alert ? (appLanguage.en ? businessNameAlert.enText : businessNameAlert.esText) : "*"}</b>
                                            </label>

                                            <input
                                                style={{ colorScheme: appTheme.dark ? "dark" : "light" }}
                                                className={`input-cre-acc-content ${businessNameAlert.alert ? 'input-content-alert' : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}
                                                type="text"
                                                name="businessName"
                                                id="businessName"
                                                maxLength="18"
                                                placeholder={appLanguage.en ? 'Type business name...' : 'Escribir el nombre del negocio...'}
                                                onChange={e => onChange(e)}
                                            />

                                            <label
                                                className="input-cre-acc-description"
                                                htmlFor="businessCategory"
                                            >
                                                {appLanguage.en ? 'Business category: ' : 'Categoria del negocio: '} 
                                                <b>{businessCategoryAlert.alert ? ( appLanguage.en ? businessCategoryAlert.enText : businessCategoryAlert.esText) : "*"}</b>
                                            </label>
                                            
                                            <select
                                                style={{colorScheme: appTheme.dark ?  "dark" : "light"}}
                                                className={`input-cre-acc-content ${businessCategoryAlert.alert ? 'input-content-alert' : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}
                                                name="businessCategory"
                                                value={account.businessCategory}
                                                onChange={e => onChange(e)}
                                            >
                                                <option value="null" >
                                                    {appLanguage.en ? "-- select --" : "-- seleccione --"}
                                                </option>
                                                {getBusinessCategories().map((businessCategory, index) => {
                                                    return ( 
                                                        <option key={index} value={businessCategory}>
                                                            {getBusinessCategory(businessCategory)}
                                                        </option>
                                                    )
                                                })}
                                            </select>

                                            <br />

                                            <button
                                                className={`outside-cre-acc-button-container`}
                                                onClick={validar}
                                            >
                                                <img
                                                    src={addDark}
                                                    alt="Create account button"
                                                    className='outside-cre-acc-button-container-img'
                                                />
                                                <p className='outside-cre-acc-button-container-text'>{appLanguage.en ? 'Create' : 'Crear'}</p>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        }
                    </div>
                </Fragment>}
            </div>
        </Fragment>
    )
}

const PlanDetails = ({ plan }) => {
    const { appTheme, appLanguage, formatRationalNumber } = useContext(AppContext);

    return (
        <div style={{ marginTop: "35px", marginBottom: "35px" }}>
            {appLanguage.en ? <Fragment>
                <p>
                    By clicking <b>"Confirm" your account will be created, but not activated. </b>
                    Once you make the payment of RD${formatRationalNumber(plan.price)} your account will be activated.
                    If extra charges are authorized, these will be reflected on your next invoice.
                </p>
                <br />
                <p>
                    You can see more details in the option: <b>Business account {">"} Bills</b>.
                </p>
                <br />
                <p>
                    By clicking Confirm you are accepting our <a className={`${appTheme.dark ? "link-to-portal-blank-dark" : "link-to-portal-blank-light"}`} href="https://portal.hazcuentas.com/terminos-y-condiciones" target="_blank" rel="noreferrer">Terms and Conditions.</a>
                </p>
            </Fragment> : <Fragment>
                <p>
                    Al presionar <b>"Confirmar" su cuenta será creada, pero no activada. </b>
                    Una vez realice el pago de RD$ {formatRationalNumber(plan.price)} su cuenta será activada.
                    En el caso de autorizar cargos extra, estos se reflejarán en su próxima factura.
                </p>
                <br />
                <p>
                    Podrás ver más detalles en la opción: <b>Cuenta de negocios {">"} Facturas</b>.
                </p>
                <br />
                <p>
                    Al dar click en Confirmar estás aceptando nuestros <a className={`${appTheme.dark ? "link-to-portal-blank-dark" : "link-to-portal-blank-light"}`} href="https://portal.hazcuentas.com/terminos-y-condiciones" target="_blank" rel="noreferrer">Términos y Condiciones.</a>
                </p>
            </Fragment>}
        </div>
    );
}

const PlanDetailsWithFreeTrial = ({ plan }) => {
    const { appTheme, appLanguage, formatRationalNumber } = useContext(AppContext);

    return (
        <div style={{ marginTop: "35px", marginBottom: "35px" }}>
            {appLanguage.en ? <Fragment>
                <p>
                    By clicking <b>"Confirm" your account will be created with a 14-day free trial. </b>
                    Once this period ends you will receive an invoice for RD${formatRationalNumber(plan.price)} plus any other charges you authorize during the month.
                    Remember that you can cancel the plan at any time if you don't like the product.
                </p>
                <br />
                <p>
                    You can see more details in the option: <b>Business account {">"} Bills</b>.
                </p>
                <br />
                <p>
                    By clicking Confirm you are accepting our <a className={`${appTheme.dark ? "link-to-portal-blank-dark" : "link-to-portal-blank-light"}`} href="https://portal.hazcuentas.com/terminos-y-condiciones" target="_blank" rel="noreferrer">Terms and Conditions.</a>
                </p>
            </Fragment> : <Fragment>
                <p>
                    Al presionar <b>"Confirmar" su cuenta será creada con una prueba gratuita de 14 días. </b>
                    Una vez finalice este periodo recibirás una factura por RD$ {formatRationalNumber(plan.price)} más cualquier otro cargo que autorice durante el mes.
                    Recuerde que puede cancelar el plan en cualquier momento si no le gusta el producto.
                </p>
                <br />
                <p>
                    Podrás ver más detalles en la opción: <b>Cuenta de negocios {">"} Facturas</b>.
                </p>
                <br />
                <p>
                    Al dar click en Confirmar estás aceptando nuestros <a className={`${appTheme.dark ? "link-to-portal-blank-dark" : "link-to-portal-blank-light"}`} href="https://portal.hazcuentas.com/terminos-y-condiciones" target="_blank" rel="noreferrer">Términos y Condiciones.</a>
                </p>
            </Fragment>}
        </div>
    );
}

export default CreateBusinessAccountPage;