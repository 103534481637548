import "./saleMiniCard.scss";
import { Link } from "react-router-dom";

import { Fragment, useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";

import completedIcon from "../../../icons/sale-completed.png";
import pendingIcon from "../../../icons/sale-pending.png";
import cancelIcon from "../../../icons/sale-cancel.png";


const SaleMiniCard = ({ sale, activeId, setActiveReference, onClickLink, width}) => {
    const { appTheme, appLanguage, isTouchEnabled, formatRationalNumber, getTimeInString, getShortDateEn, getShortDateEs} = useContext(AppContext);

    const [ time, setTime ] = useState(appLanguage.en ? getShortDateEn(sale.time) : getShortDateEs(sale.time));

    useEffect(() => {
        setTime(appLanguage.en ? getShortDateEn(sale.time) : getShortDateEs(sale.time));
    // eslint-disable-next-line
    }, [appLanguage]);

    const [ normalClassName, setNormalClassName] = useState(`recently-opened-card-div ${appTheme.dark ? (isTouchEnabled() ? "recently-opened-card-div-dark-NOHOVER" : "recently-opened-card-div-dark-HOVER") : (isTouchEnabled() ? "recently-opened-card-div-light-NOHOVER" : "recently-opened-card-div-light-HOVER")}`);
    const [ focusClassName, setFocusClassName] = useState(`recently-opened-card-div ${appTheme.dark ? (isTouchEnabled() ? "recently-opened-focus-card-div-dark-NOHOVER" : "recently-opened-focus-card-div-dark-HOVER") : (isTouchEnabled() ? "recently-opened-focus-card-div-light-NOHOVER" : "recently-opened-focus-card-div-light-HOVER")}`);

    useEffect(() => {
        setNormalClassName(`recently-opened-card-div ${appTheme.dark ? (isTouchEnabled() ? "recently-opened-card-div-dark-NOHOVER" : "recently-opened-card-div-dark-HOVER") : (isTouchEnabled() ? "recently-opened-card-div-light-NOHOVER" : "recently-opened-card-div-light-HOVER")}`);
        setFocusClassName(`recently-opened-card-div ${appTheme.dark ? (isTouchEnabled() ? "recently-opened-focus-card-div-dark-NOHOVER" : "recently-opened-focus-card-div-dark-HOVER") : (isTouchEnabled() ? "recently-opened-focus-card-div-light-NOHOVER" : "recently-opened-focus-card-div-light-HOVER")}`);
    // eslint-disable-next-line
    }, [appTheme]);

    const getSaleIcon = (status) => {
        switch (status) {
            case "completed":
                return completedIcon;
            case "canceled":
                return cancelIcon;
            default:
                return pendingIcon;
        }
    }

    return (
        <Link 
            key={sale.id}
            to={"/ver-detalles-de-venta"} 
            ref={sale.id === activeId ? setActiveReference : null}
            onClick={() => onClickLink(sale.id)}
            state={sale}
            className={sale.id === activeId ? focusClassName : normalClassName}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center"
                }}
            >
                <div style={{width: "40px", marginRight: "8px"}}>
                    <span style={{display: "flex", width: "100%", justifyContent: "center", alignItems: "center"}}>
                        <img style={{width: "25px", height: "25px"}} src={getSaleIcon(sale.status)} alt="Status" />
                    </span>
                </div>

                <div>
                    <p className="products-mini-view-product-ref"># {sale.id}</p>
                    <p 
                        className="products-mini-view-product-price" 
                        style={{fontSize: "15px"}}
                    >
                        RD$ {formatRationalNumber(sale.total)}
                    </p>
                </div>
            </div>

            {width > 530 ?
                <div 
                    style={{
                        maxWidth: "260px",
                        minWidth: "260px",
                        paddingLeft: "10px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center"
                    }}
                >
                    <p className="sales-mini-view-texto-overflow">
                        <b>{appLanguage.en ? "Items" : "Artículos"}: </b> 
                        {sale.items.map((item) => {
                            return (
                                <Fragment key={item.product.id}>
                                    ({item.quantity}: {item.product.name}),&nbsp;&nbsp;
                                </Fragment>
                            )
                        })}
                    </p>
                </div> 
            : null}

            <div 
                className="recently-opened-section2" 
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center"
                }}
            >
                <p className="recently-opened-hours">{getTimeInString(sale.time)}</p>
                <p style={{marginTop: "4px"}}>{time}</p>
            </div>
        </Link>
    );
}

export default SaleMiniCard;