import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";

const PriceListViewTable = ({ priceList, itbis }) => {
    const { appTheme, formatRationalNumber, dropDownCartOpen, dropDownProfileMenuOpen, } = useContext(AppContext);

    const [makeSticky, setMakeSticky] = useState(true);

    useEffect(() => {
        if (dropDownCartOpen || dropDownProfileMenuOpen) {
            setMakeSticky(false);
        } else {
            setMakeSticky(true);
        }
    }, [dropDownCartOpen, dropDownProfileMenuOpen]);

    const borderDark = "2px solid rgba(255, 255, 255, 0.075)";
    const border2Dark = "2px solid rgba(255, 255, 255, 0.075)";
    const border2Light = "2px solid rgba(0, 0, 0, 0.075)";

    const widthLetter = "30px";
    const widthPrice = "150px";
    const widthITBIS = "150px";
    const widthFinalPrice = "150px";

    return (
        <div
            className={`inventory-table-container ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`}
            style={{
                colorScheme: appTheme.dark ? "dark" : "light",
                borderRadius: "10px",
                maxWidth: "533px"
            }}
        >
            <div className="inventory-table-section-1" style={{overflow: "auto", height: "auto"}}>
                <table className="inventory-table-select-table">
                    <thead>
                        <tr>
                            <th className="inventory-table-select-th inventory-table-select-td-sticky " style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthLetter, maxWidth: widthLetter }}>
                                
                            </th>
                            <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthPrice, maxWidth: widthPrice }}>
                                <p>Precio sin ITBIS</p>
                            </th>
                            <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthITBIS, maxWidth: widthITBIS }}>
                                <p>ITBIS ({itbis})</p>
                            </th>
                            <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthFinalPrice, maxWidth: widthFinalPrice, borderTopRightRadius: "10px" }}>
                                <p>Precio final</p>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {priceList ?
                            priceList.map((item, index) => {
                                return (
                                    <tr
                                        style={{ cursor: "auto" }}
                                        key={index}
                                    >
                                        <td className={`inventory-table-select-td  inventory-table-select-th-sticky ${appTheme.dark ? "inventory-table-select-td-sticky-dark" : "inventory-table-select-td-sticky-light"}`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthLetter, maxWidth: widthLetter }}>
                                            <p style={{ whiteSpace: "nowrap", textAlign: "center" }}>{item.letter}</p>
                                        </td>
                                        <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthPrice, maxWidth: widthPrice }}>
                                            <p style={{ whiteSpace: "nowrap" }}> {item.price === "" ? "" : formatRationalNumber(item.price)}</p>
                                        </td>
                                        <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthITBIS, maxWidth: widthITBIS }}>
                                            <p style={{ whiteSpace: "nowrap" }}>{item.itbis === "" ? "" : formatRationalNumber(item.itbis)}</p>
                                        </td>
                                        <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthFinalPrice, maxWidth: widthFinalPrice }}>
                                            <p style={{ whiteSpace: "nowrap" }}>{item.finalPrice === "" ? "" : formatRationalNumber(item.finalPrice)}</p>
                                        </td>
                                    </tr>
                                );
                            })
                        : null}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default PriceListViewTable;