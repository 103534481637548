import "./accountHistory.scss";

import { useContext, Fragment, useEffect, useState } from "react";
import { AuthContext } from "../../../firebase/context";
import { AppContext } from "../../../context/AppContext";

import emptyFolderImg from "../../../img/empty-folder.svg";

import Spinner from "../../../components/spinner/spinner";
import ConnectionError from "../../../components/connection-error/connectionError";

import upDarkIcon from "../../../icons/up-dark.png";

import closeDarkIcon from "../../../icons/closed-dark.png";
import closeLightIcon from "../../../icons/closed-light.png";

import crossIcon from "../../../icons/cross-white.png";
import saveIcon from "../../../icons/check-dark.png";
import Spinner2 from "../../../components/spinner2/spinner2";

const AccountHistory = ({
    headerHeight,
    number,
    notesDisplaying,
    minimize, 
    loadingNotes, 
    notesConnectionError, 
    tryAgainNotes, 
    createNewNote, 
    setCreateNewNote,
    noteData,
    setNoteData,
    onClickSave,
    onClickDelete,
    loadingMoreNotes,
    loadMoreNotes,
    lessThan10,
    noMoreNotes
}) => {

    const { userData } = useContext(AuthContext);
    const { appTheme, appLanguage, getTimeInString } = useContext(AppContext);
    const [ inputAlert, setInputAlert ] = useState(false);

    const getDateInString = (date) => {
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();
        const meses = appLanguage.en ? ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"] : ["Ene", "Feb", "Mar", "Abr", "Mayo", "Jun", "Jul", "Ago", "Sept", "Oct", "Nov", "Dic"];
        return `${day} ${meses[month]}, ${year}`;
    }

    const getDate = (date) => {
        const dateObj = new Date(date)
        return getDateInString(dateObj);
    }

    const getTime = (date) => {
        const dateObj = new Date(date)
        return getTimeInString(dateObj);
    }

    const onClickAdd = () => {
        setCreateNewNote(true);
    }

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }

    const onCancel = e => {
        e.preventDefault();
        setCreateNewNote(false);
        setInputAlert(false);
        setNoteData("");
    }

    const onSave = async (e) => {
        e.preventDefault();
        if (noteData.trim() === "") {
            setInputAlert(true);
        } else {
            setInputAlert(false);
            const res = await onClickSave();
            if (res) {
                handleClickTop();
            }
        }
    }

    const [ onTop, setOnTop ] = useState(true);
    const [ topRef, setTopRef ] = useState(null);

    const [ onBottom, setOnBottom ] = useState(false);
    const [ bottomRef, setBottomRef ] = useState(null);

    useEffect(() => {
        if((!lessThan10) && onBottom && (!loadingMoreNotes) && (!noMoreNotes)) {
            if (notesDisplaying.length > 0) {
                loadMoreNotes();
            }
        }
    // eslint-disable-next-line    
    }, [onBottom]);

    const setTopReference = (inputElement)=>{
        setTopRef(inputElement);
    }

    const setBottomReference = (inputElement)=>{
        setBottomRef(inputElement);
    }

    const handleClickTop = () => {
        if (topRef) {
            topRef.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const toObserver = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
                setOnTop(true);
            } else {
                setOnTop(false);
            }
          },
          {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
          }
        );
    
        if (topRef) {
            toObserver.observe(topRef);
        }
    
        return () => {  
          if (topRef) { 
            // eslint-disable-next-line
            toObserver.unobserve(topRef);
          }
        };
    }, [topRef]);

    useEffect(() => {
        const bottomObserver = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
                setOnBottom(true);
            } else {
                setOnBottom(false);
            }
          },
          {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
          }
        );
    
        if (bottomRef) {
            bottomObserver.observe(bottomRef);
        }
    
        return () => {  
          if (bottomRef) { 
            // eslint-disable-next-line
            bottomObserver.unobserve(bottomRef);
          }
        };
    }, [bottomRef]);

    const onChange = e => {
        setNoteData(e.target.value);
        setInputAlert(false);
    }

    const onDeleteNote = id => {
        onClickDelete(id);
    }
    
    return (
        <div className="cust-account-history-con" style={ appTheme.dark ? { colorScheme: "dark"} : {colorScheme: "light"}}>
            {loadingNotes ? 
                <div 
                    className="cust-account-history-spinner-cont"
                    style={{
                        minHeight: `calc(100vh - ${headerHeight + 190}px)`, 
                        maxHeight: `calc(100vh - ${headerHeight + 190}px)`
                    }} 
                >
                    <Spinner />
                </div> 
            : <Fragment>
                {notesConnectionError ? 
                    <div   
                        style={{
                            minHeight: `calc(100vh - ${headerHeight + 210}px)`, 
                            maxHeight: `calc(100vh - ${headerHeight + 210}px)`
                        }}   
                    > 
                        <ConnectionError onTryAgain={tryAgainNotes} /> 
                    </div>
                : <Fragment>
                    {createNewNote ?
                        <div 
                            className="cust-account-history-notes-new-cont" 
                            style={{
                                minHeight: `calc(100vh - ${headerHeight + 175}px)`, 
                                maxHeight: `calc(100vh - ${headerHeight + 175}px)`
                            }}   
                        >
                            <p className="cust-account-history-notes-new-title">{appLanguage.en ? "Add note" : "Agregar nota"}</p>
                            <textarea 
                                autoComplete="off"
                                autoCorrect="off"
                                style={{colorScheme: appTheme.dark ? "dark" : "light"}}
                                placeholder={appLanguage.en ? "If you need it, leave comments about this supplier here." : "Si lo necesitas deja comentarios sobre este proveedor aquí."}
                                className={`cust-account-history-notes-new-textarea ${appTheme.dark ? "edit-customer-desktop-dark" : "edit-customer-desktop-light"} ${inputAlert ? "new-custmer-textarea-alert" : (appTheme.dark ? "cust-account-history-textarea-border-dark" : "cust-account-history-textarea-border-light")}`}
                                id={"Add note"} 
                                name={"Add note"}
                                maxLength={500}
                                onChange={e => onChange(e)}
                                value={noteData}
                                onKeyDown={e => handleKeyPress(e)}
                            />
                            <div className="new-customer-bottons-container2">
                                <button 
                                    onClick={e => onCancel(e)} 
                                    className="new-customer-bottons-button-save"
                                >
                                    <img src={crossIcon} alt="Cancel" />
                                    {appLanguage.en ? <p>Cancel</p> : <p>Cancelar</p>}
                                </button>

                                <button 
                                    onClick={e => onSave(e)}
                                    className="new-customer-bottons-button-save"
                                >
                                    <img src={saveIcon} alt="Save" />
                                    {appLanguage.en ? <p>Save</p> : <p>Guardar</p>}
                                </button>
                            </div>
                        </div>
                    : <Fragment>
                        <div
                            className="cust-account-history-notes"
                            style={{
                                minHeight: `calc(100vh - ${headerHeight + 175}px)`, 
                                maxHeight: `calc(100vh - ${headerHeight + 175}px)`
                            }}   
                        >
                            {notesDisplaying.length > 0 ? <Fragment>
                                <div style={{height: "10px"}} ref={setTopReference} />
                                {notesDisplaying.map((note) => {
                                    return (
                                        <div key={`${note.id}-${number}`} className="cust-account-history-notes-item">
                                            <div className="cust-account-history-notes-element-date-and-delete">
                                                <p style={appTheme.dark ? {color: "#74b7ff"} : {color: "#1083ff"}} className="cust-account-history-notes-element-date">{getTime(note.time)} - {getDate(note.time)} - {note.author}</p>
                                                {userData ? ((userData.email + "*") === note.author ? 
                                                    <div onClick={() => onDeleteNote(note.id)} className="cust-account-history-notes-element-delete" >
                                                        <img 
                                                            onClick={() => onDeleteNote(note.id)}
                                                            src={appTheme.dark ? closeDarkIcon : closeLightIcon} 
                                                            alt="delete" 
                                                        />
                                                    </div>
                                                : null) : null}
                                            </div>
                                            <p className="cust-account-history-notes-element-content">{appLanguage.en ? note.noteDesc.enNote : note.noteDesc.esNote}</p>
                                        </div>
                                    );
                                })}
                            </Fragment> : 
                                <div className="no-products-yet-main-container">
                                    <img className="no-products-yet-main-img" src={emptyFolderImg} alt="No products yet" />
                                    <p><b>{appLanguage.en ? "No notes yet" : "Aún no hay notas"}</b></p>
                                    <p>{appLanguage.en ? "The history of changes to this record will be displayed here." : "El historial de cambios a este registro se mostrará aquí."}</p>
                                </div>
                            }
                            
                            <div ref={setBottomReference} style={loadingMoreNotes ? {height: "80px"} : {height: "20px"}} >
                                {loadingMoreNotes ? 
                                    <div className="cust-account-history-con-more-notes-con">
                                        <Spinner2 />
                                    </div>
                                : null}
                            </div>
                        </div>
                       
                        <div className="cust-account-history-con-buttons">
                            <button onClick={onClickAdd} className="cust-account-history-con-buttons-elem"><p>{appLanguage.en ? "Add note" : "Agregar nota"}</p></button>
                            {onTop ? null : 
                                <button 
                                    onClick={handleClickTop} 
                                    className="cust-account-history-con-buttons-elem"
                                    style={{minWidth: "60px"}}
                                >
                                    <img src={upDarkIcon} alt="Go Up" />
                                </button>
                            }
                        </div>
                       
                    </Fragment>}
                </Fragment>}
            </Fragment>}
        </div>
    );
}

export default AccountHistory;