import JsBarcode from "jsbarcode";
import React, { useContext, useEffect, useRef } from "react";
import { AppContext } from "../context/AppContext";

const LabelToPrint = ({ labelData, labelRef }) => {
    const { appTheme } = useContext(AppContext);

    return (
        <div 
            className={`${appTheme.dark ? "border-box-dark" : "border-box-light"}`}
            style={{ 
                boxShadow: "1px 1px 1px 0.5px rgba(0, 0, 0, 0.2)",
                margin: "40px 8px",
                backgroundColor: "white",
                display: 'none'
            }}
        >
            {labelData ?
                <ProductLabel ref={labelRef} labelData={labelData} /> 
            : null}
        </div>
    )
}

const CodigoDeBarras = ({ labelData }) => {
    const barcodeRef = useRef(null);

    const getBarcodeWidth = (barcode) => {
        const num = String(barcode).length;
        if (num <= 10) {
            return 1.9;
        } else {
            if (num === 11) {
                return 1.8;
            } else {
                if (num === 12) {
                    return 1.7;
                } else {
                    return 1.6;
                }
            }
        }
    }

    const getBarcodeHeight = () => {
        let height = 20;
        if (labelData.includePrice) {
            height = height - 2;
        }
        if (labelData.includeName) {
            height = height - 2;
        }
        return height;
    }

    useEffect(() => {
        JsBarcode(barcodeRef.current, labelData.barcode, {
            format: "CODE128",
            lineColor: "#000",
            width: getBarcodeWidth(labelData.barcode),
            height: getBarcodeHeight(),
            displayValue: true,
            font: "Arial",
            fontSize: 14,
        });
    // eslint-disable-next-line
    }, [labelData]);

    return (
        <svg ref={barcodeRef} />
    );
};

const ProductLabel = React.forwardRef(({ labelData }, ref) => {
    if (!labelData) return null; 

    function formatRationalNumber(number) {
        if (number === undefined || number === null) {
            return "--";
        }
        
        // Check if the number is a string to convert it to a number
        if (typeof number === 'string') {
            number = parseFloat(number.split(',').join(''));
        }

        const fixedNumber = number.toFixed(2);
        const finalNumber = parseFloat(fixedNumber).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    
        // Format the number with thousands and two decimal separators
        return finalNumber.toString();
    }

    return (
        <div 
            ref={ref} 
            className="receipt get-quote-component-font" 
            style={{
                display: "flex",
                width: "270px",
                maxWidth: "270px",
                height: "141px",
                maxHeight: "141px",
                alignItems:"center",
                flexDirection: "column",
                justifyContent: "center"
            }}
        >
            <p style={{fontSize: "1px"}}>.</p>
            <div 
                style={{ 
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    padding: "0 5px",
                }}
            >
                {labelData.includePrice ? 
                    <p
                        style={{
                            fontSize: "14px",
                            color: "black",
                            whiteSpace: "nowrap",
                            fontWeight: "bold",
                            margin: "5px 10px 0 10px"
                        }}
                    >
                        RD$ {formatRationalNumber(labelData.finalPrice)}
                    </p>
                : null}

                {labelData.includeName ? 
                    <p
                        style={{
                            fontSize: "12px", 
                            color: "black",
                            whiteSpace: "nowrap", 
                            margin: "5px 10px 0 10px",
                            "WebkitBoxOrient": "vertical",
                            "WebkitLineClamp": "1",
                            "lineClamp": "1",
                            "overflow": "hidden",
                            "textOverflow": "ellipsis"
                        }}
                    >
                        {labelData.name}
                    </p>
                : null}
            </div>

            <CodigoDeBarras labelData={labelData}  />

            <p style={{fontSize: "1px"}}>.</p>
        </div>
    );
});

export default LabelToPrint;