import { Fragment, useContext } from "react";
import { Helmet } from 'react-helmet-async';
import { AuthContext } from '../../firebase/context'
import { AppContext } from '../../context/AppContext';

import accessIcon from '../../img/access.svg';

import "./connectWithExistingAccount.scss";

const ConnectWithExistingAccount = () => {

    const { appTheme, appLanguage } = useContext(AppContext);
    const { userData } = useContext(AuthContext);

    return (
        <Fragment>
            <Helmet  htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Connect with existing account' : 'Conectarse con cuenta existente'} </title>
            </Helmet>
            <div 
                className={`page-app-settings-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} 
                style={{ 
                    colorScheme: appTheme.dark ?  "dark" : "light"
                }}
            >
                <div className="page-app-settings-item">
                    <div className="page-steps-to-connect-header">
                        <img src={accessIcon} alt="Create account icon" />
                        <h3 className="page-steps-to-connect-header-tittle">{appLanguage.en ? 'Steps to connect with account' : 'Pasos para conectarse con la cuenta'} </h3>
                    </div>
                    <div className="settings-options-container">
                        <div className="settings-options-container-item">
                            <div className={`page-steps-to-connect-header-tittle-body ${appTheme.dark ? "dar-sec-bg dar-box-sha-keep-hover input-border-box-dark" : "lig-sec-bg lig-box-sha-keep-hover input-border-box-light"}`}>
                                {appLanguage.en ? <Fragment>
                                    <p>To access an existing business account you must contact the administrator of the account and provide them with your email address <i>"{userData ? userData.email : ""}"</i></p> 
                                    <br/>
                                    <p>The administrator must access the business account and go to the option:</p>
                                    <br/>
                                    <p><b> 1. Business account <br/>2. Authorized users <br/>3. Authorize access. </b></p>
                                    <br/>
                                    <p>Once in that section, the administrator must complete the form with your information (name, phone number and email address).</p>
                                </Fragment> : <Fragment>
                                    <p>Para acceder a una cuenta de negocios existente debes ponerte en contacto con la persona administradora de la misma y proporcionarle tu correo electrónico <i>"{userData ? userData.email : ""}"</i></p>
                                    <br/>
                                    <p>El administrador deberá acceder a la cuenta de negocios e ir a la opción: </p>
                                    <br/>
                                    <p> <b> 1. Cuenta de negocios <br/>2. Usuarios autorizados <br/>3. Autorizar acceso.</b> </p>
                                    <br/>
                                    <p>Una vez en ese apartado, el administrador deberá completar el formulario con tus datos (nombre, número de teléfono y dirección de correo electrónico).</p>
                                </Fragment>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ConnectWithExistingAccount;