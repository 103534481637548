import "./pictureReceiptViewer.scss";

import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../context/AppContext";

import crossIconDark from "../../../icons/cross-white.png";
import crossIconLight from "../../../icons/cross.png";

import downloadIconDark from "../../../icons/download-dark.png";
import downloadIconLight from "../../../icons/download-light.png";

const PictureReceiptViewer = ({ receiptName, viewerWidth, pictureViewerActive, setPictureViewerActive, pictureFile }) => {
    const { appTheme, appLanguage } = useContext(AppContext);

    let menuRef = useRef();

    useEffect(() => {
        if (pictureViewerActive) {
            let handler = (e) => {
                if (menuRef.current && !menuRef.current.contains(e.target)) {
                    setPictureViewerActive(false);
                };
            };
            document.addEventListener("mousedown", handler);
            return () => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    const onClickClose = () => {
        setPictureViewerActive(false);
    }

    function onDownloadImage(src) {
        blick();
        const img = new Image();
        img.crossOrigin = "https://hazcuentas.com/";
        img.src = src;
        img.onload = () => {
            // create Canvas
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            // for create tag anchor
            const a = document.createElement("a");
            a.download = receiptName;
            a.href = canvas.toDataURL("image/png");
            a.click();
        };
    }

    const [downloading, setDownloading] = useState(false);

    const blick = () => {
        setDownloading(true);
        setTimeout(() => {
            setDownloading(false);
        }, 1400);
    }

    return (
        <div className='unselectable'>
            {pictureViewerActive ?
                <div
                    className='picture-receipt-viewer-lock-screen'
                    style={{
                        width: viewerWidth,
                        minHeight: "calc(100vh - 65px)",
                        background: appTheme.dark ? "#202022" : "rgba(255, 255, 255)"
                    }}
                />
                : null}

            <div ref={menuRef} className={`picture-receipt-viewer-card ${pictureViewerActive ? 'active' : 'inactive'}`} style={{ width: viewerWidth, color: "white" }}>
                <div
                    className={`picture-receipt-viewer-card-top-shadow`}
                    style={{
                        boxShadow: appTheme.dark ? "3px 3px 3px 2px rgba(0, 0, 0, 0.3)" : "2px 2px 2px 1px rgba(0, 0, 0, 0.3)",
                    }}
                />
                <div
                    className="picture-receipt-viewer-options-cont"
                >
                    <button
                        onClick={onClickClose}
                        className={appTheme.dark ? "picture-receipt-viewer-btn-dark" : "picture-receipt-viewer-btn-light"}
                        style={{
                            color: appTheme.dark ? "white" : "black",
                            paddingLeft: "20px"
                        }}
                    >
                        <img src={appTheme.dark ? crossIconDark : crossIconLight} alt="Close" />
                    </button>
                    <div style={{ flexGrow: 4 }} />
                    <button
                        onClick={() => onDownloadImage(pictureFile)}
                        disabled={downloading ? true : false}
                        className={`picture-receipt-viewer-btn-download ${downloading ? "skeleton-receipt" : ""} ${appTheme.dark ? "picture-receipt-viewer-btn-dark" : "picture-receipt-viewer-btn-light"}`}
                        style={{
                            color: appTheme.dark ? "white" : "black",
                        }}
                    >
                        {downloading ? null : <img src={appTheme.dark ? downloadIconDark : downloadIconLight} alt="Download" />}
                        {downloading ? `${appLanguage.en ? "Downloading..." : "Descargando..."}` : `${appLanguage.en ? "Download" : "Descargar"}`}
                    </button>
                </div>
                <div className="picture-receipt-viewer-card-img">
                    <img src={pictureFile} alt="receipt" />
                </div>
            </div>
        </div>
    );
}

export default PictureReceiptViewer;