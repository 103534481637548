import { useContext, useEffect, useRef } from 'react';
import { SoftAlertContext } from '../../components/soft-alert/softAlertContext';
import { SoftLoadingContext } from '../../components/soft-loading/softLoadingContext';

import firebase from '../../firebase';
import { doc, getDocFromServer } from "firebase/firestore";

import { Outlet } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Navigation from '../../components/navigation/navigation';
import { AppContext } from '../../context/AppContext';
import { AuthContext } from '../../firebase/context';
import { SideMenuContext } from '../../context/SideMenuContext';
import { useNavigate, useLocation } from 'react-router-dom';

import SideMenuDesktop from '../../components/side-menu-desktop/sideMenuDesktop';

import Alert from '../../components/alert/alert';
import AlertLock from '../../components/alertLock/alertLock';
import AlertNewAuthUser from '../../components/alertNewAuthUser/alertNewAuthUser';

import SideMenu from '../../components/structure/sideMenu';
import OptionsBelow from '../../components/structure/optionsBelow';
import CartMenu from '../../components/structure/cartMenu';
import UserMenu from '../../components/structure/userMenu';

import UnlockApp from '../../components/unlock-app/unlockApp';

import './inside.page.scss';
import BlankBackground from '../../components/blankBackground/blankBackground';

const InsidePage = () => {
    const { appTheme, themeColor, appLanguage, appLock, setAppLock, showCartNumber } = useContext(AppContext);
    const { user, userData, setAccountSelected, managerAccess, eliminateAllCustomers, shoppingCart, deleteShoppingCart } = useContext(AuthContext);
    const { desktopView, optimizeMobileView } = useContext(SideMenuContext);

    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    const { setSoftLoadingActive } = useContext(SoftLoadingContext);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (shoppingCart && shoppingCart.salesId && (location.pathname !== "/resumen-de-venta")) {
            deleteShoppingCart();
        }
    // eslint-disable-next-line
    }, [location])

    async function checkUserDataExicts() { 
        setSoftLoadingActive(true);
        try {
            const response = await firebase.useCheckUserDataExicts();
            if(response === false) {
                navigate('/auth/completar-datos', { replace: true });
            } else {
                getUserData();
            }
        } catch (error) { 
            console.error(error);
            setSoftLoadingActive(false);
            setSoftAlertActive(true);
            setSoftAlertData({
                type : 'error',
                text: {
                    en : 'Connection issues',
                    es : 'Problemas de conexión',
                }
            });
        }
    }

    async function getUserData() { 
        setSoftLoadingActive(true);

        const isAccountSelected = JSON.parse(localStorage.getItem('isAccountSelected'));
        
        if (isAccountSelected) {
            try {
                const docRef = doc(firebase.db, "accounts", isAccountSelected.id);
                let docSnap = null;
                let response = null;
                try {
                    docSnap = await getDocFromServer(docRef); 
                    response = {
                        ...docSnap.data(),
                        "id": docSnap.id,
                    }
                } catch (error) {
                    console.error(error);
                    if (error.code === "permission-denied") {
                        eliminateAllCustomers();
                    } else {
                        throw error;
                    };
                }
                
                if (managerAccess) {
                    try {
                        const res = await firebase.useGetAccountDataSimple(isAccountSelected.id);
                        const accountRef = {
                            "doc": "manager",
                            "id": response.id,
                            "active": res.active,
                            "displayName": res.displayName,
                            "email": res.email,
                            "name": res.name,
                            "phone": res.phone,
                            "pictureLocation": res.pictureLocation 
                        }

                        localStorage.setItem("isAccountSelected", JSON.stringify(accountRef));
                        setAccountSelected(accountRef);
                    } catch (error) {
                        console.error(error)
                    }
                } else {
                    // Check if the user is admin or auth user
                    const accountRef = {
                        "doc": response.admin === user.uid ? "admin" : "authorizedUser",
                        "id": response.id
                    }
                    localStorage.setItem("isAccountSelected", JSON.stringify(accountRef));
                    setAccountSelected(accountRef);
                }
                
            } catch (error) {
                console.error(error);
                setSoftAlertActive(true);
                setSoftAlertData({
                    type : 'error',
                    text: {
                        en : 'Connection issues',
                        es : 'Problemas de conexión',
                    }
                });
            }
        }
        setSoftLoadingActive(false);
    }

    useEffect(() => {
        if(user) {
            checkUserDataExicts();
        }
    // eslint-disable-next-line
    }, [user, userData]);

    const getOptimizedForMobile = () => {
        if ((!desktopView) && optimizeMobileView) {
            return true;
        } else {
            return false;
        }
    }

    let cartButtonRef = useRef();
    let userButtonRef = useRef();

    return (
        <HelmetProvider>
            <Helmet  htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <meta name="theme-color" content={`${themeColor}`} />
                <title>HazCuentas</title>
            </Helmet>
            {!appLock ? 
                <div className={`${appTheme.dark ? "dark-theme" : "light-theme"} App ${desktopView ? null : "unselectable" }`} >
                    <Alert/>
                    <AlertLock/>
                    <AlertNewAuthUser/>

                    <SideMenu />
                    <OptionsBelow />

                    {desktopView ? <CartMenu optimizedForMobile={getOptimizedForMobile()} cartButtonRef={cartButtonRef} /> :
                        (optimizeMobileView ? 
                            (showCartNumber ? <CartMenu optimizedForMobile={getOptimizedForMobile()} cartButtonRef={cartButtonRef} /> : null)
                        :
                            <CartMenu optimizedForMobile={getOptimizedForMobile()} cartButtonRef={cartButtonRef} />
                        )
                    }

                    {desktopView ? 
                        <UserMenu userButtonRef={userButtonRef} /> 
                    : 
                        (optimizeMobileView ? null : <UserMenu userButtonRef={userButtonRef} /> )
                    }
                    
                    <div className='app-container'>
                        <Navigation cartButtonRef={cartButtonRef} userButtonRef={userButtonRef} />
                        <div className='navigation-place-space' />
            
                        <div className='page-body'>
                            {desktopView ? <SideMenuDesktop /> : null} 
                            {userData ? <Outlet /> : <BlankBackground />}
                        </div>
                    </div>
                </div>
            : 
                <UnlockApp appLock={appLock} setAppLock={setAppLock} />
            }
        </HelmetProvider>
    );
}

export default InsidePage;