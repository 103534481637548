import "./productCard.scss";

import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { useNavigate } from "react-router-dom";

import {
    ref,
    getDownloadURL,
} from "firebase/storage";

import noPicture from "../../../img/no-picture.png";
import noReady from "../../../img/nothing.png";
import error404 from "../../../img/404-error.png";

import addImg from "../../../icons/add-to-cart-dark.png";

import removeImgLight from "../../../icons/remove-from-cart-light.png";

import firebase from "../../../firebase";
import { AuthContext } from "../../../firebase/context";

const ProductCard = ({ product, activeId, setActiveReference, onClickLink }) => {
    const { appTheme, appLanguage, formatProductName, formatRationalNumber, getCategory, isTouchEnabled } = useContext(AppContext);
    const { setPictureInMemory, isItemInShoppingCart, accessTo, addToShoppingCart, removeFromShoppingCart } = useContext(AuthContext);

    const [pictureData, setPictureData] = useState(null);
    const [loadingError, setLoadingError] = useState(false);

    const navigate = useNavigate();

    const [noImgFile, setNotImgFile] = useState(() => {
        if (product.picturesLocation) {
            if (product.picturesLocation[0]) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    });

    useEffect(() => {
        if (product.picturesLocation && product.id) {
            if (product.picturesLocation[0]) {
                const path = product.picturesLocation[0];

                if (!pictureData) {
                    checkIfImageExistInPr(path);
                } else {
                    if (path !== pictureData.location) {
                        checkIfImageExistInPr(path);
                    }
                }
            } else {
                setPictureData(null);
            }
        } else {
            setPictureData(null);
        }

        setNotImgFile(() => {
            if (product.picturesLocation) {
                if (product.picturesLocation[0]) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        })
        // eslint-disable-next-line
    }, [product]);

    const checkIfImageExistInPr = (path) => {
        const dimensions = "d300x300";

        const dbId = product.id;

        const indexedDB = window.indexedDB;
        const request = indexedDB.open("hazcuentas-pr-pictures-database", 1);

        request.onerror = function (event) { console.error(event); }

        request.onupgradeneeded = function () {
            const db = request.result;
            if (!db.objectStoreNames.contains("hazcuentas-pr-pictures-store")) {
                db.createObjectStore("hazcuentas-pr-pictures-store", { keyPath: "id" });
            }
        }

        request.onsuccess = function () {
            const db = request.result;
            const transaction = db.transaction('hazcuentas-pr-pictures-store', 'readwrite');

            transaction.onerror = (err) => {
                console.warn(err);
                return false;
            }

            const store = transaction.objectStore('hazcuentas-pr-pictures-store');
            const requestToCheck = store.get(dbId);

            requestToCheck.onsuccess = (ev) => {
                const request = ev.target;
                if (request.result) {
                    if (path === request.result.key.path) {
                        if (request.result.key[dimensions]) {
                            setPictureData({
                                pictureFile: request.result.key[dimensions],
                                location: path
                            });
                        } else {
                            checkIfImageExistInGe(path);
                        }
                    } else {
                        checkIfImageExistInGe(path);
                    }
                } else {
                    checkIfImageExistInGe(path);
                }
            }

            requestToCheck.onerror = () => {
                checkIfImageExistInGe(path);
            }
        }
    }

    const checkIfImageExistInGe = (path) => {
        const dimensions = "d300x300";

        const dbId = product.id;

        const indexedDB = window.indexedDB;
        const request = indexedDB.open("hazcuentas-pictures-database", 1);

        request.onerror = function (event) { console.error(event); }

        request.onupgradeneeded = function () {
            const db = request.result;
            if (!db.objectStoreNames.contains("hazcuentas-pictures-store")) {
                db.createObjectStore("hazcuentas-pictures-store", { keyPath: "id" });
            }
        }

        request.onsuccess = function () {
            const db = request.result;
            const transaction = db.transaction('hazcuentas-pictures-store', 'readwrite');

            transaction.onerror = (err) => {
                console.warn(err);
                return false;
            }

            const store = transaction.objectStore('hazcuentas-pictures-store');
            const requestToCheck = store.get(dbId);

            requestToCheck.onsuccess = (ev) => {
                const request = ev.target;
                if (request.result) {
                    if (path === request.result.key.path) {
                        if (request.result.key[dimensions]) {
                            setPictureData({
                                pictureFile: request.result.key[dimensions],
                                location: path
                            });
                        } else {
                            getPictureFile(path);
                        }
                    } else {
                        getPictureFile(path);
                    }
                } else {
                    getPictureFile(path);
                }
            }

            requestToCheck.onerror = () => {
                getPictureFile(path);
            }
        }
    }

    const getPictureFile = async (path) => {
        // This is a recursive function that download all the dimensions 
        // of a picture store in firebase store
        let requestCounter = 6;

        const getPictureByDimensions = async (dimensions) => {
            try {
                const locationPath = `${path}${dimensions}`;
                const imageRef = ref(firebase.storage, locationPath);
                const url = await getDownloadURL(imageRef);
                const xhr = new XMLHttpRequest();

                xhr.responseType = 'blob';
                xhr.onload = () => {
                    const blob = xhr.response;
                    const fr = new FileReader();
                    fr.readAsDataURL(blob);
                    fr.addEventListener('load', () => {
                        const urlData = fr.result;
                        if (dimensions !== "_600x600") {
                            setPictureData({
                                pictureFile: urlData,
                                location: path
                            });
                        }
                        setLoadingError(false);
                        if (dimensions === "_140x140") {
                            dbKey.d140x140 = urlData;
                            getPictureByDimensions("_300x300");
                        } else {
                            if (dimensions === "_300x300") {
                                dbKey.d300x300 = urlData;
                                getPictureByDimensions("_600x600");
                            } else {
                                if (dimensions === "_600x600") {
                                    dbKey.d600x600 = urlData;
                                    setPictureInMemory(dbKey, product.id);
                                }
                            }
                        }
                    });
                };
                xhr.open('GET', url);
                xhr.send();
            } catch (error) {
                if ((requestCounter > 0) && (error.code !== "storage/unauthorized")) {
                    requestCounter--;
                    setTimeout(() => {
                        getPictureByDimensions("_140x140");
                    }, 1000);
                } else {
                    console.error(error);
                    if (pictureData === null) {
                        setLoadingError(true);
                    }
                }
            }
        }

        const dbKey = {
            "path": path,
            "d140x140": null,
            "d300x300": null,
            "d600x600": null,
        }

        getPictureByDimensions("_140x140");
    }

    const getTitleForCard = (product) => {
        if (appLanguage.en) {
            return `${product.name} \n\nRD$ ${formatRationalNumber(product.priceWithoutTax)}\nCategory: ${product.category ? getCategory(product.category) : "--"}\nQuantity Available: ${typeof product.quantity === 'number' ? product.quantity.toLocaleString() : "--"}\nLocation: ${product.location ? product.location : "--"}`;
        } else {
            return `${product.name} \n\nRD$ ${formatRationalNumber(product.priceWithoutTax)}\nCategoria: ${product.category ? getCategory(product.category) : "--"}\nCantidad disponible: ${typeof product.quantity === 'number' ? product.quantity.toLocaleString() : "--"}\nUbicación: ${product.location ? product.location : "--"}`;
        }
    }

    const [normalClassName, setNormalClassName] = useState(`products-individual-product-card unselectable ${appTheme.dark ? (isTouchEnabled() ? "products-individual-product-card-div-dark-NOHOVER" : "products-individual-product-card-div-dark-HOVER") : (isTouchEnabled() ? "products-individual-product-card-div-light-NOHOVER" : "products-individual-product-card-div-light-HOVER")}`);
    const [focusClassName, setFocusClassName] = useState(`products-individual-product-card unselectable ${appTheme.dark ? (isTouchEnabled() ? "products-individual-product-focus-card-div-dark-NOHOVER" : "products-individual-product-focus-card-div-dark-HOVER") : (isTouchEnabled() ? "products-individual-product-focus-card-div-light-NOHOVER" : "products-individual-product-focus-card-div-light-HOVER")}`);

    useEffect(() => {
        setNormalClassName(`products-individual-product-card unselectable ${appTheme.dark ? (isTouchEnabled() ? "products-individual-product-card-div-dark-NOHOVER" : "products-individual-product-card-div-dark-HOVER") : (isTouchEnabled() ? "products-individual-product-card-div-light-NOHOVER" : "products-individual-product-card-div-light-HOVER")}`);
        setFocusClassName(`products-individual-product-card unselectable ${appTheme.dark ? (isTouchEnabled() ? "products-individual-product-focus-card-div-dark-NOHOVER" : "products-individual-product-focus-card-div-dark-HOVER") : (isTouchEnabled() ? "products-individual-product-focus-card-div-light-NOHOVER" : "products-individual-product-focus-card-div-light-HOVER")}`);
        // eslint-disable-next-line
    }, [appTheme]);

    const addToCart = () => {
        addToShoppingCart(product);
    }

    const removeFromCart = () => {
        removeFromShoppingCart(product.id);
    }

    const handleLinkClick = () => {
        onClickLink(product.id);
        navigate('/ver-producto', { state: product });
    }

    const canBeAddedToShoppingCart = () => {
        if (isItemInShoppingCart(product.id)) {
            return true;
        } else {
            if (product && (product.quantity === null || product.quantity > 0)) {
                return true;
            } else {
                return false;
            }
        }
    }

    return (
        <div
            ref={product.id === activeId ? setActiveReference : null}
            title={getTitleForCard(product)}
            className={product.id === activeId ? focusClassName : normalClassName}
        >
            {noImgFile && (!loadingError) ?
                <div onClick={handleLinkClick} className="products-individual-product-yes-img-cont">
                    <div style={{ width: "140px", height: "140px" }}>
                        <img className={` products-individual-product-yes-img`} src={pictureData ? pictureData.pictureFile : noReady} alt="Product" />
                    </div>
                </div>
                :
                <div onClick={handleLinkClick} className="products-individual-product-no-img-cont" style={appTheme.dark ? { backgroundColor: "#343434ec" } : { backgroundColor: "rgba(128, 128, 128, 0.171)" }}>
                    <img className="products-individual-product-no-img" src={loadingError ? error404 : noPicture} alt="Product" />
                </div>
            }

            {typeof product.quantity === 'number' ?
                (product.quantity === 0 ?
                    <span onClick={handleLinkClick} className="prod-card-info-out-of-stock-span-cont">{appLanguage.en ? "Unavailable" : "Agotado"}</span> 
                :
                    (product.availability && product.availability === "close to out of stock" ? 
                        <span
                            onClick={handleLinkClick} 
                            className="prod-card-info-out-of-stock-span-cont"
                            style={{
                                background: "rgba(255, 166, 0, 0.774)",
                                textAlign: "start",
                            }}
                        >
                            {appLanguage.en ? "Only" : "Solo"} {product.quantity}
                        </span>
                    : null)
                )
            : null}

            {accessTo.sales && canBeAddedToShoppingCart() ?
                isItemInShoppingCart(product.id) ?
                    <button
                        onClick={removeFromCart}
                        className={isTouchEnabled() ? "remove-from-card-cart-btn-product-no-hover" : "remove-from-card-cart-btn-product"}
                        style={{
                            padding: "0px 5px",
                            width: "67px",
                            height: "48px",
                            borderRadius: "100px",
                            marginTop: "-49px",
                            marginLeft: "71px"
                        }}
                    >
                        <img
                            src={removeImgLight}
                            alt="Remove from cart"
                            style={{
                                width: "25px",
                                height: "25px",
                                marginRight: "2px"
                            }}
                        />
                    </button>
                :
                    <button
                        onClick={addToCart}
                        className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                        style={{
                            padding: "0px 5px",
                            width: "67px",
                            height: "48px",
                            borderRadius: "100px",
                            marginTop: "-49px",
                            marginLeft: "71px"
                        }}
                    >
                        <img
                            src={addImg}
                            alt="Add to cart"
                            style={{
                                width: "25px",
                                height: "25px",
                                marginRight: "2px"
                            }}
                        />
                    </button>
                : null
            }

            <div onClick={handleLinkClick} className="products-individual-product-name-div">
                <p className="products-individual-product-name">{formatProductName(product.name)}</p>
            </div>

            <p onClick={handleLinkClick} className="products-individual-product-price" style={{ color: appTheme.dark ? "#a7d0ff" : "#2089ff" }}>RD$ {formatRationalNumber(product.finalPrice)}</p>

        </div>
    );
}

export default ProductCard;