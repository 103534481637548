import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { AuthContext } from "../../../firebase/context";
import { AppContext } from "../../../context/AppContext";

import { SideMenuContext } from "../../../context/SideMenuContext";
import { SoftAlertContext } from '../../../components/soft-alert/softAlertContext';
import { AlertContext } from "../../../components/alert/alertContext";

import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";

import crossWhiteIcon from "../../../icons/cross-white.png";
import saveIcon from "../../../icons/check-dark.png";

import warningIcon from "../../../icons/warning.png";

import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';

import InventoryEditTable from "../../../appTools/appToolsComponents/inventoryEditTable/inventoryEditTable";

import firebase from "../../../firebase";

import { useLocation, useNavigate } from "react-router-dom";

const EditingProductsPage = () => {

    let observedRef = useRef(null);

    const navigate = useNavigate();

    const { accountData, accessTo, updateShoppingCartInfoFromServer } = useContext(AuthContext);
    const { appTheme, appLanguage, setDropDownCartOpen, setDropDownProfileMenuOpen, dropDownCartOpen, dropDownProfileMenuOpen, formatRationalNumber } = useContext(AppContext);

    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    const { setAlertData, setAlertActive, getErrorDescription } = useContext(AlertContext);

    const { desktopView, windowDimension, isSideMenuMinimized } = useContext(SideMenuContext);

    const [fieldAlert, setFieldAlert] = useState(null);

    const [makeSticky, setMakeSticky] = useState(true);

    useEffect(() => {
        if (dropDownCartOpen || dropDownProfileMenuOpen) {
            setMakeSticky(false);
        } else {
            setMakeSticky(true);
        }
    }, [dropDownCartOpen, dropDownProfileMenuOpen]);

    const [fieldAlertRef, setFieldAlertRef] = useState(null);
    const fieldAlertReference = (inputElement) => {
        setFieldAlertRef(inputElement);
    }

    const [duplicatedBarcodes, setDuplicatedBarcodes] = useState([]);
    const [ duplicatedInTable, setDuplicatedInTables ] = useState([]);

    const removeThisDuplicatedFromTable = (barcodeToRemoved) => {
        let temDuplicatedInTable = [...duplicatedInTable];
        let newDuplicatedInTable = temDuplicatedInTable.filter(item => item !== barcodeToRemoved);
        setDuplicatedInTables(newDuplicatedInTable);
    }

    const location = useLocation();

    const [editingRecords, setEditingRecords] = useState(null);

    const [ currentRecords, setCurrentRecords ] = useState(null);
    const [ recordsBeenDisplay, setRecordsBeenDisplay ] = useState([]);

    const [pageNumber, setPageNumber] = useState(1);
    const [navControlers, setNavControlers] = useState(null);
    const [showPageNav, setShowPageNav] = useState(false);

    const blink = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 100);
    }

    const onClickPrevious = () => {
        blink();

        const tempRecordsBeenDisplay = [];

        const startAtIndex = ((pageNumber - 1) * 30) - 30;
        const endAtIndex = startAtIndex + 30;
                        
        for (let i = startAtIndex; ((i < editingRecords.length) && (i < endAtIndex)); i++) {
            tempRecordsBeenDisplay.push(editingRecords[i]);
        }

        setPageNumber(pageNumber - 1);
        setRecordsBeenDisplay(tempRecordsBeenDisplay);

        setNavControlers({
            ...navControlers,
            "page": pageNumber - 1,
            "isPrevious": startAtIndex === 0 ? false : true,
            "isNext": true,
        });
    }

    const onClickNext = () => {
        blink();

        const tempRecordsBeenDisplay = [];

        const startAtIndex = ((pageNumber + 1) * 30) - 30;
        const endAtIndex = startAtIndex + 30;
                        
        for (let i = startAtIndex; ((i < editingRecords.length) && (i < endAtIndex)); i++) {
            tempRecordsBeenDisplay.push(editingRecords[i]);
        }

        setPageNumber(pageNumber + 1);
        setRecordsBeenDisplay(tempRecordsBeenDisplay);

        setNavControlers({
            ...navControlers,
            "page": pageNumber + 1,
            "isPrevious": true,
            "isNext": endAtIndex >= editingRecords.length ? false : true,
        });
    }

    const convertFieldIntoString = (record, num) => {
        const newRecord = {
            "number": num,
            "id": record.id,
            "name": record.name,
            "category": record.category,
            "rate": record.rate,
            "description": record.description === null ? "" : record.description,
            "quantity": record.quantity === null ? "" : String(record.quantity),
            "location": record.location === null ? "" : record.location,
            "barcode": record.barcode === null ? "" : String(record.barcode),
            "priceWithoutTax": formatRationalNumber(record.priceWithoutTax),
            "itbisPercentage": record.itbisPercentage,
            "itbisAmount": formatRationalNumber(record.itbisAmount),
            "finalPrice": formatRationalNumber(record.finalPrice),
        }
        return newRecord;
    }

    useEffect(() => {
        if (location.state) {
            if (location.state.currentRecords) {
                setCurrentRecords(location.state.currentRecords);
            }

            if (location.state.editingRecords) {
                const tempRecords = location.state.editingRecords;

                const newTempRecords = [];

                for (let i = 0; i < tempRecords.length; i++) {
                    const newRecord = convertFieldIntoString(tempRecords[i], i + 1)
                    newTempRecords.push(newRecord)
                }

                setEditingRecords(newTempRecords);

                if (newTempRecords.length > 30) {
                    setShowPageNav(true);
                } else {
                    setShowPageNav(false);
                }
                
                const tempRecordsBeenDisplay = [];

                if (location.state.recordsBeenDisplay) {

                    const startAtIndex = 0;
                    const endAtIndex = startAtIndex + 30;
                        
                    for (let i = startAtIndex; ((i < newTempRecords.length) && (i < endAtIndex)); i++) {
                        tempRecordsBeenDisplay.push(newTempRecords[i]);
                    }

                    setRecordsBeenDisplay(tempRecordsBeenDisplay);

                } else {
                    
                    if (location.state.pageNumber) {
                        const startAtIndex = (location.state.pageNumber * 30) - 30;
                        const endAtIndex = startAtIndex + 30;
                        
                        for (let i = startAtIndex; ((i < newTempRecords.length) && (i < endAtIndex)); i++) {
                            tempRecordsBeenDisplay.push(newTempRecords[i]);
                        }

                    } else {

                        const startAtIndex = 0;
                        const endAtIndex = startAtIndex + 30;
                        
                        for (let i = startAtIndex; ((i < newTempRecords.length) && (i < endAtIndex)); i++) {
                            tempRecordsBeenDisplay.push(newTempRecords[i]);
                        }

                    }

                    setRecordsBeenDisplay(tempRecordsBeenDisplay);
                }
            } else {
                setShowPageNav(false)
            }

            if (location.state.pageNumber) {
                setPageNumber(location.state.pageNumber);
            } else {
                setPageNumber(1)
            }
            
            if (location.state.navControlers) {
                setNavControlers(location.state.navControlers);
            } else {
                setNavControlers({
                    "page": 1,
                    "totalPage": location.state.editingRecords ? Math.ceil(location.state.editingRecords.length / 30) : 1,
                    "isPrevious": false,
                    "isNext": location.state.editingRecords ? (Math.ceil(location.state.editingRecords.length / 30) > 1 ? true : false) : false,
                });
            }

        }
        // eslint-disable-next-line 
    }, []);

    const [loading, setLoading] = useState(false);

    const [longProcess, setLongProcess] = useState(false);

    const [width, setWidth] = useState(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            } else {
                try {
                    const width = observedRef.current.offsetWidth - 11
                    return width;
                } catch {
                    return;
                }
            }
        } else {
            return;
        }
    });

    useEffect(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (observedRef.current.offsetWidth !== width) {
                        setWidth(observedRef.current.offsetWidth - 11);
                    }
                } catch { }
            });

            resizeObserver.observe(observedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [observedRef.current, windowDimension]);

    let headerObservedRef = useRef(null);

    const [headerHeight, setHeaderHeight] = useState();

    useEffect(() => {
        if (headerObservedRef) {
            if (!headerObservedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (headerObservedRef.current.offsetHeight !== headerHeight) {
                        setHeaderHeight(headerObservedRef.current.offsetHeight);
                    }
                } catch { }
            });

            resizeObserver.observe(headerObservedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [headerObservedRef.current, windowDimension, width, isSideMenuMinimized]);

    const onScroll = () => {
        setDropDownCartOpen(false);
        setDropDownProfileMenuOpen(false);
    }

    const goBack = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate('/', { replace: true });
        }
    }

    const getColumName = (y) => {
        switch (y) {
            case 1:
                return "name"
            case 2:
                return "description"
            case 3:
                return "barcode"
            case 4:
                return "location"
            case 5:
                return "quantity"
            case 6:
                return "category"
            case 7:
                return "rate"
            case 8:
                return "priceWithoutTax"
            case 9:
                return "itbisPercentage"
            case 10:
                return "itbisAmount"
            case 11:
                return "finalPrice"
            default:
                break;
        }
    }

    const checkIfRecordEmpty = (recordToCheck) => {
        if (recordToCheck) {
            const name = recordToCheck[getColumName(1)];
            const desc = recordToCheck[getColumName(2)];
            const barc = recordToCheck[getColumName(3)];
            const loca = recordToCheck[getColumName(4)];
            const quan = recordToCheck[getColumName(5)];
            const pric = recordToCheck[getColumName(8)];
            const aitb = recordToCheck[getColumName(10)];
            const fpri = recordToCheck[getColumName(11)];

            if (
                name === "" &&
                desc === "" &&
                barc === "" &&
                loca === "" &&
                quan === "" &&
                pric === "" &&
                aitb === "" &&
                fpri === ""
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return true
        }
    }

    useEffect(() => {
        if (fieldAlertRef) {
            fieldAlertRef.scrollIntoView({
                block: 'center',  
                inline: 'end', // nearest
                behavior: "smooth"
            });

        }
        // eslint-disable-next-line 
    }, [fieldAlertRef]);

    const isBarcodeAlert = (barcode) => {
        if (duplicatedBarcodes.includes(Number(barcode))) {
            return true;
        } else {
            return false;
        }
    }

    const isAnyBarcodeAlert = (editingRecords) => {
        if (editingRecords) {
            editingRecords.forEach(record => {
                if (isBarcodeAlert(record.barcode.trim())) {
                    return record.number;
                }
            });
            return false;
        } else {
            return false
        }
    }

    const getPictureLocationForShoppingCart = (id) => {
        const product = currentRecords.find((product) => product.id === id);
        return product ? product.picturesLocation : null;
    }

    async function editProducts(recordsWithChanges) {
        setLoading(true);

        if (recordsWithChanges.length > 1) {
            setLongProcess(true);
        }

        try {
            const res = await firebase.useEditProducts({
                "recordsWithChanges": recordsWithChanges,
                "businessId": accountData.id
            });

            if (res.msg === "Duplicate barcodes") {
                setDuplicatedBarcodes(res.duplicatedBarcodes);
                setAlertData({
                    type: 'error',
                    title: {
                        en: `Error trying to edit the products`,
                        es: `Error al intentar editar los productos`
                    },
                    code: "Duplicate barcodes",
                    description: {
                        en: 'It is not possible to assign a barcode that is already associated with another product.',
                        es: 'No es posible asignar un código de barras que ya esté asociado a otro producto.'
                    }
                });

                setAlertActive(true);
                setLoading(false);

                let errorRecordNumber = null;
                
                recordsWithChanges.every(record => {
                    if (res.duplicatedBarcodes.includes(Number(record.barcode))) {
                        errorRecordNumber = record.number;
                        return false;
                    } 
                    return true;
                });

                if (errorRecordNumber) {
                    setFieldAlert({
                        x: errorRecordNumber,
                        y: 3,
                        en: "The barcode cannot be duplicated",
                        es: "El código de barras no puede estar repetido",
                    });
                    const pageToNum = Math.floor((errorRecordNumber - 1) / 30) + 1;
                    if (pageToNum !== pageNumber) {
                        goToPage(pageToNum);
                    }
                }
            } else {
                const productToUpdateInShoppingCart = [];

                recordsWithChanges.forEach(product => {
                    const optimizedProduct = {
                        ...product,
                        "description": product.description.trim() === "" ? null : product.description.trim(),
                        "quantity": product.quantity.trim() === "" ? null : Number(product.quantity),
                        "location": product.location.trim() === "" ? null : product.location.trim(),
                        "barcode": product.barcode.trim() === "" ? null : Number(product.barcode),
                        "priceWithoutTax": parseFloat(product.priceWithoutTax.split(",").join("")),
                        "itbisAmount": parseFloat(product.itbisAmount.split(",").join("")),
                        "picturesLocation": getPictureLocationForShoppingCart(product.id),
                        "finalPrice": parseFloat(product.finalPrice.split(",").join("")),
                    }
                    productToUpdateInShoppingCart.push(optimizedProduct);
                });

                await updateShoppingCartInfoFromServer();

                setAlertData({
                    type: 'success',
                    title: {
                        en: 'The products have been edited successfully',
                        es: 'Los productos han sido editados de manera exitosa'
                    },
                    code: '',
                    description: {
                        en: `If you want to add images to these products, you must search for each product separately.`,
                        es: `Si deseas agregar imágenes a estos productos, debe buscar cada producto por separado.`
                    }
                });

                setAlertActive(true);
                navigate('/inventario', { replace: true });
            }
        } catch (error) {
            setLoading(false);
            setLongProcess(false);
            const { code } = JSON.parse(JSON.stringify(error));
            console.error(error);
            setAlertData({
                type: 'error',
                title: {
                    en: `Error trying to edit the products`,
                    es: `Error al intentar editar los productos`
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
        }
    }

    const goToPage = (pageToNum) => {
        blink();

        const tempRecordsBeenDisplay = [];

        const startAtIndex = (pageToNum * 30) - 30;
        const endAtIndex = startAtIndex + 30;
                        
        for (let i = startAtIndex; ((i < editingRecords.length) && (i < endAtIndex)); i++) {
            tempRecordsBeenDisplay.push(editingRecords[i]);
        }

        setPageNumber(pageToNum);
        setRecordsBeenDisplay(tempRecordsBeenDisplay);

        setNavControlers({
            ...navControlers,
            "page": pageToNum,
            "isPrevious": startAtIndex === 0 ? false : true,
            "isNext": endAtIndex >= editingRecords.length ? false : true,
        });
    }

    const lookForChanges = (newRecord, oldRecord) => {
        const newBarcode = newRecord.barcode;
        const oldBarcode = oldRecord.barcode === null ? "" : String(oldRecord.barcode);
        if (newBarcode.trim() !== oldBarcode.trim()) {
            return true;
        }

        const newCategory = newRecord.category;
        const oldCategory = oldRecord.category;
        if (newCategory.trim() !== oldCategory.trim()) {
            return true;
        }

        const newDescription = newRecord.description;
        const oldDescription = oldRecord.description === null ? "" : String(oldRecord.description);
        if (newDescription.trim() !== oldDescription.trim()) {
            return true;
        }

        const newItbisAmount = formatRationalNumber(newRecord.itbisAmount);
        const oldItbisAmount = formatRationalNumber(oldRecord.itbisAmount);
        if (newItbisAmount !== oldItbisAmount) {
            return true;
        }

        const newItbisPercentage = newRecord.itbisPercentage;
        const oldItbisPercentage = oldRecord.itbisPercentage;
        if (newItbisPercentage.trim() !== oldItbisPercentage.trim()) {
            return true;
        }

        const newLocation = newRecord.location;
        const oldLocation = oldRecord.location === null ? "" : String(oldRecord.location);
        if (newLocation.trim() !== oldLocation.trim()) {
            return true;
        }

        const newName = newRecord.name;
        const oldName = oldRecord.name;
        if (newName.trim() !== oldName.trim()) {
            return true;
        }

        const newPriceWithoutTax = formatRationalNumber(newRecord.priceWithoutTax);
        const oldPriceWithoutTax = formatRationalNumber(oldRecord.priceWithoutTax);
        if (newPriceWithoutTax !== oldPriceWithoutTax) {
            return true;
        }

        const newQuantity = newRecord.quantity;
        const oldQuantity = oldRecord.quantity === null ? "" : String(oldRecord.quantity);
        if (newQuantity.trim() !== oldQuantity.trim()) {
            return true;
        }

        const newRate = newRecord.rate;
        const oldRate = oldRecord.rate;
        if (newRate.trim() !== oldRate.trim()) {
            return true;
        }

        const newFinalPrice = formatRationalNumber(newRecord.finalPrice);
        const oldFinalPrice = formatRationalNumber(oldRecord.finalPrice);
        if (newFinalPrice !== oldFinalPrice) {
            return true;
        }

        return false;
    }

    const checkIfDuplicated = (barcode) => {
        if (barcode.trim() !== "") {
            let barcodeCounter = 0;
            for (let i = 0; i < editingRecords.length; i++) {
                const barcodeToCheck = editingRecords[i].barcode.trim();
                if (barcodeToCheck !== ""){
                    if (barcodeToCheck === barcode.trim()) {
                        barcodeCounter++;
                    }
                }
            }
            if (barcodeCounter > 1) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const getLastRecordWithBarcodeRepeted = (duplicatedInThisTable) => {
        const array1 = [...duplicatedBarcodes];
        const array2 = [...duplicatedInThisTable];

        const combinedArray = array1.concat(array2)
        const allRepetedBarcodes = [...new Set(combinedArray)];

        for (let i = editingRecords.length - 1; i >= 0; i--) {
            // Retorna el numero primer elemento con codigo de barra repetido
            if (allRepetedBarcodes.includes(editingRecords[i].barcode.trim())) {
                return editingRecords[i].number;
            }
        }
        
        return false;
    }

    const onClickSave = () => {
        setFieldAlert(null);
        fieldAlertReference(null);

        let errorRecordNumber = null;

        const barcodeDuplicatedInTable = []

        editingRecords.every(record => {
            if (duplicatedBarcodes.includes(Number(record.barcode))) {
                errorRecordNumber = record.number;
                return false;
            } else {
                if (record.name.trim() === "") {
                    setFieldAlert({
                        x: record.number,
                        y: 1,
                        en: "The name cannot be empty",
                        es: "El nombre no puede estar vacío", 
                    });
                    setMakeSticky(false);
                    setTimeout(() => {
                        if (!(dropDownCartOpen || dropDownProfileMenuOpen)) {
                            setMakeSticky(true);
                        }
                    }, 100);
                    errorRecordNumber = record.number;
                    return false;
                } else {
                    if (checkIfDuplicated(record.barcode)) {
                        barcodeDuplicatedInTable.push(record.barcode.trim());
                    } else {
                        if (record.priceWithoutTax.trim() === "") {
                            setFieldAlert({
                                x: record.number,
                                y: 11,
                                en: "The price cannot be empty",
                                es: "El precio no puede estar vacío", 
                            });
                            errorRecordNumber = record.number;
                            return false;
                        }
                    }
                }
            }
            return true;
        });

        if (errorRecordNumber) {
            setFieldAlert({
                x: errorRecordNumber,
                y: 3,
                en: "The barcode cannot be duplicated",
                es: "El código de barras no puede estar repetido",
            });
            const pageToNum = Math.floor((errorRecordNumber - 1) / 30) + 1;
            if (pageToNum !== pageNumber) {
                goToPage(pageToNum);
            }
            return;
        }

        if (barcodeDuplicatedInTable.length > 0) {
            errorRecordNumber = getLastRecordWithBarcodeRepeted(barcodeDuplicatedInTable);
            setDuplicatedInTables(barcodeDuplicatedInTable);
            setFieldAlert({
                x: errorRecordNumber,
                y: 3,
                en: "The barcode cannot be duplicated",
                es: "El código de barras no puede estar repetido",
            });
            const pageToNum = Math.floor((errorRecordNumber - 1) / 30) + 1;
            if (pageToNum !== pageNumber) {
                goToPage(pageToNum);
            }
            return;
        }
        
        const recordsWithChanges = [];

        editingRecords.forEach((newRecord) => {
            const oldRecord = currentRecords[newRecord.number - 1];
            const changes = lookForChanges(newRecord, oldRecord);
            if (changes) {
                recordsWithChanges.push(newRecord);
            }
        });

        if (recordsWithChanges.length > 0) {
            editProducts(recordsWithChanges);
        } else {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'You have not made changes',
                    es: 'No has hecho cambios',
                }
            });
        }
    }

    useEffect(() => {
        if (accountData) {
            if (!accountData.active) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accountData]);

    useEffect(() => {
        if (accessTo) {
            if (!(accessTo.inventory)) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accessTo]);

    useEffect(() => { 
        if (location.state) {
            const newState = {
                "editingRecords": editingRecords,
                "pageNumber": pageNumber,
                "navControlers": navControlers,
                "showPageNav": showPageNav,
                "currentRecords": currentRecords,
            }
            navigate(null, { replace: true, state: newState });
        } else  {
            navigate("/inventario", { replace: true });
        }
    // eslint-disable-next-line
    }, [editingRecords, pageNumber, navControlers, showPageNav, currentRecords]);

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Edit products' : 'Editar productos'}</title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && accessTo ? <Fragment>
                    {accountData.active ?
                        <div className={`page-choose-account-item`}>
                            {accessTo.inventory ? <Fragment>
                                <div ref={headerObservedRef}>
                                    {desktopView ?
                                        <ToolTitle
                                            icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                            text={appLanguage.en ? 'Edit products' : 'Editar productos'}
                                        />
                                        : null}
                                    <div className="inven-first-secct-conte">
                                        
                                            <div className="inven-first-secct-adding-buttons">
                                                {fieldAlert ?
                                                    <div
                                                        className="invent-adding-product-alert-text-p"
                                                        style={{ flexGrow: 4 }}
                                                    >
                                                        <img src={warningIcon} alt="" />
                                                        <p>{appLanguage.en ? fieldAlert.en : fieldAlert.es}</p>
                                                    </div>
                                                    :
                                                    isAnyBarcodeAlert(editingRecords) ?
                                                        <div
                                                            className="invent-adding-product-alert-text-p"
                                                            style={{ flexGrow: 4 }}
                                                        >
                                                            <img src={warningIcon} alt="" />
                                                            <p>{appLanguage.en ?
                                                                "It is not possible to assign a barcode that is already associated with another product."
                                                                :
                                                                "No es posible asignar un código de barras que ya esté asociado a otro producto."
                                                            }</p>
                                                        </div>
                                                        : null
                                                }
                                                <div className="inven-first-section-div-btn" style={{ marginBottom: "15px" }}>
                                                    <button
                                                        onClick={goBack}
                                                        disabled={loading ? true : false}
                                                        className="new-customer-bottons-button-save"
                                                        style={loading ? {
                                                            backgroundColor: "#3f526698",
                                                            color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                                            cursor: "not-allowed",
                                                        } : {}}
                                                    >
                                                        <img src={crossWhiteIcon} alt="Cancel" />
                                                        {appLanguage.en ? <p>Cancel</p> : <p>Cancelar</p>}
                                                    </button>
                                                    <button
                                                        onClick={onClickSave}
                                                        disabled={loading ? true : false}
                                                        className="new-customer-bottons-button-save"
                                                        style={loading ? {
                                                            backgroundColor: "#3f526698",
                                                            color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                                            cursor: "not-allowed",
                                                        } : {}}
                                                    >
                                                        <img src={saveIcon} alt="Save" />
                                                        {appLanguage.en ? <p>Save</p> : <p>Guardar</p>}
                                                    </button>
                                                </div>
                                            </div>
                                        
                                    </div>
                                </div>
                                <InventoryEditTable
                                    onScroll={onScroll}
                                    makeSticky={makeSticky}
                                    headerHeight={headerHeight}
                                    loading={loading}
                                    longProcess={longProcess}
                                    fieldAlert={fieldAlert}
                                    setFieldAlert={setFieldAlert}
                                    editingRecords={editingRecords}
                                    setEditingRecords={setEditingRecords}
                                    recordsBeenDisplay={recordsBeenDisplay}
                                    fieldAlertReference={fieldAlertReference}
                                    getColumName={getColumName}
                                    checkIfRecordEmpty={checkIfRecordEmpty}
                                    duplicatedBarcodes={duplicatedBarcodes}
                                    duplicatedInTable={duplicatedInTable}
                                    removeThisDuplicatedFromTable={removeThisDuplicatedFromTable}
                                    pageNumber={pageNumber}
                                    navControlers={navControlers}
                                    showPageNav={showPageNav}
                                    onClickNext={onClickNext}
                                    onClickPrevious={onClickPrevious}
                                />
                            </Fragment> : null}
                        </div>
                        : null}
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

export default EditingProductsPage;