import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const LabelOptionsContext = createContext();

const LabelOptionsProvider = (props) => {

    const location = useLocation();

    const [ labelOptionsActive, setLabelOptionsActive ] = useState(false);

    const resetState = () => {
        // Nothing
    }

    useEffect(() => {
        if (!labelOptionsActive) {
            resetState();
        }
    }, [labelOptionsActive]);

    useEffect(() => {
        if (labelOptionsActive) {
            if (location.pathname !== "/detalles-de-producto") {
                setLabelOptionsActive(false);
                resetState();
            }
        }
    // eslint-disable-next-line
    }, [location])

    return (
        <LabelOptionsContext.Provider
            value={{ 
                labelOptionsActive, 
                setLabelOptionsActive
            }}
        >
            {props.children}
        </LabelOptionsContext.Provider>
    )
}

export default LabelOptionsProvider;