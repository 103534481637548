import "./sales.page.scss";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { AuthContext } from "../../../firebase/context";
import { AppContext } from "../../../context/AppContext";
import { Link, useLocation, useNavigate } from "react-router-dom";

import firebase from "../../../firebase";
import { collection, query, limit, getCountFromServer, getDocsFromServer, where, orderBy, startAt, startAfter } from "firebase/firestore";

import crossIcon from "../../../icons/cross.png";
import crossWhiteIcon from "../../../icons/cross-white.png";

import magnifyingIcon from "../../../icons/magnifying-glass.png";
import dollarImg from "../../../icons/dollar-sign-dark.png";

import onIcon from "../../../icons/on-button.png";
import offIcon from "../../../icons/off-button.png";

import saveIcon from "../../../icons/check-dark.png";

import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';

import xCancelDark from "../../../icons/cancel-dark.png";
import xCancelLight from "../../../icons/cancel-light.png";

import filterImgDark from "../../../icons/filter-dark.png";
import filterImgLight from "../../../icons/filter-light.png";

import adjustmentIconDark from '../../../icons/adjustment-dark.png';
import adjustmentIconLight from '../../../icons/adjustment-light.png';

import InputTypeDate from "../../../appTools/appToolsComponents/inputTypeDate/inputTypeDate";

import InputPickerDate from "../../../appTools/appToolsComponents/inputPickerDate/inputPickerDate";
import { InputPickerDateContext } from "../../../appTools/appToolsComponents/inputPickerDate/inputPickerDateContext";
import { SideMenuContext } from "../../../context/SideMenuContext";
import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";
import Spinner from "../../../components/spinner/spinner";
import { AlertContext } from "../../../components/alert/alertContext";
import SalesNewTable from "../../../appTools/appToolsComponents/salesNewTable/salesNewTable";

const SalesPage = () => {
    const location = useLocation();

    const { appTheme, appLanguage, getDateWithMonthShort, isTouchEnabled } = useContext(AppContext);
    const { desktopView, windowDimension, isSideMenuMinimized, smallView } = useContext(SideMenuContext);
    const { accountData, accessTo, setSaleListener, sales, shift, salesStatistics, authorizedUsers, setAuthorizedUsersListener, saveKeyInIndexedDB, setAccountData } = useContext(AuthContext); // managerAccess

    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    const { alertActive, setAlertData, setAlertActive, getErrorDescription, setOnAgree } = useContext(AlertContext);

    const [showConnectionError, setShowConnectionError] = useState(false);

    const [loadingSettings, setLoadingSettings] = useState(false);

    const [settingsIcon, setSettingsIcon] = useState(appTheme.dark ? adjustmentIconDark : adjustmentIconLight);

    const [dropDownSettingsOpen, setDropDownSettingsOpen] = useState(false);
    const [showSave, setShowSave] = useState(false);

    const { currentDate, pickerDateActive, setPickerDateActive, setPickerDateTitle, setCurrentDate, inputName, setInputName } = useContext(InputPickerDateContext);

    const [tempSettings, setTempSittings] = useState(accountData ? {
        "useShifts": accountData.useShifts,
    } : null);

    useEffect(() => {
        setTempSittings(accountData ? {
            "useShifts": accountData.useShifts,
        } : null);
    }, [accountData, dropDownSettingsOpen]);

    useEffect(() => {
        if (tempSettings && accountData) {
            if (tempSettings.useShifts === accountData.useShifts) {
                setShowSave(false);
            } else {
                setShowSave(true);
            }
        }
    }, [tempSettings, accountData]);

    async function updateNotificationSettings() {
        setLoadingSettings(true);
        setAlertActive(false);

        try {
            const response = await firebase.useUpdateSalesSettings({
                businessId: accountData.id,
                tempSettings: tempSettings,
            });

            setAccountData(response);
            saveKeyInIndexedDB("accountData", response);

            setAlertData({
                type: 'success',
                title: {
                    en: 'Settings saved successfully',
                    es: 'Ajustes guardados exitosamente'
                },
                code: '',
                description: {
                    en: `Shifts have been successfully ${tempSettings.useShifts ? "activated" : "deactivated"} in this business account.`,
                    es: `Los turnos han sido ${tempSettings.useShifts ? "activados" : "desactivados"} de manera exitosa en esta cuenta de negocios. `
                }
            });
            setAlertActive(true);

            setDropDownMenuOpen(false);
            setDropDownSettingsOpen(false);
            setLoadingSettings(false);
        } catch (error) {
            console.error(error);
            setLoadingSettings(false);

            setAlertData({
                type: 'error',
                title: {
                    en: 'Error saving settings',
                    es: 'Error al guardar ajustes',
                },
                code: "error",
                description: getErrorDescription("error")
            });
            setAlertActive(true);
        }
    }

    const isSalesSup = () => {
        // return managerAccess || accessTo.salesSup;
        return false;
    }

    const onSaveSettings = () => {
        if (!tempSettings) {
            return;
        }

        setAlertData({
            type: 'question',
            title: {
                en: `Are you sure you want to ${tempSettings.useShifts ? "activate" : "deactivate"} shifts?`,
                es: `¿Estás seguro de que quieres ${tempSettings.useShifts ? "activar " : "desactivar"} los turnos?`
            },
            code: '',
            description: {
                en: "IMPORTANT: It is not recommended for account users to make sales while processing this option. If any authorized user is in the middle of a shift, it is advised to complete it before proceeding.",
                es: "IMPORTANTE: No se recomienda que los usuarios de la cuenta realicen ventas mientras se procesa esta opción. Si algún usuario autorizado está en medio de un turno, se recomienda que lo finalice antes de continuar."
            }
        });
        setOnAgree(() => updateNotificationSettings);
        setAlertActive(true);
    }

    useEffect(() => {
        if (dropDownSettingsOpen) {
            setSettingsIcon(appTheme.dark ? xCancelDark : xCancelLight);
        } else {
            setSettingsIcon(appTheme.dark ? adjustmentIconDark : adjustmentIconLight);
        }
    }, [dropDownSettingsOpen, appTheme]);


    const onClickOpcion = (option) => {
        setTempSittings({
            ...tempSettings,
            [option]: !tempSettings[option],
        });
    }

    useEffect(() => {
        setCurrentDate(new Date());
        // eslint-disable-next-line
    }, []);

    // This prevent the page send a request twice to the backend
    const [generalState] = useState("0000");

    useEffect(() => {
        setSaleListener(true);
        setAuthorizedUsersListener(true);
        // eslint-disable-next-line
    }, [generalState]);

    const recentlyOpenedAccounts = Array.isArray(sales) ? sales : [];

    const [docsCount, setDocsCount] = useState(null);

    const [loading, setLoading] = useState(true);

    const [empty, setEmpty] = useState(false);
    const [showPageNav, setShowPageNav] = useState(false);

    // Use navControlers.page to initialize
    const [pageNumber, setPageNumber] = useState(null);

    const [searchingText, setSearchingText] = useState("");

    const [searchingByTextActive, setSearchingTextActive] = useState(false);

    // Keep state in the current path
    const [results, setResults] = useState(null);
    const [controler, setControler] = useState(null);
    const [pagesHistory, setPageHistory] = useState([]);
    const [navControlers, setNavControlers] = useState(null);

    // All of this is to avoid requesting data 
    // from the backend twice
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        if (refresh === 1 && navControlers && controler) {
            setRefresh(0);
            setPageNumber(navControlers.page);
            refreshSearch();
        }
        // eslint-disable-next-line
    }, [refresh]);

    const [activeRef, setActiveRef] = useState(null);
    const [activeId, setActiveId] = useState(null);

    const setActiveReference = (inputElement) => {
        setActiveRef(inputElement);
    }

    useEffect(() => {
        if (activeRef) {
            activeRef.scrollIntoView({
                block: 'center',
                inline: 'nearest'
            });
            // setActiveId(null);
        }
    }, [activeRef]);

    const onClickLink = (sale) => {
        let newControler = null;

        if (controler) {
            newControler = {
                ...controler,
                "triggerSearch": false,
            }
        }

        const newState = {
            "results": results,
            "filter": filter,
            "docsCount": docsCount,
            "controler": newControler,
            "pagesHistory": pagesHistory,
            "navControlers": navControlers,
            "showConnectionError": showConnectionError,
            "empty": null,
            "showPageNav": showPageNav,
            "activeId": sale.id,
            "searchingByTextActive": searchingByTextActive,
            "loading": false,
        }

        navigate(null, { replace: true, state: newState });
        navigate("/ver-detalles-de-venta", { state: { id: sale.id } })

    }

    useEffect(() => {
        if (location.state) {
            if (refresh === 0) {
                setRefresh(1)
            }

            setControler(location.state.controler);

            if (location.state.controler) {
                setSearchingText(location.state.controler.searchingText);
            }

            setPageHistory(location.state.pagesHistory);
            setNavControlers(location.state.navControlers);
            setShowConnectionError(location.state.showConnectionError);
            setEmpty(location.state.empty);
            setShowPageNav(location.state.showPageNav);
            setDocsCount(location.state.docsCount)
            setFilter(location.state.filter);
            setActiveId(location.state.activeId);
            setSearchingTextActive(location.state.searchingByTextActive);
            setLoading(location.state.loading);

            let page = null;

            if (location.state.navControlers) {
                setPageNumber(location.state.navControlers.page);
                page = location.state.navControlers.page;
            }

            const itemsDeleted = JSON.parse(localStorage.getItem('itemsDeleted'));

            function removeObjectsWithIds(array, idsToRemove) {
                if (Array.isArray((array))) {
                    return array.filter(obj => !idsToRemove.includes(obj.id));
                } else {
                    return null;
                }
            }

            if (location.controler) {
                if (
                    sales &&
                    page === 1 &&
                    location.state.controler.filterBy === "all" &&
                    !location.controler.dateFrom &&
                    !location.controler.dateTo &&
                    location.state.controler.searchingText.trim() === ""
                ) {
                    const recentlyOpenedAccounts = Array.isArray(sales) ? sales : [];
                    // filtar
                    const res = recentlyOpenedAccounts;
                    if (itemsDeleted) {
                        const filteredArray = removeObjectsWithIds(res, itemsDeleted);
                        setResults(filteredArray);
                    } else {
                        setResults(res);
                    }
                } else {
                    // filtar
                    const res = location.state.results;

                    if (itemsDeleted) {
                        const filteredArray = removeObjectsWithIds(res, itemsDeleted);
                        setResults(filteredArray);
                    } else {
                        setResults(res);
                    }
                }
            } else {
                // filtar
                const res = location.state.results;

                if (itemsDeleted) {
                    const filteredArray = removeObjectsWithIds(res, itemsDeleted);
                    setResults(filteredArray);
                } else {
                    setResults(res);
                }
            }

            if (!location.state.loading) {
                blink();
            }
        } else {
            setResults(recentlyOpenedAccounts);

            setControler({
                "filterBy": "all",
                "soldBy": "",
                "status": "",
                "dateFrom": null,
                "dateTo": null,
                "searchingText": "",
                "triggerSearch": false,
            });

            setPageHistory([]);

            setNavControlers({
                "page": 1,
                "totalPage": salesStatistics ? Math.ceil(salesStatistics.all / 30) : 1,
                "isPrevious": false,
                "isNext": salesStatistics ? (Math.ceil(salesStatistics.all / 30) > 1 ? true : false) : false
            });

            setShowConnectionError(false);
            setEmpty(false);
            setShowPageNav(false);
            setPageNumber(1);
            blink();
        }
        // eslint-disable-next-line 
    }, []);

    useEffect(() => {
        if (navControlers && salesStatistics) {
            if (
                sales &&
                navControlers.page === 1 &&
                controler.filterBy === "all" &&
                !controler.dateFrom &&
                !controler.dateTo &&
                controler.searchingText.trim() === ""
            ) {
                const recentlyOpenedAccounts = Array.isArray(sales) ? sales : [];
                setResults(recentlyOpenedAccounts);

                setNavControlers({
                    "page": 1,
                    "totalPage": salesStatistics ? Math.ceil(salesStatistics.all / 30) : 1,
                    "isPrevious": false,
                    "isNext": salesStatistics ? (Math.ceil(salesStatistics.all / 30) > 1 ? true : false) : false
                });
            }
        }

        if (salesStatistics && controler) {
            if ((controler.searchingText.trim() === "") && (!filter)) {
                if (salesStatistics.all > 0) {
                    setEmpty(false);
                    if (salesStatistics.all > 30) {
                        setShowPageNav(true);
                    } else {
                        setShowPageNav(false);
                    }
                } else {
                    setEmpty(true);
                }
            }
        }
        // eslint-disable-next-line
    }, [sales, salesStatistics, docsCount]);

    let observedRef = useRef(null);
    let headerObservedRef = useRef(null);

    const [width, setWidth] = useState();
    const [headerHeight, setHeaderHeight] = useState();
    const [filter, setFilter] = useState(null);

    useEffect(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (observedRef.current.offsetWidth !== width) {
                        setWidth(observedRef.current.offsetWidth);
                    }
                } catch { }
            });

            resizeObserver.observe(observedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [observedRef.current, windowDimension]);

    useEffect(() => {
        if (headerObservedRef) {
            if (!headerObservedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (headerObservedRef.current.offsetHeight !== headerHeight) {
                        setHeaderHeight(headerObservedRef.current.offsetHeight);
                    }
                } catch { }
            });

            resizeObserver.observe(headerObservedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [headerObservedRef.current, windowDimension, filter, results, isSideMenuMinimized]);
    
    const [disabled, setDisabled] = useState(true);

    const [dateStringFrom, setDateStringFrom] = useState("");
    const [dateStringTo, setDateStringTo] = useState("");

    const [dateObjFrom, setDateObjFrom] = useState(false);
    const [dateObjTo, setDateObjTo] = useState(false);

    const [showAlertSoldBy, setShowAlertSoldBy] = useState(false);
    const [showAlertStatus, setShowAlertStatus] = useState(false);
    const [showAlertFrom, setShowAlertFrom] = useState(false);
    const [showAlertTo, setShowAlertTo] = useState(false);

    const [filterBy, setFilterBy] = useState("all");

    const [soldBy, setSoldBy] = useState("");
    const [status, setStatus] = useState("");

    useEffect(() => {
        if (filterBy === "all" && (!dateObjFrom) && (!dateObjTo)) {
            if (filter) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        } else {
            setDisabled(false);
        }
    // eslint-disable-next-line
    }, [filterBy, dateObjFrom, dateObjTo])

    useEffect(() => {
        if (salesStatistics && controler) {
            if ((controler.searchingText.trim() === "") && (!filter)) {
                setDocsCount(salesStatistics.all);
            }
        }

    }, [filter, salesStatistics, controler]);

    const [filterIcon, setFilterIcon] = useState(appTheme.dark ? filterImgDark : filterImgLight);

    const [dropDownMenuOpen, setDropDownMenuOpen] = useState(false);

    const onScroll = () => {
        setDropDownMenuOpen(false);
        setDropDownSettingsOpen(false);
    }

    const onClickSettingsButton = () => {
        dropDownSettingsOpen ? setDropDownSettingsOpen(false) : setDropDownSettingsOpen(true);
    }

    let menuRef = useRef();
    let settingsRef = useRef();

    useEffect(() => {
        if (dropDownMenuOpen) {
            setFilterIcon(appTheme.dark ? xCancelDark : xCancelLight);
        } else {
            setFilterIcon(appTheme.dark ? filterImgDark : filterImgLight);
        }
    }, [dropDownMenuOpen, appTheme]);

    useEffect(() => {
        if (controler) {
            if (
                pageNumber === 1 &&
                sales &&
                controler.filterBy === "all" &&
                !controler.dateFrom &&
                !controler.dateTo &&
                controler.searchingText.trim() === ""
            ) {
                if (controler.triggerSearch) {
                    onResetAll();
                }
            } else {
                if (accountData && controler.triggerSearch) {
                    searchSales(controler);
                }
            }
        }
        // eslint-disable-next-line 
    }, [controler]);

    function removeAccents(text) {
        const normalizedString = text.normalize("NFD");
        const chainWithoutAccents = normalizedString.replace(/[\u0300-\u036f]/g, "");
        return chainWithoutAccents;
    }

    function optimizeTextForSearch(text) {
        const chainWithoutAccents = removeAccents(text);
        const lowerCaseText = chainWithoutAccents.toLowerCase();
        const trimText = lowerCaseText.trim();
        return trimText;
    }

    function getQueryValue(filter) {
        if (filter) {
            if (filter.filterBy === "soldBy" ) {
                return filter.soldBy;
            } else {
                if (filter.filterBy === "status") {
                    return filter.status;
                } else {
                    return null;
                }
            }
        } else {
            return null;
        }
    }

    const searchSales = async (controler) => {
        setActiveId(null);

        if (
            sales &&
            navControlers.page === 1 &&
            controler.filterBy === "all" &&
            !controler.dateFrom &&
            !controler.dateTo &&
            controler.searchingText.trim() === ""
        ) { return; }

        setLoading(true);
        setShowConnectionError(false);

        const searchingBy = optimizeTextForSearch(controler.searchingText);

        let q = null;
        let qNoOrder = null;

        if (filter) {
            if (filter.filterBy === "all") {
                if (searchingBy === "") {
                    if (filter.from) {
                        // To get the sales docs
                        q = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        qNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                        );
                    } else {
                        // To get the sales docs
                        q = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            orderBy("time", "desc"),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        qNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            orderBy("time", "desc"),
                        );
                    }
                } else {
                    if (filter.from) {
                        // To get the sales docs
                        q = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        qNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                        );
                    } else {
                        // To get the sales docs
                        q = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        qNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                        );
                    }
                }
            } else {
                if (searchingBy === "") {
                    if (filter.from) {
                        // To get the sales docs
                        q = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        qNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                        );
                    } else {
                        // To get the sales docs
                        q = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            orderBy("time", "desc"),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        qNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            orderBy("time", "desc"),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                        );
                    }
                } else {
                    if (filter.from) {
                        // To get the sales docs
                        q = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        qNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                        );
                    } else {
                        // To get the sales docs
                        q = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        qNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                        );
                    }
                }
            }
        } else {
            if (searchingBy === "") {
                // To get the sales docs
                q = query(
                    collection(firebase.db, `accounts/${accountData.id}/sales`),
                    orderBy("time", "desc"),
                    limit(30)
                );
            } else {
                // To get the sales docs
                q = query(
                    collection(firebase.db, `accounts/${accountData.id}/sales`),
                    where("searchKeys", "array-contains", searchingBy),
                    orderBy("time", "desc"),
                    limit(30)
                );

                // To get the docsCount to control the page nav
                qNoOrder = query(
                    collection(firebase.db, `accounts/${accountData.id}/sales`),
                    where("searchKeys", "array-contains", searchingBy),
                    orderBy("time", "desc"),
                );
            }
        }

        try {
            if (document.activeElement) {
                document.activeElement.blur();
            }
        } catch (error) {
            console.warn(error);
        }

        try {
            const snapshot = await getDocsFromServer(q);
            const res = [];

            let newDocsCount = null;

            if (filter || (searchingBy !== "")) {
                const snapshot = await getCountFromServer(qNoOrder);
                newDocsCount = snapshot.data().count;
            }

            setSearchingTextActive(searchingBy ? (searchingBy !== "" ? true : false) : false);

            setPageHistory([]);
            setPageNumber(1);

            snapshot.forEach((doc) => {
                if (doc.data()) {
                    const thisDoc = {
                        ...doc.data(),
                        "id": doc.id,
                    }

                    delete thisDoc.searchKeys;

                    res.push(thisDoc)
                }
            });

            if (filter || (searchingBy !== "")) {
                setDocsCount(newDocsCount);
                setShowPageNav(salesStatistics ? (Math.ceil(newDocsCount / 30) > 1 ? true : false) : false);
                setNavControlers({
                    "page": 1,
                    "totalPage": salesStatistics ? Math.ceil(newDocsCount / 30) : 1,
                    "isPrevious": false,
                    "isNext": salesStatistics ? (Math.ceil(newDocsCount / 30) > 1 ? true : false) : false
                });
            } else {
                setShowPageNav(salesStatistics ? (Math.ceil(salesStatistics.all / 30) > 1 ? true : false) : false);
                setNavControlers({
                    "page": 1,
                    "totalPage": salesStatistics ? Math.ceil(salesStatistics.all / 30) : 1,
                    "isPrevious": false,
                    "isNext": salesStatistics ? (Math.ceil(salesStatistics.all / 30) > 1 ? true : false) : false
                });
            }

            if (res.length > 0) {
                setEmpty(false);

                updateOrPushHistory({
                    page: 1,
                    startAt: res[0].time,
                    endAt: res[res.length - 1].time
                });
            } else {
                setEmpty(true);
            }

            setResults(Array.isArray(res) ? res : []);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
            setShowConnectionError(true);
        }
    }

    const getPageDetailsByNumber = (n) => {
        if (pagesHistory) {
            for (let i = 0; i < pagesHistory.length; i++) {
                if (pagesHistory[i]["page"] === n) {
                    return pagesHistory[i];
                }
            }
        }
        return null;
    }

    // To get the accounts use the pageHistory to call the funcition to get it.
    const updateOrPushHistory = (updatedObject) => {
        const pagesHistoryCopy = pagesHistory ? [...pagesHistory] : [];
        const page = updatedObject.page;
        const index = pagesHistoryCopy.findIndex(obj => obj["page"] === page);

        if (index !== -1) {
            // If the object exists, update it
            pagesHistoryCopy[index] = { ...pagesHistoryCopy[index], ...updatedObject };
            setPageHistory(pagesHistoryCopy);
        } else {
            // If the object doesn't exist, push a new object to the array
            pagesHistoryCopy.push(updatedObject);
            setPageHistory(pagesHistoryCopy);
        }
    }

    const onResetAll = () => {
        setActiveId(null);
        blink();

        setControler({
            "filterBy": "all",
            "soldBy": "",
            "status": "",
            "dateFrom": null,
            "dateTo": null,
            "searchingText": "",
            "triggerSearch": false,
        });

        setPageHistory([]);

        setResults(sales);

        setShowPageNav(salesStatistics ? (Math.ceil(salesStatistics.all / 30) > 1 ? true : false) : false);

        setNavControlers({
            "page": 1,
            "totalPage": salesStatistics ? Math.ceil(salesStatistics.all / 30) : 1,
            "isPrevious": false,
            "isNext": salesStatistics ? (Math.ceil(salesStatistics.all / 30) > 1 ? true : false) : false,
        });
        setPageNumber(1);
    }

    async function goToPagePrevious(q, pageN) {
        const snapshot = await getDocsFromServer(q);
        const res = [];

        snapshot.forEach((doc) => {
            if (doc.data()) {
                const thisDoc = {
                    ...doc.data(),
                    "id": doc.id,
                }
                delete thisDoc.searchKeys;
                res.push(thisDoc)
            }
        });

        if (!(res.length > 0)) {
            setResults([]);
        } else {
            updateOrPushHistory({
                page: pageN,
                startAt: res[0].time,
                endAt: res[res.length - 1].time
            });

            setResults(res);
        }
    }

    const onClickPrevious = async e => {
        e.preventDefault();

        setActiveId(null);

        if (navControlers.isPrevious === false) {
            return;
        }

        let startAtText = null;

        const searchingBy = optimizeTextForSearch(controler.searchingText);

        const lastPageNumber = navControlers.page - 1;

        if (lastPageNumber === 1 && (!filter) && searchingBy === "") {
            onResetAll();
            return
        }

        const lastPageDetails = getPageDetailsByNumber(lastPageNumber);

        if (!lastPageDetails) {
            console.log("ERROR********************");
        } else {
            startAtText = lastPageDetails.startAt;
        }

        let lastQuery = null;
        let lastQueryNoOrder = null;

        if (filter) {
            if (filter.filterBy === "all") {
                if (searchingBy === "") {
                    if (filter.from) {
                        // To get the sales docs
                        lastQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            startAt(startAtText),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        lastQueryNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                        );
                    } else {
                        // To get the sales docs
                        lastQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            orderBy("time", "desc"),
                            startAt(startAtText),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        lastQueryNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            orderBy("time", "desc"),
                        );
                    }
                } else {
                    if (filter.from) {
                        // To get the sales docs
                        lastQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            startAt(startAtText),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        lastQueryNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                        );
                    } else {
                        // To get the sales docs
                        lastQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            startAt(startAtText),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        lastQueryNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                        );
                    }
                }
            } else {
                if (searchingBy === "") {
                    if (filter.from) {
                        // To get the sales docs
                        lastQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                            startAt(startAtText),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        lastQueryNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                        );
                    } else {
                        // To get the sales docs
                        lastQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            orderBy("time", "desc"),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                            startAt(startAtText),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        lastQueryNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            orderBy("time", "desc"),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                        );
                    }
                } else {
                    if (filter.from) {
                        // To get the sales docs
                        lastQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                            startAt(startAtText),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        lastQueryNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                        );
                    } else {
                        // To get the sales docs
                        lastQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                            startAt(startAtText),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        lastQueryNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                        );
                    }
                }
            }
        } else {
            if (searchingBy === "") {
                // To get the sales docs
                lastQuery = query(
                    collection(firebase.db, `accounts/${accountData.id}/sales`),
                    orderBy("time", "desc"),
                    startAt(startAtText),
                    limit(30)
                );
            } else {
                // To get the sales docs
                lastQuery = query(
                    collection(firebase.db, `accounts/${accountData.id}/sales`),
                    where("searchKeys", "array-contains", searchingBy),
                    orderBy("time", "desc"),
                    startAt(startAtText),
                    limit(30)
                );

                // To get the docsCount to control the page nav
                lastQueryNoOrder = query(
                    collection(firebase.db, `accounts/${accountData.id}/sales`),
                    where("searchKeys", "array-contains", searchingBy),
                    orderBy("time", "desc"),
                    startAt(startAtText),
                );
            }
        }

        setLoading(true);

        try {
            await goToPagePrevious(lastQuery, navControlers.page - 1);

            let newDocsCount = null;

            if (filter || (searchingBy !== "")) {
                const snapshot = await getCountFromServer(lastQueryNoOrder);
                newDocsCount = snapshot.data().count;
            }

            setSearchingTextActive(searchingBy ? (searchingBy !== "" ? true : false) : false);

            const newPageNumber = navControlers.page - 1;
            setPageNumber(newPageNumber);

            if (filter || (searchingBy !== "")) {
                setDocsCount(newDocsCount);
                setNavControlers({
                    "page": newPageNumber,
                    "totalPage": salesStatistics ? Math.ceil(newDocsCount / 30) : 1,
                    "isPrevious": newPageNumber === 1 ? false : true,
                    "isNext": salesStatistics ? (Math.ceil(newDocsCount / 30) > newPageNumber ? true : false) : false
                });

            } else {
                setNavControlers({
                    "page": newPageNumber,
                    "totalPage": salesStatistics ? Math.ceil(salesStatistics.all / 30) : 1,
                    "isPrevious": newPageNumber === 1 ? false : true,
                    "isNext": salesStatistics ? (Math.ceil(salesStatistics.all / 30) > newPageNumber ? true : false) : false
                })
            }

        } catch (error) {
            console.error(error);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Connection issues',
                    es: 'Problemas de conexión',
                }
            });
        }

        setLoading(false);
    }

    async function goToNextPage(q) {

        const snapshot = await getDocsFromServer(q);
        const res = [];

        snapshot.forEach((doc) => {
            if (doc.data()) {
                const thisDoc = {
                    ...doc.data(),
                    "id": doc.id,
                }
                delete thisDoc.searchKeys;
                res.push(thisDoc)
            }
        });

        if (!(res.length > 0)) {
            setResults([]);
        } else {
            if (pagesHistory) {
                if (!(pagesHistory.length > 0)) {
                    const newHistory = [
                        {
                            page: 1,
                            startAt: results[0].time,
                            endAt: results[results.length - 1].time
                        },
                        {
                            page: navControlers.page + 1,
                            startAt: res[0].time,
                            endAt: res[res.length - 1].time
                        }
                    ];

                    setPageHistory(newHistory);
                } else {
                    updateOrPushHistory({
                        page: navControlers.page + 1,
                        startAt: res[0].time,
                        endAt: res[res.length - 1].time
                    });
                }
            } else {
                updateOrPushHistory({
                    page: navControlers.page + 1,
                    startAt: res[0].time,
                    endAt: res[res.length - 1].time
                });
            }
            setResults(res);
        }
    }

    const onClickNext = async e => {
        e.preventDefault();

        setActiveId(null);

        if (navControlers.isNext === false) {
            return;
        }

        let startAfterOrAfterText = null;
        let useStartAt = null;

        const nextPageNumber = navControlers.page + 1;
        const nextPageDetails = getPageDetailsByNumber(nextPageNumber);

        if (!nextPageDetails) {
            const lastProduct = results[results.length - 1];
            startAfterOrAfterText = lastProduct.time;
            useStartAt = false;
        } else {
            startAfterOrAfterText = nextPageDetails.startAt;
            useStartAt = true;
        }

        const searchingBy = optimizeTextForSearch(controler.searchingText);

        let nextQuery = null;
        let nextQueryNoOrder = null;

        if (filter) {
            if (filter.filterBy === "all") {
                if (searchingBy === "") {
                    if (filter.from) {
                        // To get the sales docs
                        nextQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            useStartAt ? startAt(startAfterOrAfterText) : startAfter(startAfterOrAfterText),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        nextQueryNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                        );
                    } else {
                        // To get the sales docs
                        nextQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            orderBy("time", "desc"),
                            useStartAt ? startAt(startAfterOrAfterText) : startAfter(startAfterOrAfterText),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        nextQueryNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            orderBy("time", "desc"),
                        );
                    }
                } else {
                    if (filter.from) {
                        // To get the sales docs
                        nextQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            useStartAt ? startAt(startAfterOrAfterText) : startAfter(startAfterOrAfterText),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        nextQueryNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                        );
                    } else {
                        // To get the sales docs
                        nextQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            useStartAt ? startAt(startAfterOrAfterText) : startAfter(startAfterOrAfterText),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        nextQueryNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                        );
                    }
                }
            } else {
                if (searchingBy === "") {
                    if (filter.from) {
                        // To get the sales docs
                        nextQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                            useStartAt ? startAt(startAfterOrAfterText) : startAfter(startAfterOrAfterText),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        nextQueryNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                        );
                    } else {
                        // To get the sales docs
                        nextQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            orderBy("time", "desc"),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                            useStartAt ? startAt(startAfterOrAfterText) : startAfter(startAfterOrAfterText),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        nextQueryNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            orderBy("time", "desc"),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                        );
                    }
                } else {
                    if (filter.from) {
                        // To get the sales docs
                        nextQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                            useStartAt ? startAt(startAfterOrAfterText) : startAfter(startAfterOrAfterText),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        nextQueryNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                        );
                    } else {
                        // To get the sales docs
                        nextQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                            useStartAt ? startAt(startAfterOrAfterText) : startAfter(startAfterOrAfterText),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        nextQueryNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/sales`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                        );
                    }
                }
            }
        } else {
            if (searchingBy === "") {
                // To get the sales docs
                nextQuery = query(
                    collection(firebase.db, `accounts/${accountData.id}/sales`),
                    orderBy("time", "desc"),
                    useStartAt ? startAt(startAfterOrAfterText) : startAfter(startAfterOrAfterText),
                    limit(30)
                );
            } else {
                // To get the sales docs
                nextQuery = query(
                    collection(firebase.db, `accounts/${accountData.id}/sales`),
                    where("searchKeys", "array-contains", searchingBy),
                    orderBy("time", "desc"),
                    useStartAt ? startAt(startAfterOrAfterText) : startAfter(startAfterOrAfterText),
                    limit(30)
                );

                // To get the docsCount to control the page nav
                nextQueryNoOrder = query(
                    collection(firebase.db, `accounts/${accountData.id}/sales`),
                    where("searchKeys", "array-contains", searchingBy),
                    orderBy("time", "desc"),
                    useStartAt ? startAt(startAfterOrAfterText) : startAfter(startAfterOrAfterText),
                );
            }
        }

        setLoading(true);

        try {
            await goToNextPage(nextQuery);

            let newDocsCount = null;

            if (filter || (searchingBy !== "")) {
                const snapshot = await getCountFromServer(nextQueryNoOrder);
                newDocsCount = snapshot.data().count;
            }

            setSearchingTextActive(searchingBy ? (searchingBy !== "" ? true : false) : false);

            const newPageNumber = navControlers.page + 1;
            setPageNumber(newPageNumber);

            if (filter || (searchingBy !== "")) {
                setDocsCount(newDocsCount);
                setNavControlers({
                    "page": newPageNumber,
                    "totalPage": salesStatistics ? Math.ceil(newDocsCount / 30) : 1,
                    "isPrevious": newPageNumber === 1 ? false : true,
                    "isNext": salesStatistics ? (Math.ceil(newDocsCount / 30) > newPageNumber ? true : false) : false
                });

            } else {
                setNavControlers({
                    "page": newPageNumber,
                    "totalPage": salesStatistics ? Math.ceil(salesStatistics.all / 30) : 1,
                    "isPrevious": newPageNumber === 1 ? false : true,
                    "isNext": salesStatistics ? (Math.ceil(salesStatistics.all / 30) > newPageNumber ? true : false) : false
                })
            }
        } catch (error) {
            console.error(error);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Connection issues',
                    es: 'Problemas de conexión',
                }
            });
        }
        setLoading(false);
    }

    const navigate = useNavigate();

    const handleChange = e => {
        setSearchingText(e.target.value);
    }

    let inputRef = null;

    const setRefernce = (inputElement) => {
        inputRef = inputElement;
    }

    const cleanInput = () => {
        try {
            if (inputRef) {
                inputRef.focus();
            }
        } catch (error) {
            console.warn(error);
        }

        setSearchingText("");
    }

    useEffect(() => {
        if (dropDownSettingsOpen && settingsRef) {
            if (settingsRef.current) {
                let handler = (e) => {
                    if (!settingsRef.current.contains(e.target)) {
                        if (!pickerDateActive && !alertActive) {
                            setDropDownSettingsOpen(false);
                        }
                    };
                };
                document.addEventListener("mousedown", handler);
                return () => {
                    document.removeEventListener("mousedown", handler);
                }
            }
        }
    });

    useEffect(() => {
        if (dropDownMenuOpen && menuRef) {
            if (menuRef.current) {
                let handler = (e) => {
                    if (!menuRef.current.contains(e.target)) {
                        if (!pickerDateActive) {
                            setDropDownMenuOpen(false);
                        }
                    };
                };
                document.addEventListener("mousedown", handler);
                return () => {
                    document.removeEventListener("mousedown", handler);
                }
            }
        }
    });

    const onClickUserButton = () => {
        dropDownMenuOpen ? setDropDownMenuOpen(false) : setDropDownMenuOpen(true);
    }

    const esPlaceholder = "Escribe palabra clave aquí...";
    const enPlaceholder = "Write keyword here...";

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            setControler({
                ...controler,
                "searchingText": searchingText,
                "triggerSearch": true,
            });
        }
    }

    useEffect(() => {
        if (accountData) {
            if (!accountData.active) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accountData]);

    useEffect(() => {
        if (accessTo) {
            if (!(accessTo.sales || accessTo.salesSup || accessTo.salesConsult)) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accessTo]);

    const blink = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 100);
    }

    const refreshSearch = async () => {
        if (
            sales &&
            navControlers.page === 1 &&
            controler.filterBy === "all" &&
            !controler.dateFrom &&
            !controler.dateTo &&
            controler.searchingText.trim() === ""
        ) {
            const recentlyOpenedAccounts = Array.isArray(sales) ? sales : [];
            setResults(recentlyOpenedAccounts);

            setNavControlers({
                "page": 1,
                "totalPage": salesStatistics ? Math.ceil(salesStatistics.all / 30) : 1,
                "isPrevious": false,
                "isNext": salesStatistics ? (Math.ceil(salesStatistics.all / 30) > 1 ? true : false) : false
            });
        } else {
            let startAtText = null;

            // This is like previous, but we usel the current page number
            const lastPageNumber = navControlers.page;

            const searchingBy = optimizeTextForSearch(controler.searchingText);

            if (lastPageNumber === 1 && (!filter) && searchingBy === "") {
                onResetAll();
                return
            }

            const lastPageDetails = getPageDetailsByNumber(lastPageNumber);

            if (!lastPageDetails) {
                console.log("ERROR********************");
            } else {
                startAtText = lastPageDetails.startAt;
            }

            let lastQuery = null;

            if (filter) {
                if (filter.filterBy === "all") {
                    if (searchingBy === "") {
                        if (filter.from) {
                            // To get the sales docs
                            lastQuery = query(
                                collection(firebase.db, `accounts/${accountData.id}/sales`),
                                orderBy("time", "desc"),
                                where("time", ">=", filter.from.getTime()),
                                where("time", "<=", filter.to.getTime()),
                                startAt(startAtText),
                                limit(30)
                            );
                        } else {
                            // To get the sales docs
                            lastQuery = query(
                                collection(firebase.db, `accounts/${accountData.id}/sales`),
                                orderBy("time", "desc"),
                                startAt(startAtText),
                                limit(30)
                            );
                        }
                    } else {
                        if (filter.from) {
                            // To get the sales docs
                            lastQuery = query(
                                collection(firebase.db, `accounts/${accountData.id}/sales`),
                                where("searchKeys", "array-contains", searchingBy),
                                orderBy("time", "desc"),
                                where("time", ">=", filter.from.getTime()),
                                where("time", "<=", filter.to.getTime()),
                                startAt(startAtText),
                                limit(30)
                            );
                        } else {
                            // To get the sales docs
                            lastQuery = query(
                                collection(firebase.db, `accounts/${accountData.id}/sales`),
                                where("searchKeys", "array-contains", searchingBy),
                                orderBy("time", "desc"),
                                startAt(startAtText),
                                limit(30)
                            );
                        }
                    }
                } else {
                    if (searchingBy === "") {
                        if (filter.from) {
                            // To get the sales docs
                            lastQuery = query(
                                collection(firebase.db, `accounts/${accountData.id}/sales`),
                                orderBy("time", "desc"),
                                where("time", ">=", filter.from.getTime()),
                                where("time", "<=", filter.to.getTime()),
                                where(filter.filterBy, "==", getQueryValue(filter)),
                                startAt(startAtText),
                                limit(30)
                            );
                        } else {
                            // To get the sales docs
                            lastQuery = query(
                                collection(firebase.db, `accounts/${accountData.id}/sales`),
                                orderBy("time", "desc"),
                                where(filter.filterBy, "==", getQueryValue(filter)),
                                startAt(startAtText),
                                limit(30)
                            );
                        }
                    } else {
                        if (filter.from) {
                            // To get the sales docs
                            lastQuery = query(
                                collection(firebase.db, `accounts/${accountData.id}/sales`),
                                where("searchKeys", "array-contains", searchingBy),
                                orderBy("time", "desc"),
                                where("time", ">=", filter.from.getTime()),
                                where("time", "<=", filter.to.getTime()),
                                where(filter.filterBy, "==", getQueryValue(filter)),
                                startAt(startAtText),
                                limit(30)
                            );
                        } else {
                            // To get the sales docs
                            lastQuery = query(
                                collection(firebase.db, `accounts/${accountData.id}/sales`),
                                where("searchKeys", "array-contains", searchingBy),
                                orderBy("time", "desc"),
                                where(filter.filterBy, "==", getQueryValue(filter)),
                                startAt(startAtText),
                                limit(30)
                            );
                        }
                    }
                }
            } else {
                if (searchingBy === "") {
                    // To get the sales docs
                    lastQuery = query(
                        collection(firebase.db, `accounts/${accountData.id}/sales`),
                        orderBy("time", "desc"),
                        startAt(startAtText),
                        limit(30)
                    );
                } else {
                    // To get the sales docs
                    lastQuery = query(
                        collection(firebase.db, `accounts/${accountData.id}/sales`),
                        where("searchKeys", "array-contains", searchingBy),
                        orderBy("time", "desc"),
                        startAt(startAtText),
                        limit(30)
                    );
                }
            }

            try {
                await goToPagePrevious(lastQuery, navControlers.page);
                setSearchingTextActive(searchingBy ? (searchingBy !== "" ? true : false) : false);
            } catch (error) {
                console.error(error);
            }
        }
    }

    function searchById(id) {
        if (authorizedUsers) {
            const authUser = authorizedUsers.find(item => item.id === id);
            return authUser.name;
        } else {
            return "--";
        }
    }

    const getUserName = (soldBy) => {
        if (appLanguage.en) {
            if (soldBy === "") {
                return "In all records"
            } else {
                return searchById(soldBy)
            }
        } else {
            if (soldBy === "") {
                return "En todos los registros"
            } else {
                return searchById(soldBy)
            }
        }
    }

    const searchByStatus = (status) => {
        if (status === "completed") {
            return appLanguage.en ? "Showing complete sales" : "Mostrando ventas completas";
        } else {
            if (status === "pending") {
                return appLanguage.en ? "Showing pending sales" : "Mostrando ventas pendientes";
            } else {
                return appLanguage.en ? "Showing canceled sales" : "Mostrando ventas canceladas"
            }
        }
    }

    const getSaleStatus = (status) => {
        if (appLanguage.en) {
            if (status === "") {
                return "In all records"
            } else {
                return searchByStatus(status)
            }
        } else {
            if (status === "") {
                return "En todos los registros"
            } else {
                return searchByStatus(status)
            }
        }
    }

    const onClickApplyFilter = () => {
        let newDateFrom = null;
        let newDateTo = null;

        if (filterBy !== "all") {
            if (filterBy === "soldBy") {
                if (soldBy.trim() === "") {
                    setShowAlertSoldBy(true);
                    return;
                }
            } else {
                if (status.trim() === "") {
                    setShowAlertStatus(true);
                    return;
                }
            }
        }

        if ((!dateObjFrom) !== (!dateObjTo)) {
            if (!dateObjFrom) {
                setShowAlertFrom(true);
                return;
            }
            if (!dateObjTo) {
                setShowAlertTo(true);
                return;
            }
        }

        if (dateObjFrom) {
            newDateFrom = new Date(dateObjFrom.getTime());
            newDateFrom.setHours(0);
        }

        if (dateObjTo) {
            newDateTo = new Date(dateObjTo.getTime());
            newDateTo.setHours(23, 59, 59, 999);
        }

        setFilter({
            "filterBy": filterBy,
            "soldBy": soldBy,
            "status": status,
            "from": newDateFrom,
            "to": newDateTo,
        });

        setControler({
            ...controler,
            "filterBy": filterBy,
            "soldBy": soldBy,
            "status": status,
            "dateFrom": newDateFrom ? newDateFrom.getTime() : null,
            "dateTo": newDateTo ? newDateTo.getTime() : null,
            "searchingText": searchingText,
            "triggerSearch": true,
        });

        setPageHistory([]);

        setDropDownMenuOpen(false);
    }

    const onClickCleanFilter = () => {
        setActiveId(null);
        setResults(recentlyOpenedAccounts);

        setControler({
            "filterBy": "all",
            "soldBy": "",
            "status": "",
            "dateFrom": null,
            "dateTo": null,
            "searchingText": "",
            "triggerSearch": false,
        });

        setPageHistory([]);

        setNavControlers({
            "page": 1,
            "totalPage": salesStatistics ? Math.ceil(salesStatistics.all / 30) : 1,
            "isPrevious": false,
            "isNext": salesStatistics ? (Math.ceil(salesStatistics.all / 30) > 1 ? true : false) : false
        });

        setShowConnectionError(false);

        setEmpty(salesStatistics ? (salesStatistics.all > 0 ? false : true) : true);
        setShowPageNav(salesStatistics ? (Math.ceil(salesStatistics.all / 30) > 1 ? true : false) : false);

        setPageNumber(1);

        setSearchingText("");
        setFilter(null);

        setFilterBy("all");
        setSoldBy("");
        setStatus("");
        setDateObjFrom(null);
        setDateObjTo(null);
        setDateStringFrom("");
        setDateStringTo("");

        setSearchingTextActive(false);

        blink();
    }

    const search = async () => {
        setControler({
            ...controler,
            "searchingText": searchingText,
            "triggerSearch": true,
        });
    }

    const onChangeFilterBy = (e) => {
        setFilterBy(e.target.value);
        setSoldBy("");
        setStatus("");
    }

    const onChangeSoldBy = (e) => {
        setShowAlertSoldBy(false);
        setSoldBy(e.target.value);
    }

    const onChangeStatus = (e) => {
        setShowAlertStatus(false);
        setStatus(e.target.value);
    }

    const onClickPickFrom = () => {
        setPickerDateActive(true);
        setPickerDateTitle(appLanguage.en ? "Select the date from which you want to filter" : "Selecciona la fecha desde la cual deseas filtrar");
        if (dateObjFrom) {
            setCurrentDate(dateObjFrom);
        }
        setInputName("dateFrom");
    }

    const onClickPickTo = () => {
        setPickerDateActive(true);
        setPickerDateTitle(appLanguage.en ? "Select the date up to which you want to filter" : "Selecciona la fecha hasta la cual deseas filtrar");
        if (dateObjTo) {
            setCurrentDate(dateObjTo);
        }
        setInputName("dateTo");
    }

    const tryAgain = () => {
        setActiveId(null);
        setControler({
            ...controler,
            "triggerSearch": true,
        })
    }

    useEffect(() => {
        if (inputName) {
            switch (inputName) {
                case "dateFrom":
                    setDateStringFrom(`${currentDate.getDate().toString().padStart(2, '0')} / ${(currentDate.getMonth() + 1).toString().padStart(2, '0')} / ${currentDate.getFullYear()}`);
                    setShowAlertFrom(false);
                    break;
                case "dateTo":
                    setDateStringTo(`${currentDate.getDate().toString().padStart(2, '0')} / ${(currentDate.getMonth() + 1).toString().padStart(2, '0')} / ${currentDate.getFullYear()}`);
                    setShowAlertTo(false);
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line
    }, [currentDate]);

    useEffect(() => {
        if (filter) {
            setDateObjFrom(filter.from);
            setDateObjTo(filter.to);
            setSoldBy(filter.user);

            if (filter.from) {
                setDateStringFrom(`${filter.from.getDate().toString().padStart(2, '0')} / ${(filter.from.getMonth() + 1).toString().padStart(2, '0')} / ${filter.from.getFullYear()}`);
            } else {
                setDateStringFrom("");
            }

            if (filter.to) {
                setDateStringTo(`${filter.to.getDate().toString().padStart(2, '0')} / ${(filter.to.getMonth() + 1).toString().padStart(2, '0')} / ${filter.to.getFullYear()}`);
            } else {
                setDateStringTo("");
            }
        } else {
            setFilterBy("all");
            setSoldBy("");
            setStatus("");
            setDateObjFrom(null);
            setDateObjTo(null);
            setDateStringFrom("");
            setDateStringTo("");
        }
        setShowAlertFrom(false);
        setShowAlertTo(false);
        // eslint-disable-next-line
    }, [dropDownMenuOpen]);

    const [searchInputFocus, setSearchInputFocus] = useState(false);

    useEffect(() => {
        if (
            inputRef &&
            inputRef.contains(document.activeElement) &&
            document.activeElement instanceof HTMLInputElement
        ) {
            setSearchInputFocus(true);
        } else {
            setSearchInputFocus(false);
        }
        // eslint-disable-next-line
    }, [document.activeElement]);

    const checkSearchInput = () => {
        if (
            inputRef &&
            inputRef.contains(document.activeElement) &&
            document.activeElement instanceof HTMLInputElement
        ) {
            setSearchInputFocus(true);
        } else {
            setSearchInputFocus(false);
        }
    }

    const getAuthUserName = (userId) => {
        if (authorizedUsers) {
            const user = authorizedUsers.find(user => user.id === userId);
            return user ? user.name : "--";
        } else {
            return "--"
        }
    }

    useEffect(() => {
        let newControler = null;

        if (controler) {
            newControler = {
                ...controler,
                "triggerSearch": false,
            }
        }

        const newState = {
            "results": results,
            "filter": filter,
            "docsCount": docsCount,
            "controler": newControler,
            "pagesHistory": pagesHistory,
            "navControlers": navControlers,
            "showConnectionError": showConnectionError,
            "empty": empty,
            "showPageNav": showPageNav,
            "activeId": activeId,
            "searchingByTextActive": searchingByTextActive,
            "loading": false,
        }
        navigate(null, { replace: true, state: newState });

        if (accessTo) {
            if (!(accessTo.sales || accessTo.salesSup || accessTo.salesConsult)) {
                navigate("/", { replace: true })
            }
        }

        // eslint-disable-next-line
    }, [results, controler, pagesHistory, navControlers, showConnectionError, empty, showPageNav, activeId, filter, docsCount, searchingByTextActive]);

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Sales' : 'Ventas'} </title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && accessTo ? <Fragment>
                    {accountData.active ?
                        <div className={`page-choose-account-item`} ref={observedRef}> {/** Usar este elemeto para saber el tamaño de la página */}
                            {(accessTo.sales || accessTo.salesSup || accessTo.salesConsult) ? <Fragment>
                                <InputPickerDate pickerWidth={width} />
                                {desktopView ?
                                    <ToolTitle
                                        icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                        text={appLanguage.en ? "Sales" : "Ventas"}
                                    />
                                    : null}
                                <div ref={headerObservedRef} className="sales-services-first-section">
                                    <div className="sales-services-first-item">
                                        <div className="div-search-input-home" style={{ marginTop: 0, maxWidth: "400px" }}>
                                            <input
                                                ref={setRefernce}
                                                type="search"
                                                onBlur={checkSearchInput}
                                                onFocus={checkSearchInput}
                                                autoComplete="off"
                                                autoCorrect="off"
                                                className={`search-input-home ${appTheme.dark ? "search-input-home-dar dar-sec-bg" : "search-input-home-lig lig-sec-bg"}`}
                                                value={searchingText}
                                                onChange={e => handleChange(e)}
                                                name="searchingText"
                                                onKeyDown={e => handleKeyPress(e)}
                                                maxLength={30}
                                                placeholder={appLanguage.en ? enPlaceholder : esPlaceholder}
                                                style={appTheme.dark ? { colorScheme: "dark" } : { colorScheme: "light" }}
                                            />

                                            {searchingText === "" ? null :
                                                <div className={`search-div-delete ${searchInputFocus ? (appTheme.dark ? "search-button-home-dar-focus" : "search-button-home-lig-focus") : (appTheme.dark ? "search-button-home-dar" : "search-button-home-lig")} ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`}>
                                                    <button onClick={cleanInput} className={appTheme.dark ? "search-div-delete-button-dark" : "search-div-delete-button-light"}>
                                                        <img src={appTheme.dark ? crossWhiteIcon : crossIcon} alt="delete" />
                                                    </button>
                                                </div>
                                            }

                                            <button
                                                className="search-button-home"
                                                type="submit"
                                                onClick={search}
                                            >
                                                <img src={magnifyingIcon} alt="Search box" />
                                            </button>
                                        </div>

                                        <div className="sales-filter-botton-main unselectable">
                                            <div ref={menuRef} className="sales-filter-botton-container">

                                                <button
                                                    onClick={onClickUserButton}
                                                    className={`filer-sales-btn ${appTheme.dark ? `${isTouchEnabled() ? "filer-sales-btn-dark-no-hover" : "filer-sales-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "filer-sales-btn-light-no-hover" : "filer-sales-btn-light"} border-box-light`}`}
                                                    style={{
                                                        width: isSalesSup() ? (smallView ? "60px" : "100px") : "100px",
                                                        minHeight: "38.4px"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: "20px", height: "20px", marginRight: isSalesSup() ? (smallView ? 0 : "12px") : "12px" }}
                                                        src={filterIcon}
                                                        alt="Filter"
                                                    />
                                                    {isSalesSup() ? (smallView ? null : <p style={dropDownMenuOpen ? { fontWeight: "bold" } : {}}>{appLanguage.en ? "Filter" : "Filtrar"}</p>) : <p style={dropDownMenuOpen ? { fontWeight: "bold" } : {}}>{appLanguage.en ? "Filter" : "Filtrar"}</p>}
                                                </button>

                                                <span
                                                    className={`sales-pointing-up ${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"} ${dropDownMenuOpen ? 'active' : 'inactive'}`}
                                                    style={{
                                                        marginLeft: isSalesSup() ? (smallView ? "43px" : "60px") : "60px",
                                                    }}
                                                />

                                                <div
                                                    style={{
                                                        boxShadow: appTheme.dark ? "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" : "1px 1px 1px 0.8px rgba(0, 0, 0, 0.2)",
                                                    }}
                                                    className={`dropdown-menu-sales ${appTheme.dark ? "dropdown-menu-sales-dark border-box-dark" : "dropdown-menu-sales-light border-box-light"} ${dropDownMenuOpen ? 'active' : 'inactive'}`}
                                                >
                                                    {isSalesSup() ? (smallView ? <p style={{ textAlign: "center", marginBottom: "10px" }}><b>{appLanguage.en ? "Filter sales" : "Filtrar ventas"}</b></p> : null) : null}

                                                    <label className="filter-labels-inputs" htmlFor="filterBy">{appLanguage.en ? "Filter by" : "Filtrar por"}</label>
                                                    <select
                                                        style={appTheme.dark ? { colorScheme: "dark" } : { colorScheme: "light" }}
                                                        className={`filter-select-input-sales ${appTheme.dark ? "dar-pri-bg input-border-box-dark" : "lig-pri-bg input-border-box-light"}`}
                                                        name="filterBy"
                                                        id="filterBy"
                                                        value={filterBy}
                                                        onChange={e => onChangeFilterBy(e)}
                                                    >
                                                        <option value="all">{appLanguage.en ? "All" : "Todo"}</option>
                                                        <option value="soldBy">{appLanguage.en ? "Seller" : "Vendedor"}</option>
                                                        <option value="status">{appLanguage.en ? "Status" : "Estado"}</option>
                                                    </select>

                                                    {filterBy !== "all" ? <Fragment>
                                                        {filterBy === "soldBy" ? <Fragment>
                                                            <label className="filter-labels-inputs" htmlFor="soldBy">{appLanguage.en ? "Sale made by" : "Venta realizada por"}</label>
                                                            <select
                                                                style={appTheme.dark ? { colorScheme: "dark" } : { colorScheme: "light" }}
                                                                className={`filter-select-input-sales ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"} ${showAlertSoldBy ? "border-date-alert" : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")}`}
                                                                name="soldBy"
                                                                id="soldBy"
                                                                value={soldBy}
                                                                onChange={e => onChangeSoldBy(e)}
                                                            >
                                                                <option value="">{appLanguage.en ? "-- select --" : "-- seleccionar --"}</option>
                                                                {authorizedUsers ? <Fragment>
                                                                    {authorizedUsers.length > 0 ?
                                                                        <Fragment>
                                                                            {authorizedUsers.map((record) => {
                                                                                return (
                                                                                    <option key={record.id} value={record.id}>{record.name}</option>
                                                                                );
                                                                            })}
                                                                        </Fragment>
                                                                        : null}
                                                                </Fragment> : null}
                                                            </select>
                                                        </Fragment> : <Fragment>
                                                            <label className="filter-labels-inputs" htmlFor="status">{appLanguage.en ? "Sale status" : "Estado de la venta"}</label>
                                                            <select
                                                                style={appTheme.dark ? { colorScheme: "dark" } : { colorScheme: "light" }}
                                                                className={`filter-select-input-sales ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"} ${showAlertStatus ? "border-date-alert" : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")}`}
                                                                name="status"
                                                                id="status"
                                                                value={status}
                                                                onChange={e => onChangeStatus(e)}
                                                            >
                                                                <option value="">{appLanguage.en ? "-- select --" : "-- seleccionar --"}</option>
                                                                <option value="completed">{appLanguage.en ? "Completed" : "Completa"}</option>
                                                                <option value="pending">{appLanguage.en ? "Pending" : "Pendiente"}</option>
                                                                <option value="canceled">{appLanguage.en ? "Canceled" : "Cancelada"}</option>
                                                            </select>
                                                        </Fragment>}
                                                    </Fragment> : null}

                                                    <label className="filter-labels-inputs">{appLanguage.en ? "From " : "Desde "} {showAlertFrom ? (appLanguage.en ? "** invalid **" : "** inválida **") : null}</label>
                                                    <InputTypeDate
                                                        dateObj={dateObjFrom}
                                                        setDateObj={setDateObjFrom}
                                                        dateString={dateStringFrom}
                                                        setDateString={setDateStringFrom}
                                                        showAlert={showAlertFrom}
                                                        setShowAlert={setShowAlertFrom}
                                                        onClickPick={onClickPickFrom}
                                                    />

                                                    <label className="filter-labels-inputs">{appLanguage.en ? "To" : "Hasta"} {showAlertTo ? (appLanguage.en ? "** invalid **" : "** inválida **") : null}</label>
                                                    <InputTypeDate
                                                        dateObj={dateObjTo}
                                                        setDateObj={setDateObjTo}
                                                        dateString={dateStringTo}
                                                        setDateString={setDateStringTo}
                                                        showAlert={showAlertTo}
                                                        setShowAlert={setShowAlertTo}
                                                        onClickPick={onClickPickTo}
                                                    />

                                                    <div className="filter-button-action-div">
                                                        <button
                                                            disabled={disabled}
                                                            onClick={onClickApplyFilter}
                                                            className="filter-button-btn-div"
                                                            style={disabled ? {
                                                                backgroundColor: "#3f526698",
                                                                color: appTheme.dark ? "rgb(173, 173, 173)" : "rgba(255, 255, 255, 0.712)",
                                                                cursor: "default",
                                                                boxShadow: "none",
                                                            } : null}
                                                        >
                                                            {appLanguage.en ? "Apply" : "Aplicar"}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            {isSalesSup() ?
                                                <div ref={settingsRef} className="notification-filter-botton-container unselectable">
                                                    <button
                                                        style={{
                                                            marginTop: "20px",
                                                            width: isSalesSup() ? (smallView ? "60px" : "120px") : "120px"
                                                        }}
                                                        onClick={onClickSettingsButton}
                                                        className={`notification-list-config-btn ${appTheme.dark ? `${isTouchEnabled() ? "settings-notification-btn-dark-no-hover" : "settings-notification-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "settings-notification-btn-light-no-hover" : "settings-notification-btn-light"} border-box-light`}`}
                                                    >
                                                        <img
                                                            style={{
                                                                width: "20px",
                                                                height: "20px",
                                                                marginRight: isSalesSup() ? (smallView ? 0 : "12px") : "12px"
                                                            }}
                                                            src={settingsIcon}
                                                            alt="Settings"
                                                        />

                                                        {isSalesSup() ? (smallView ? null : <p style={dropDownSettingsOpen ? { fontWeight: "bold" } : {}}>{appLanguage.en ? "Settings" : "Ajustes"}</p>) : <p style={dropDownSettingsOpen ? { fontWeight: "bold" } : {}}>{appLanguage.en ? "Settings" : "Ajustes"}</p>}
                                                    </button>

                                                    <span
                                                        className={`notification-pointing-up ${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"} ${dropDownSettingsOpen ? 'active' : 'inactive'}`}
                                                        style={{
                                                            marginLeft: isSalesSup() ? (smallView ? "23px" : "50px") : "50px",
                                                        }}
                                                    />

                                                    <div
                                                        style={{
                                                            boxShadow: appTheme.dark ? "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" : "1px 1px 1px 0.8px rgba(0, 0, 0, 0.2)",
                                                            marginLeft: isSalesSup() ? (smallView ? "-100px" : "-97px") : "-97px",
                                                        }}
                                                        className={`dropdown-menu-notification ${appTheme.dark ? "dropdown-menu-notification-dark border-box-dark" : "dropdown-menu-notification-light border-box-light"} ${dropDownSettingsOpen ? 'active' : 'inactive'}`}
                                                    >
                                                        {loadingSettings ?
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    minHeight: "200px",
                                                                    paddingTop: "50px"
                                                                }}
                                                            >
                                                                <Spinner />
                                                            </div>
                                                            :
                                                            <Fragment>
                                                                {tempSettings ?
                                                                    <div
                                                                        style={{
                                                                            minHeight: "250px",
                                                                        }}
                                                                    >
                                                                        <div className="drop-men-not-option-con">
                                                                            <h3>{appLanguage.en ? "Sales settings" : "Ajustes de ventas"}:</h3>

                                                                            <button
                                                                                onClick={() => onClickOpcion("useShifts")}
                                                                                className={`${isTouchEnabled() ? "drop-men-not-option-btn-no-hover" : "drop-men-not-option-btn"}`}
                                                                            >
                                                                                <img src={tempSettings.useShifts ? onIcon : offIcon} alt="Check" />
                                                                                <p className={appTheme.dark ? "dark-text" : "light-text"}><b>{appLanguage.en ? "Shifts" : "Turnos"}</b></p>
                                                                            </button>
                                                                        </div>

                                                                        <div
                                                                            className="drop-men-not-option-con"
                                                                            style={{
                                                                                marginTop: "0px",
                                                                                padding: "20px"
                                                                            }}
                                                                        >
                                                                            <p>{appLanguage.en ? "Shifts represent the periods during which cashiers or salespeople make sales, enabling precise control of cash flow." : "Los turnos representan los periodos en los que los cajeros o vendedores realizan ventas, permitiendo un control preciso del flujo de caja."}</p>
                                                                        </div>

                                                                        <div
                                                                            className="drop-men-not-save-con"
                                                                            style={{
                                                                                marginTop: "35px"
                                                                            }}
                                                                        >
                                                                            <button
                                                                                onClick={onSaveSettings}
                                                                                disabled={!showSave}
                                                                                className="new-customer-bottons-button-save"
                                                                                style={!showSave ? {
                                                                                    backgroundColor: "#3f526698",
                                                                                    color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                                                                    cursor: "default"
                                                                                } : null}
                                                                            >
                                                                                <img src={saveIcon} alt="Save" />
                                                                                {appLanguage.en ? <p>Save</p> : <p>Guardar</p>}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    : null}
                                                            </Fragment>
                                                        }
                                                    </div>
                                                </div>
                                                : null}

                                            {accessTo.sales ?
                                                <div className="customer-header-button-div" style={{ marginTop: "20px" }}>
                                                    <Link to={accountData.useShifts ? (shift ? "/carrito-de-compras" : "/gestionar-turnos") : "/carrito-de-compras"} className="customer-header-button-link" style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                                        <img src={dollarImg} alt="Add" />
                                                        <p style={{ whiteSpace: "nowrap" }}><b>{appLanguage.en ? "Make sale" : "Realizar venta"}</b></p>
                                                    </Link>
                                                </div>
                                                : null}

                                        </div>

                                        {filter ?
                                            <div className={`filter-sales-details-main-con ${appTheme.dark ? "border-box-dark" : "border-box-light"}`}>
                                                <div className="filter-sales-details-data">
                                                    {filter.from ?
                                                        (filter.from.getTime() > filter.to.getTime() ?
                                                            <p style={{ fontSize: "12px", color: "rgba(255, 0, 0, 0.76)" }}><b>*** {appLanguage.en ? "Invalid Date Range" : "Rango de fechas no válido"} ***</b></p>
                                                            :
                                                            <p style={{ fontSize: "12px" }}><b>{getDateWithMonthShort(filter.from)} - {getDateWithMonthShort(filter.to)}</b></p>
                                                        )
                                                        : <p style={{ color: appTheme.dark ? "#74b7ff" : "#1083ff" }}><b>{appLanguage.en ? "Search" : "Búsqueda"}</b></p>}

                                                    <p style={{ fontSize: "15px" }}>{filter.filterBy === "soldBy" ? getUserName(filter.soldBy) : getSaleStatus(filter.status)}</p>
                                                </div>

                                                <button className="filter-sales-details-clean-filter-btn" onClick={onClickCleanFilter}>
                                                    <img
                                                        style={{ width: "16px", height: "16px", marginRight: "16px" }}
                                                        src={appTheme.dark ? crossWhiteIcon : crossIcon}
                                                        alt="Clean filter"
                                                    />
                                                </button>
                                            </div>
                                            :
                                            searchingByTextActive ?
                                                <div className={`filter-sales-details-main-con ${appTheme.dark ? "border-box-dark" : "border-box-light"}`}>
                                                    <div className="filter-sales-details-data">
                                                        <p style={{ color: appTheme.dark ? "#74b7ff" : "#1083ff" }}><b>{appLanguage.en ? "Search" : "Búsqueda"}</b></p>
                                                        <p style={{ fontSize: "15px" }}>{appLanguage.en ? "In all records" : "En todos los registros"}</p>
                                                    </div>
                                                    <button className="filter-sales-details-clean-filter-btn" onClick={onClickCleanFilter}>
                                                        <img
                                                            style={{ width: "16px", height: "16px", marginRight: "16px" }}
                                                            src={appTheme.dark ? crossWhiteIcon : crossIcon}
                                                            alt="Clean filter"
                                                        />
                                                    </button>
                                                </div>
                                                : null
                                        }
                                    </div>
                                </div>

                                <div
                                    className="sales-records-main-cont"
                                    style={{
                                        padding: "0px",
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        style={{
                                            marginTop: 0,
                                            width: "100%",
                                            maxWidth: "1286px",
                                            display: "flex",
                                            flexDirection: "column",
                                            margin: "0px",
                                        }}
                                    >
                                        <SalesNewTable
                                            onScroll={onScroll}
                                            headerHeight={headerHeight + (desktopView ? 70 : 30)}
                                            loading={loading}
                                            getAuthUserName={getAuthUserName}
                                            showConnectionError={showConnectionError}
                                            tryAgain={tryAgain}
                                            results={results}
                                            onClickLink={onClickLink}
                                            activeId={activeId}
                                            setActiveReference={setActiveReference}
                                            pageNumber={pageNumber}
                                            navControlers={navControlers}
                                            showPageNav={showPageNav}
                                            onClickNext={onClickNext}
                                            onClickPrevious={onClickPrevious}
                                            dropDownMenuOpen={dropDownMenuOpen}
                                            dropDownSettingsOpen={dropDownSettingsOpen}
                                            empty={empty}
                                        />
                                    </div>
                                </div>
                            </Fragment> : null}
                        </div>
                        : null}
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

export default SalesPage;