import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";

const PriceListEditTable = ({ 
    priceList, 
    fieldAlert, 
    fieldAlertReference, 
    setFieldAlert,
    itbis,
    setPriceListTemp,
    normalizeNumber
}) => {
    const { appTheme, formatRationalNumber, dropDownCartOpen, dropDownProfileMenuOpen, } = useContext(AppContext);

    const [makeSticky, setMakeSticky] = useState(true);

    useEffect(() => {
        if (dropDownCartOpen || dropDownProfileMenuOpen) {
            setMakeSticky(false);
        } else {
            setMakeSticky(true);
        }
    }, [dropDownCartOpen, dropDownProfileMenuOpen]);

    const [activeField, setActiveFiel] = useState(null);

    const isFieldActive = (x, y) => {
        if (activeField) {
            if (activeField.x === x && activeField.y === y) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const isRowActive = (x) => {
        if (activeField) {
            if (activeField.x === x) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const isColumnActive = (y) => {
        if (activeField) {
            if (activeField.y === y) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const borderDark = "2px solid rgba(255, 255, 255, 0.075)";
    const border2Dark = "2px solid rgba(255, 255, 255, 0.075)";
    const border2Light = "2px solid rgba(0, 0, 0, 0.075)";

    const widthLetter = "30px";
    const widthPrice = "145px";
    const widthITBIS = "145px";
    const widthFinalPrice = "145px";

    const setFocus = (x, y) => {
        setActiveFiel({ x, y });
    }

    const isFieldAlert = (x, y) => {
        if (fieldAlert) {
            if (fieldAlert.x === x && fieldAlert.y === y) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }

    function calculatePriceWithoutTax(totalWithTax, taxPercentage) {
        // Convert the tax percentage to a decimal
        let taxRate = taxPercentage / 100;
        // Calculate the price without tax
        let priceWithoutTax = totalWithTax / (1 + taxRate);
        return priceWithoutTax;
    }

    const checkField = (x, y, fieldContent) => {
        switch (y) {
            case 1:
                // priceWithoutTax
                if (fieldAlert) {
                    if (fieldAlert.x === x) {
                        setFieldAlert(null);
                    }
                }

                let priceNum = "";
                let isPointPrice = false;

                for (let i = 0; i < fieldContent.length; i++) {
                    if (fieldContent[i - 3] !== ".") {
                        if (fieldContent[i] === ".") {
                            if (!isPointPrice) {
                                priceNum += fieldContent[i];
                                isPointPrice = true;
                            }
                        } else {
                            if ((!(isNaN(fieldContent[i]))) && (fieldContent[i].trim() !== "")) {
                                priceNum += fieldContent[i];
                            }
                        }
                    }
                }

                if (!isNaN(priceNum.trim()) && priceNum.trim() !== "") {

                    let priceTaxPercentage = 0;

                    if (itbis === "16%") {
                        priceTaxPercentage = 0.16;
                    } else {
                        if (itbis === "18%") {
                            priceTaxPercentage = 0.18;
                        }
                    }

                    const itbisAmount = Number(priceNum) * priceTaxPercentage;

                    const priceRes = {
                        "priceWithoutTax": priceNum,
                        "finalPrice": formatRationalNumber(Number(priceNum) + itbisAmount),
                        "itbisAmount": formatRationalNumber(itbisAmount)
                    }

                    return priceRes;
                } else {
                    const priceRes = {
                        "priceWithoutTax": "",
                        "finalPrice": "",
                        "itbisAmount": ""
                    }
                    return priceRes;
                }
          
            case 3:
                // finalPrice
                if (fieldAlert) {
                    if (fieldAlert.x === x) {
                        setFieldAlert(null);
                    }
                }

                let totalPriceNum = "";
                let isPointTotalPrice = false;

                let taxPercentage = 0;

                if (itbis === "16%") {
                    taxPercentage = 16;
                } else {
                    if (itbis === "18%") {
                        taxPercentage = 18;
                    }
                }

                for (let i = 0; i < fieldContent.length; i++) {
                    if (fieldContent[i - 3] !== ".") {
                        if (fieldContent[i] === ".") {
                            if (!isPointTotalPrice) {
                                totalPriceNum += fieldContent[i];
                                isPointTotalPrice = true;
                            }
                        } else {
                            if ((!(isNaN(fieldContent[i]))) && (fieldContent[i].trim() !== "")) {
                                totalPriceNum += fieldContent[i];
                            }
                        }
                    }
                }

                const priceWithoutTax = calculatePriceWithoutTax(Number(totalPriceNum), taxPercentage);
                const itbisAmount = Number(totalPriceNum) - priceWithoutTax;

                if (!isNaN(totalPriceNum.trim()) && totalPriceNum.trim() !== "") {
                    const priceRes = {
                        "priceWithoutTax": formatRationalNumber(priceWithoutTax),
                        "finalPrice": totalPriceNum,
                        "itbisAmount": formatRationalNumber(itbisAmount)
                    }

                    return priceRes;
                } else {
                    const priceRes = {
                        "priceWithoutTax": "",
                        "finalPrice": "",
                        "itbisAmount": ""
                    }

                    return priceRes;
                }

            default:
                break;
        }
    }

    const getPriceIndex = (x) => {
        switch (x) {
            case "B":
                return 1;
            case "C":
                return 2;
            case "D":
                return 3;
            case "E":
                return 4;
            default:
                break;
        }
    }

    const handleChangeInput = (e, x, y) => {
        e.preventDefault();

        const fieldContent = e.target.value;

        let temRecords = [...priceList];

        const verifiedFieldContent = checkField(x, y, fieldContent);

        temRecords[getPriceIndex(x)].price = verifiedFieldContent.priceWithoutTax;
        temRecords[getPriceIndex(x)].itbis = verifiedFieldContent.itbisAmount;
        temRecords[getPriceIndex(x)].finalPrice = verifiedFieldContent.finalPrice;
                
        setPriceListTemp(temRecords);
    };

    const formatAmount = (x) => {
        let temRecords = [...priceList];

        const priceWithoutTax = temRecords[getPriceIndex(x)].price;
        const itbisAmount = temRecords[getPriceIndex(x)].itbis;
        const finalPrice = temRecords[getPriceIndex(x)].finalPrice

        temRecords[getPriceIndex(x)].price = priceWithoutTax.trim() === "" ? "" : formatRationalNumber(priceWithoutTax);
        temRecords[getPriceIndex(x)].itbis = itbisAmount.trim() === "" ? "" : formatRationalNumber(itbisAmount);
        temRecords[getPriceIndex(x)].finalPrice = finalPrice.trim() === "" ? "" : formatRationalNumber(finalPrice);

        setPriceListTemp(temRecords);
    }

    return (
        <div
            className={`inventory-table-container ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`}
            style={{
                colorScheme: appTheme.dark ? "dark" : "light",
                borderRadius: "10px",
                maxWidth: "533px"
            }}
        >
            <div className="inventory-table-section-1" style={{overflow: "auto", height: "auto"}}>
                <table className="inventory-table-select-table">
                    <thead>
                        <tr>
                            <th className="inventory-table-select-th inventory-table-select-td-sticky " style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthLetter, maxWidth: widthLetter }}>
                                
                            </th>
                            <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthPrice, maxWidth: widthPrice, backgroundColor: isColumnActive(1) ? "#425c79" : "#627890" }}>
                                <p>Precio sin ITBIS</p>
                            </th>
                            <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthITBIS, maxWidth: widthITBIS }}>
                                <p>ITBIS ({itbis})</p>
                            </th>
                            <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthFinalPrice, maxWidth: widthFinalPrice, borderTopRightRadius: "10px", backgroundColor: isColumnActive(3) ? "#425c79" : "#627890" }}>
                                <p>Precio final</p>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {priceList ?
                            priceList.map((item, index) => {
                                return (
                                    <tr
                                        style={{ cursor: "auto" }}
                                        key={index}
                                    >
                                        <td ref={isFieldAlert(item.letter, 1) ? fieldAlertReference : null} className={`inventory-table-create-th-sticky ${appTheme.dark ? "inventory-table-create-td-sticky-dark" : "inventory-table-create-td-sticky-light"}`} style={{ position: makeSticky ? "sticky" : "static", border: appTheme.dark ? border2Dark : border2Light, minWidth: widthLetter, maxWidth: widthLetter, backgroundColor: isRowActive(item.letter) ? "#425c79" : "", color: isRowActive(item.letter) ? "white" : "" }}>
                                            <span style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
                                                <p>{item.letter}</p>
                                            </span>
                                        </td>

                                        {item.letter === "A" ?
                                            <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthPrice, maxWidth: widthPrice }}>
                                                <p style={{ whiteSpace: "nowrap", lineHeight: 1 }}>{item.price === "" ? "" : formatRationalNumber(item.price)}</p>
                                            </td>
                                        :
                                            <td>
                                                <textarea
                                                    inputMode="numeric"
                                                    onBlur={() => formatAmount(item.letter)}
                                                    maxLength={40}
                                                    className={`inventory-table-create-td-content-editable ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}
                                                    style={{ border: isFieldAlert(item.letter, 1) ? "2px solid red" : (isFieldActive(item.letter, 1) ? "2px solid rgb(108, 167, 221)" : (appTheme.dark ? border2Dark : border2Light)), minWidth: widthPrice, maxWidth: widthPrice }}
                                                    onFocus={() => setFocus(item.letter, 1)}
                                                    value={item.price}
                                                    onKeyDown={e => handleKeyPress(e)}
                                                    onChange={(e) => handleChangeInput(e, item.letter, 1)}
                                                    rows={"1"}
                                                />
                                            </td>
                                        }
                                        
                                        <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthITBIS, maxWidth: widthITBIS }}>
                                            <p style={{ whiteSpace: "nowrap", lineHeight: 1 }}>{item.itbis === "" ? "" : formatRationalNumber(item.itbis)}</p>
                                        </td>

                                        {item.letter === "A" ?
                                            <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthPrice, maxWidth: widthPrice }}>
                                                <p style={{ whiteSpace: "nowrap", lineHeight: 1 }}>{item.finalPrice === "" ? "" : formatRationalNumber(item.finalPrice)}</p>
                                            </td>
                                        :
                                            <td>
                                                <textarea
                                                    inputMode="numeric"
                                                    onBlur={() => formatAmount(item.letter)}
                                                    maxLength={40}
                                                    className={`inventory-table-create-td-content-editable ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}
                                                    style={{ border: isFieldActive(item.letter, 3) ? "2px solid rgb(108, 167, 221)" : (appTheme.dark ? border2Dark : border2Light), minWidth: widthPrice, maxWidth: widthPrice }}
                                                    onFocus={() => setFocus(item.letter, 3)}
                                                    value={item.finalPrice}
                                                    onKeyDown={e => handleKeyPress(e)}
                                                    onChange={(e) => handleChangeInput(e, item.letter, 3)}
                                                    rows={"1"}
                                                />
                                            </td>
                                        }
                                    </tr>
                                );
                            })
                        : null}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default PriceListEditTable;