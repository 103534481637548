import { Fragment, useContext, useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AuthContext } from "../../../firebase/context";
import { Helmet } from "react-helmet-async";
import SettingsActionRequired from "../../../components/settings-action-required/settingsActionRequired";
import { AppContext } from "../../../context/AppContext";

import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';

import { uid } from "uid";

import firebase from "../../../firebase";
import { onSnapshot, doc, updateDoc } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";

import { SideMenuContext } from "../../../context/SideMenuContext";
import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";
import PictureProductViewer from "../../../appTools/appToolsComponents/pictureProductViewer/pictureProductViewer";
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";

import noPicture from "../../../img/no-picture.png";
import noReady from "../../../img/nothing.png";
import error404 from "../../../img/404-error.png";

import deleteIcon from "../../../icons/closed-dark.png";
import editIcon from "../../../icons/signature-dark.png";
import reloadIcon from "../../../icons/reload-dark.png";
import labelIconDark from "../../../icons/label-dark.png";
import settingsIconDark from "../../../icons/settings-dark.png";

import uploadIconDark from "../../../icons/upload-dark.png";
import uploadIconLight from "../../../icons/upload-light.png";

import indicatorsIconDark from "../../../icons/line-chart-dark.png";

import crossWhiteIcon from "../../../icons/cross-white.png";
import saveIcon from "../../../icons/check-dark.png";

import "./productDetails.page.scss";
import { AlertContext } from "../../../components/alert/alertContext";
import Spinner3 from "../../../components/spinner3/spinner3";
import Spinner2 from "../../../components/spinner2/spinner2";
import Spinner from "../../../components/spinner/spinner";
import LabelOptions from "../../../appTools/appToolsComponents/labelOptions/labelOptions";
import { LabelOptionsContext } from "../../../appTools/appToolsComponents/labelOptions/labelOptionsContext";

const ProductDetailsPage = () => {

    let observedRef = useRef(null);
    let pageObserverRef = useRef(null);

    const {  setLabelOptionsActive } = useContext(LabelOptionsContext);

    const { accountData, accessTo, updateProductInShoppingCart, removeFromShoppingCart } = useContext(AuthContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    const { appLanguage, appTheme, isTouchEnabled, formatRationalNumber, getCategory, getRatePer, getThisBusinessProductsCategories, getUnitsOfMeasurementFor } = useContext(AppContext);
    const { desktopView, windowDimension, isSideMenuMinimized } = useContext(SideMenuContext);

    const { setAlertData, setAlertActive, getErrorDescription, setOnAgree } = useContext(AlertContext);

    const [disabled, setDisabled] = useState(true);
    const [duplicatedBarcode, setDuplicatedBarcode] = useState(null);

    const [fieldAlertRef, setFieldAlertRef] = useState(null);
    const fieldAlertReference = (inputElement) => {
        setFieldAlertRef(inputElement);
    }

    useEffect(() => {
        if (fieldAlertRef) {
            fieldAlertRef.scrollIntoView({ behavior: "smooth" });
        }
        // eslint-disable-next-line 
    }, [fieldAlertRef]);

    const navigate = useNavigate();
    const location = useLocation();

    const [product, setProduct] = useState(null);

    const inputsNames = {
        "name": uid(8),
        "description": uid(8),
        "quantity": uid(8),
        "location": uid(8),
        "barcode": uid(8),
        "priceWithoutTax": uid(8),
        "itbisRate": uid(8),
        "finalPrice": uid(8),
    }

    const getInputName = (uid) => {
        switch (uid) {
            case inputsNames.name:
                return "name";
            case inputsNames.description:
                return "description";
            case inputsNames.quantity:
                return "quantity";
            case inputsNames.location:
                return "location";
            case inputsNames.barcode:
                return "barcode";
            case inputsNames.finalPrice:
                return "finalPrice";
            case inputsNames.priceWithoutTax:
                return "priceWithoutTax";
            case inputsNames.itbisRate:
                return "itbisRate";
            default:
                return uid;
        }
    }

    const [newProduct, setNewProduct] = useState(null);
    const [editing, setEditing] = useState(false);

    const [request, setRequest] = useState("0000");

    const [loadingPicture, setLoadingPicture] = useState(true);
    const [uploadingPicture, setUploadingPicture] = useState(false);

    const [pictureProductViewerActive, setPictureProductViewerActive] = useState(false);

    const [loading, setLoading] = useState(true);

    const [loadingBarcode, setLoadingBarcode] = useState(false);

    const [doNotDefineQuantity, setDoNotDefineQuantity] = useState(true);

    const doNotDefineQuantityHandler = () => {
        if (!doNotDefineQuantity) {
            setNewProduct({
                ...newProduct,
                "quantity": "",
            });
        } else {
            setNewProduct({
                ...newProduct,
                "quantity": typeof product.quantity === 'number' ? product.quantity.toString() : "",
            });
        }

        setQuantityAlert({
            alert: false,
            enText: '',
            esText: ''
        });
        
        setDoNotDefineQuantity(!doNotDefineQuantity);
    }

    const blink = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 100);
    }

    const getHeaderHeight = () => {
        setTimeout(() => {
            if (headerObservedRef) {
                if (!headerObservedRef.current) {
                    return;
                }

                if (headerObservedRef.current.offsetHeight !== headerHeight) {
                    setHeaderHeight(headerObservedRef.current.offsetHeight);
                }
            }
        }, 300);
    }

    useEffect(() => {
        blink();
        getHeaderHeight();
        // eslint-disable-next-line
    }, [])

    const onClickPicture = () => {
        setPictureProductViewerActive(true);
    }

    useEffect(() => {
        if (accountData) {
            if (!accountData.active) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accountData]);

    useEffect(() => {
        if (accessTo) {
            if (!(accessTo.inventory)) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accessTo]);

    useEffect(() => {
        if (location.state) {
            const itemsDeleted = JSON.parse(localStorage.getItem('itemsDeleted'));

            if (itemsDeleted) {
                const productId = location.state.id;

                function containsString(arr, target) {
                    for (let i = 0; i < arr.length; i++) {
                        if (arr[i].includes(target)) {
                            return true;
                        }
                    }
                    return false;
                }

                if (containsString(itemsDeleted, productId)) {
                    if (window.history.state && window.history.state.idx > 0) {
                        navigate(-1);
                    } else {
                        navigate('/', { replace: true });
                    }
                } else {
                    setProduct(location.state);
                    setRequest(uid());
                }

            } else {
                setProduct(location.state);
                setRequest(uid());
            }

        } else {
            navigate("/productos", { replace: true })
        }
        // eslint-disable-next-line 
    }, []);

    // Now I have to listeng to the product doc
    useEffect(() => {
        if (product && (request !== "0000")) {
            const unsub = onSnapshot(doc(firebase.db, `accounts/${accountData.id}/products`, product.id), (doc) => {
                const productUpdated = {
                    ...doc.data(),
                    "id": doc.id,
                };

                delete productUpdated.searchKeys;

                if (doc.data()) {
                    setProduct(productUpdated);
                    updateProductInShoppingCart(productUpdated);
                    navigate(null, { replace: true, state: productUpdated });
                }
            }, err => {
                console.error(err);
            });
            return () => unsub();
        }
        // eslint-disable-next-line
    }, [request]);

    const { setPictureInMemory } = useContext(AuthContext);

    const [pictureData, setPictureData] = useState(null);
    const [loadingError, setLoadingError] = useState(false);

    const [noImgFile, setNotImgFile] = useState(() => {
        if (product) {
            if (product.picturesLocation) {
                if (product.picturesLocation[0]) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        } else {
            return false
        }
    });

    useEffect(() => {
        if (product) {
            if (product.picturesLocation && product.id) {
                if (product.picturesLocation[0]) {
                    const path = product.picturesLocation[0];
                    if (!uploadingPicture) {
                        if (!pictureData) {
                            setLoadingPicture(true);
                        } else {
                            if (path !== pictureData.location) {
                                setLoadingPicture(true);
                            }
                        }
                        checkIfImageExistInPr(path);
                    }
                }
            }
            setNotImgFile(() => {
                if (product.picturesLocation) {
                    if (product.picturesLocation[0]) {
                        return true
                    } else {
                        setLoadingPicture(false);
                        return false
                    }
                } else {
                    setLoadingPicture(false);
                    return false
                }
            });
            setNewProduct({
                "name": product.name,
                "category": product.category ? product.category : "general",
                "rate": product.rate,
                "description": product.description ? product.description : "",
                "quantity": typeof product.quantity === 'number' ? product.quantity.toString() : "",
                "location": product.location ? product.location.toString() : "",
                "barcode": typeof product.barcode === 'number' ? product.barcode.toString() : "",
                "priceWithoutTax": formatRationalNumber(product.priceWithoutTax),
                "finalPrice": formatRationalNumber(product.finalPrice),
                "itbisPercentage": product.itbisPercentage.toString(),
                "itbisAmount": formatRationalNumber(product.itbisAmount),
            });
            setDoNotDefineQuantity(product.quantity === null ? true : false)
        }
        // eslint-disable-next-line
    }, [product]);

    const checkIfImageExistInPr = (path) => {

        const dimensions = "d600x600";

        const dbId = product.id;

        const indexedDB = window.indexedDB;
        const request = indexedDB.open("hazcuentas-pr-pictures-database", 1);

        request.onerror = function (event) { console.error(event); }

        request.onupgradeneeded = function () {
            const db = request.result;
            if (!db.objectStoreNames.contains("hazcuentas-pr-pictures-store")) {
                db.createObjectStore("hazcuentas-pr-pictures-store", { keyPath: "id" });
            }
        }

        request.onsuccess = function () {
            const db = request.result;
            const transaction = db.transaction('hazcuentas-pr-pictures-store', 'readwrite');

            transaction.onerror = (err) => {
                console.warn(err);
                return false;
            }

            const store = transaction.objectStore('hazcuentas-pr-pictures-store');
            const requestToCheck = store.get(dbId);

            requestToCheck.onsuccess = (ev) => {
                const request = ev.target;
                if (request.result) {
                    if (path === request.result.key.path) {
                        if (request.result.key[dimensions]) {
                            setPictureData({
                                pictureFile: request.result.key[dimensions],
                                location: path
                            });
                            setLoadingPicture(false);
                        } else {
                            checkIfImageExistInGe(path);
                        }
                    } else {
                        checkIfImageExistInGe(path);
                    }
                } else {
                    checkIfImageExistInGe(path);
                }
            }

            requestToCheck.onerror = () => {
                checkIfImageExistInGe(path);
            }
        }
    }

    const checkIfImageExistInGe = (path) => {
        const dimensions = "d600x600";

        const dbId = product.id;

        const indexedDB = window.indexedDB;
        const request = indexedDB.open("hazcuentas-pictures-database", 1);

        request.onerror = function (event) { console.error(event); }

        request.onupgradeneeded = function () {
            const db = request.result;
            if (!db.objectStoreNames.contains("hazcuentas-pictures-store")) {
                db.createObjectStore("hazcuentas-pictures-store", { keyPath: "id" });
            }
        }

        request.onsuccess = function () {
            const db = request.result;
            const transaction = db.transaction('hazcuentas-pictures-store', 'readwrite');

            transaction.onerror = (err) => {
                console.warn(err);
                return false;
            }

            const store = transaction.objectStore('hazcuentas-pictures-store');
            const requestToCheck = store.get(dbId);

            requestToCheck.onsuccess = (ev) => {
                const request = ev.target;
                if (request.result) {
                    if (path === request.result.key.path) {
                        if (request.result.key[dimensions]) {
                            setPictureData({
                                pictureFile: request.result.key[dimensions],
                                location: path
                            });
                            setLoadingPicture(false);
                        } else {
                            getPictureFile(path);
                        }
                    } else {
                        getPictureFile(path);
                    }
                } else {
                    getPictureFile(path);
                }
            }

            requestToCheck.onerror = () => {
                getPictureFile(path);
            }
        }
    }

    const getPictureFile = async (path) => {
        // This is a recursive function that download all the dimensions 
        // of a picture store in firebase store

        let requestCounter = 6;

        const getPictureByDimensions = async (dimensions) => {
            try {
                const locationPath = `${path}${dimensions}`;
                const imageRef = ref(firebase.storage, locationPath);
                const url = await getDownloadURL(imageRef);
                const xhr = new XMLHttpRequest();

                xhr.responseType = 'blob';
                xhr.onload = () => {
                    const blob = xhr.response;
                    const fr = new FileReader();
                    fr.readAsDataURL(blob);
                    fr.addEventListener('load', () => {
                        const urlData = fr.result;
                        setPictureData({
                            pictureFile: urlData,
                            location: path
                        });
                        setLoadingPicture(false);
                        if (dimensions === "_140x140") {
                            dbKey.d140x140 = urlData;
                            getPictureByDimensions("_300x300");
                        } else {
                            if (dimensions === "_300x300") {
                                dbKey.d300x300 = urlData;
                                getPictureByDimensions("_600x600");
                            } else {
                                if (dimensions === "_600x600") {
                                    dbKey.d600x600 = urlData;
                                    setPictureInMemory(dbKey, product.id);
                                    setLoadingError(false);
                                }
                            }
                        }
                    });
                };
                xhr.open('GET', url);
                xhr.send();
            } catch (error) {
                if ((requestCounter > 0) && (error.code !== "storage/unauthorized")) {
                    requestCounter--;
                    setTimeout(() => {
                        getPictureByDimensions("_140x140");
                    }, 1000);
                } else {
                    console.error(error);
                    if (pictureData === null) {
                        setLoadingError(true);
                    }
                    setLoadingPicture(false);
                }
            }
        }

        const dbKey = {
            "path": path,
            "d140x140": null,
            "d300x300": null,
            "d600x600": null,
        }

        getPictureByDimensions("_140x140");
    }

    // eslint-disable-next-line
    const getPictureFileAfterUploading = async (path) => {
        // This is a recursive function that download all the dimensions 
        // of a picture store in firebase store
        let requestCounter = 6;
        setUploadingPicture(true);

        const getPictureByDimensions = async (dimensions) => {
            try {
                const locationPath = `${path}${dimensions}`;
                const imageRef = ref(firebase.storage, locationPath);
                const url = await getDownloadURL(imageRef);
                const xhr = new XMLHttpRequest();

                xhr.responseType = 'blob';
                xhr.onload = () => {
                    const blob = xhr.response;
                    const fr = new FileReader();
                    fr.readAsDataURL(blob);
                    fr.addEventListener('load', () => {
                        const urlData = fr.result;
                        setPictureData({
                            pictureFile: urlData,
                            location: path
                        });
                        setUploadingPicture(false);
                        if (dimensions === "_140x140") {
                            dbKey.d140x140 = urlData;
                            getPictureByDimensions("_300x300");
                        } else {
                            if (dimensions === "_300x300") {
                                dbKey.d300x300 = urlData;
                                getPictureByDimensions("_600x600");
                            } else {
                                if (dimensions === "_600x600") {
                                    dbKey.d600x600 = urlData;
                                    setLoadingError(false);
                                    setPictureInMemory(dbKey, product.id);
                                }
                            }
                        }
                    });
                };
                xhr.open('GET', url);
                xhr.send();
            } catch (error) {
                if (requestCounter > 0) {
                    requestCounter--;
                    setTimeout(() => {
                        getPictureByDimensions("_140x140");
                    }, 1000);
                } else {
                    setUploadingPicture(false);
                    if (pictureData === null) {
                        setLoadingError(true);
                    }
                    console.error(error);
                }
            }
        }

        const dbKey = {
            "path": path,
            "d140x140": null,
            "d300x300": null,
            "d600x600": null,
        }

        getPictureByDimensions("_140x140");
    }

    const [pageWidth, setPageWidth] = useState(() => {
        if (pageObserverRef) {
            if (!pageObserverRef.current) {
                return windowDimension.width;
            } else {
                try {
                    const width = pageObserverRef.current.offsetWidth
                    return width;
                } catch {
                    return windowDimension.width;
                }
            }
        } else {
            return windowDimension.width;
        }
    });

    useEffect(() => {
        if (pageObserverRef) {
            if (!pageObserverRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (pageObserverRef.current.offsetWidth !== width) {
                        setPageWidth(pageObserverRef.current.offsetWidth);
                    }
                } catch { }
            });

            resizeObserver.observe(pageObserverRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [pageObserverRef.current, windowDimension]);

    // using the SideMenuContext calculate the initial state
    const [width, setWidth] = useState(desktopView ? windowDimension.width - (isSideMenuMinimized ? 137.6 : 327.6) : windowDimension.width);

    useEffect(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (observedRef.current.offsetWidth !== width) {
                        setWidth(observedRef.current.offsetWidth);
                    }
                } catch { }
            });

            resizeObserver.observe(observedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [observedRef.current, windowDimension]);

    let headerObservedRef = useRef(null);
    const [headerHeight, setHeaderHeight] = useState(68);
    useEffect(() => {
        if (headerObservedRef) {
            if (!headerObservedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (headerObservedRef.current.offsetHeight !== headerHeight) {
                        setHeaderHeight(headerObservedRef.current.offsetHeight);
                    }
                } catch { }
            });

            resizeObserver.observe(headerObservedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [headerObservedRef.current, windowDimension, width, isSideMenuMinimized, editing]);

    async function deleteProduct() {
        setLoading(true);
        setAlertActive(false);

        const accountId = accountData.id.toString();

        try {
            await firebase.useDeleteProduct({
                "accountId": accountId,
                "productId": product.id,
            });

            removeFromShoppingCart(product.id);

            setAlertData({
                type: 'success',
                title: {
                    en: `Product deleted`,
                    es: `Producto eliminado`
                },
                code: '',
                description: {
                    en: `The product has been successfully deleted.`,
                    es: `El producto se han eliminado correctamente.`
                }
            });
            setAlertActive(true);

            const itemsDeleted = JSON.parse(localStorage.getItem('itemsDeleted'));

            if (itemsDeleted) {
                const itemsIdDeleted = [...itemsDeleted];
                itemsIdDeleted.push(product.id);
                localStorage.setItem("itemsDeleted", JSON.stringify(itemsIdDeleted));
            } else {
                localStorage.setItem("itemsDeleted", JSON.stringify([product.id]));
            }

            navigate(-1);
        } catch (error) {
            console.error(error);
            setAlertData({
                type: 'error',
                title: {
                    en: `Error when trying to delete product`,
                    es: `Error al tratar de eliminar el producto`
                },
                code: "error",
                description: getErrorDescription("error")
            });
            setAlertActive(true);
            setLoading(false);
        }
    }

    const onDelete = () => {
        setAlertData({
            type: 'question',
            title: {
                en: `Delete product?`,
                es: `¿Eliminar producto?`
            },
            code: '',
            description: {
                en: `Are you sure you want to delete the product?`,
                es: `¿Estás seguro de que deseas eliminar el producto?`
            }
        });
        setOnAgree(() => deleteProduct);
        setAlertActive(true);
    }

    const onEdict = () => {
        setEditing(true);
    }

    const onMovements = () => {
        navigate('/movimientos-del-producto', { state: {product} });
    }

    const onIndicators = () => {
        navigate('/indicadores-de-desempeno-del-producto', { state: {product} });
    }

    const onAdvanceOptions = () => {
        navigate('/opciones-avanzadas', { state: {product}});
    }

    const onCancel = () => {
        setEditing(false);
        setNewProduct({
            "name": product.name,
            "category": product.category ? product.category : "general",
            "rate": product.rate,
            "description": product.description ? product.description : "",
            "quantity": typeof product.quantity === 'number' ? product.quantity.toString() : "",
            "location": product.location ? product.location.toString() : "",
            "barcode": product.barcode ? product.barcode.toString() : "",
            "priceWithoutTax": formatRationalNumber(product.priceWithoutTax),
            "finalPrice": formatRationalNumber(product.finalPrice),
            "itbisPercentage": product.itbisPercentage.toString(),
            "itbisAmount": formatRationalNumber(product.itbisAmount),
        });

        setNameAlert({
            alert: false,
            enText: '',
            esText: ''
        });

        setQuantityAlert({
            alert: false,
            enText: '',
            esText: ''
        });

        setPriceAlert({
            alert: false,
            enText: '',
            esText: ''
        });

        setDoNotDefineQuantity(product.quantity === null ? true : false)
    }

    async function uploadImage(file) {
        setUploadingPicture(true);

        try {
            const fileName = uid(16);
            const newPath = `accounts/${accountData.id}/products/${product.id}/${fileName}`;

            const imageRef = ref(firebase.storage, newPath);

            await uploadBytes(imageRef, file);

            const productRef = doc(firebase.db, `accounts/${accountData.id}/products`, product.id);

            // Atomically add a new region to the "regions" array field.
            await updateDoc(productRef, {
                picturesLocation: [newPath],
            });

            setProduct({
                ...product,
                picturesLocation: [newPath],
            });

            updateProductInShoppingCart({
                ...product,
                picturesLocation: [newPath],
            });

            await firebase.useDeleteAllProductPicturesButThis({
                businessId: accountData.id,
                productId: product.id,
                path: newPath,
            });

            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'Image uploaded',
                    es: 'Imagen subida',
                }
            });

            setPictureData(null);

            // Wait for the image to be ready for download
            setTimeout(() => {
                getPictureFileAfterUploading(newPath);
            }, 1000);


        } catch (error) {
            console.error(error);
            setUploadingPicture(false);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Connection issues',
                    es: 'Problemas de conexión',
                }
            });
        }
    }

    const onChangeImage = (file) => {
        if (file.type.startsWith('image/')) {
            uploadImage(file);
        } else {
            setAlertData({
                type: 'error',
                title: {
                    en: 'Error trying to open image',
                    es: 'Error al intentar abrir la imagen',
                },
                code: "invalid file",
                description: {
                    en: 'Please select a valid image.',
                    es: 'Por favor, seleccione una imagen válida.'
                }
            });
            setAlertActive(true);
        }
    }

    function calculatePriceWithoutTax(totalWithTax, taxPercentage) {
        // Convert the tax percentage to a decimal
        let taxRate = taxPercentage / 100;
        // Calculate the price without tax
        let priceWithoutTax = totalWithTax / (1 + taxRate);
        return priceWithoutTax;
    }

    const [nameAlert, setNameAlert] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [quantityAlert, setQuantityAlert] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [priceAlert, setPriceAlert] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const onChangeForm = e => {
        const inputName = getInputName(e.target.name);

        if (inputName === "name") {
            setNameAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        }

        if (inputName === "quantity") {
            setQuantityAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        }

        if (inputName === "priceWithoutTax" || inputName === "finalPrice") {
            setPriceAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        }

        if (inputName === "priceWithoutTax") {
            const text = e.target.value;
            let amount = "";
            let isPoint = false;

            for (let i = 0; i < text.length; i++) {
                if (text[i - 3] !== ".") {
                    if (text[i] === ".") {
                        if (!isPoint) {
                            amount += text[i];
                            isPoint = true;
                        }
                    } else {
                        if ((!(isNaN(text[i]))) && (text[i].trim() !== "")) {
                            amount += text[i];
                        }
                    }
                }
            }

            if (!isNaN(amount.trim()) && amount.trim() !== "") {
                let itbisPercentage = 0;

                if (newProduct.itbisPercentage === "16%") {
                    itbisPercentage = 0.16;
                } else {
                    if (newProduct.itbisPercentage === "18%") {
                        itbisPercentage = 0.18;
                    }
                }

                const itbisAmount = Number(amount) * itbisPercentage;

                setNewProduct({
                    ...newProduct,
                    "priceWithoutTax": amount,
                    "finalPrice": formatRationalNumber(Number(amount) + itbisAmount),
                    "itbisAmount": formatRationalNumber(itbisAmount)
                });
            } else {
                setNewProduct({
                    ...newProduct,
                    "priceWithoutTax": "",
                    "finalPrice": "",
                    "itbisAmount": ""
                });
            }
        } else {
            if (inputName === "finalPrice") {
                const text = e.target.value;

                let amount = "";
                let isPoint = false;

                let taxPercentage = 0;

                if (newProduct.itbisPercentage === "16%") {
                    taxPercentage = 16;
                } else {
                    if (newProduct.itbisPercentage === "18%") {
                        taxPercentage = 18;
                    }
                }

                for (let i = 0; i < text.length; i++) {
                    if (text[i - 3] !== ".") {
                        if (text[i] === ".") {
                            if (!isPoint) {
                                amount += text[i];
                                isPoint = true;
                            }
                        } else {
                            if ((!(isNaN(text[i]))) && (text[i].trim() !== "")) {
                                amount += text[i];
                            }
                        }
                    }
                }

                const priceWithoutTax = calculatePriceWithoutTax(Number(amount), taxPercentage);
                const itbisAmount = Number(amount) - priceWithoutTax;

                if (!isNaN(amount.trim()) && amount.trim() !== "") {
                    setNewProduct({
                        ...newProduct,
                        "priceWithoutTax": formatRationalNumber(priceWithoutTax),
                        "finalPrice": amount,
                        "itbisAmount": formatRationalNumber(itbisAmount)
                    });
                } else {
                    setNewProduct({
                        ...newProduct,
                        "priceWithoutTax": "",
                        "finalPrice": "",
                        "itbisAmount": ""
                    });
                }
            } else {
                if (inputName === "quantity") {
                    const text = e.target.value;
                    let amount = "";
                    let isPoint = false;

                    for (let i = 0; i < text.length; i++) {
                        if (text[i - 3] !== ".") {
                            if (text[i] === ".") {
                                if (!isPoint) {
                                    amount += text[i];
                                    isPoint = true;
                                }
                            } else {
                                if ((!(isNaN(text[i]))) && (text[i].trim() !== "")) {
                                    amount += text[i];
                                }
                            }
                        }
                    }
                    
                    setNewProduct({
                        ...newProduct,
                        [inputName]: amount
                    });
                } else {
                    if (inputName === "barcode") {
                        const text = e.target.value;
                        let amount = "";
                        for (let i = 0; i < text.length; i++) {
                            if ((!(isNaN(text[i]))) && (text[i].trim() !== "")) {
                                amount += text[i];
                            }
                        }
                        setNewProduct({
                            ...newProduct,
                            [inputName]: amount
                        });
                    } else {
                            if (inputName === "itbisPercentage") {
                            onClickTaxes(e.target.value);
                        } else {
                            if (inputName === "category") {
                                // To manage the units for categories
                                setNewProduct({
                                    ...newProduct,
                                    [inputName]: e.target.value,
                                    "rate": getUnitsOfMeasurementFor(e.target.value)[0]
                                });
                            } else {
                                setNewProduct({
                                    ...newProduct,
                                    [inputName]: e.target.value
                                });
                            }
                        }
                    }
                }
            }
        }
    }

    const onSave = e => {
        e.preventDefault();

        const productTemp = { ...newProduct };

        if (isDuplicated(newProduct.barcode)) {
            return;
        }

        if (productTemp.name.trim() === "") {
            setNameAlert({
                alert: true,
                enText: 'Required field',
                esText: 'Campo requerido'
            });
            return;
        }

        if ((!doNotDefineQuantity) && productTemp.quantity.trim() === "") {
            setQuantityAlert({
                alert: true,
                enText: 'Required field',
                esText: 'Campo requerido'
            });
            return;
        }

        if (productTemp.priceWithoutTax.trim() === "") {
            setPriceAlert({
                alert: true,
                enText: 'Required field',
                esText: 'Campo requerido'
            });
            return;
        }

        edictProduct({
            ...productTemp,
            "id": product.id
        });
    }

    async function edictProduct(product) {
        setLoading(true);

        try {
            const res = await firebase.useEdictProduct({
                ...product,
                "businessId": accountData.id
            });

            if (res.msg === "Duplicate barcode") {
                setDuplicatedBarcode(res.duplicatedBarcode);

                setAlertData({
                    type: 'error',
                    title: {
                        en: `Error trying to edit the product`,
                        es: `Error al intentar editar el producto`
                    },
                    code: "Duplicate barcodes",
                    description: {
                        en: 'It is not possible to assign a barcode that is already associated with another product.',
                        es: 'No es posible asignar un código de barras que ya esté asociado a otro producto.'
                    }
                });
                setAlertActive(true);
                setLoading(false);
            } else {
                setSoftAlertActive(true);
                setSoftAlertData({
                    type: 'sucess',
                    text: {
                        en: `Product edited`,
                        es: `Producto editado`,
                    }
                });

                navigate(null, { replace: true, state: { ...res } });
                setProduct(res);
                updateProductInShoppingCart(res);
                setEditing(false);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            const { code } = JSON.parse(JSON.stringify(error));
            console.log(error);
            setAlertData({
                type: 'error',
                title: {
                    en: `Error trying to edit the product`,
                    es: `Error al intentar editar el producto`
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
        }
    }

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }

    const onClickTaxes = (itbisPercentageSTR) => {
        const text = newProduct.priceWithoutTax.trim();
        const amount = Number(text.split(',').join(''));

        if (amount) {
            let itbisPercentage = 0;

            if (itbisPercentageSTR === "16%") {
                itbisPercentage = 0.16;
            } else {
                if (itbisPercentageSTR === "18%") {
                    itbisPercentage = 0.18;
                }
            }

            const itbisAmount = Number(amount) * itbisPercentage;

            setNewProduct({
                ...newProduct,
                "priceWithoutTax": formatRationalNumber(text),
                "finalPrice": formatRationalNumber(Number(amount) + itbisAmount),
                "itbisPercentage": itbisPercentageSTR,
                "itbisAmount": formatRationalNumber(itbisAmount)
            });
        } else {
            setNewProduct({
                ...newProduct,
                "priceWithoutTax": "",
                "finalPrice": "",
                "itbisPercentage": itbisPercentageSTR,
                "itbisAmount": ""
            });
        }
    }

    const isDuplicated = (barcode) => {
        if (duplicatedBarcode) {
            if (duplicatedBarcode.trim() === barcode.trim()) {
                return true;
            } else {
                return false;
            }
        } else {
            return false
        }
    }

    const formatAmount = () => {
        if (newProduct) {
            setNewProduct({
                ...newProduct,
                "priceWithoutTax": newProduct.priceWithoutTax === "" ? "" : formatRationalNumber(newProduct.priceWithoutTax),
                "finalPrice": newProduct.finalPrice === "" ? "" : formatRationalNumber(newProduct.finalPrice),
            });
        }
    }

    const getNewBarcode = async () => {
        setLoadingBarcode(true);

        try {
            const res = await firebase.useGetNewBarcode({
                "businessId": accountData.id
            });

            setNewProduct({
                ...newProduct,
                "barcode": res
            });

            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: `Generated barcode.`,
                    es: `Código de barras generado.`,
                }
            });

        } catch (error) {
            console.error(error);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Error when trying to generate barcode.',
                    es: 'Error al intentar generar código de barras.',
                }
            });
        }

        setLoadingBarcode(false);
    }

    const onClickLabel = () => {
        setLabelOptionsActive(true);
    }

    const isBarcode = () => {
        if (product && product.barcode) {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        if (!newProduct) {
            setDisabled(true);
            return;
        }

        const normalize = (value) => (value ? value.toString().trim() : "");

        const noChanges =
            newProduct.name.trim() === product.name.trim() &&
            newProduct.category === product.category &&
            newProduct.rate === product.rate &&
            newProduct.description.trim() === normalize(product.description) &&
            parseFloat(newProduct.priceWithoutTax.replace(/,/g, "")) === product.priceWithoutTax &&
            parseFloat(newProduct.quantity.replace(/,/g, "")) === product.quantity &&
            parseFloat(newProduct.barcode.replace(/,/g, "")) === product.barcode &&
            normalize(newProduct.location) === normalize(product.location) &&
            newProduct.itbisPercentage === product.itbisPercentage;

        setDisabled(noChanges);
    // eslint-disable-next-line
    }, [newProduct]);

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? "Product details" : 'Detalles del producto'} </title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && product && accessTo ? <Fragment>
                    {accountData.active ?
                        <div ref={pageObserverRef} className="page-app-settings-item">
                            {accessTo.inventory ? <Fragment>
                                <PictureProductViewer
                                    productName={product.name}
                                    viewerWidth={width ? pageWidth : "300px"}
                                    pictureProductViewerActive={pictureProductViewerActive}
                                    setPictureProductViewerActive={setPictureProductViewerActive}
                                    pictureData={pictureData}
                                    setProduct={setProduct}
                                    setLoadingPicture={setLoadingPicture}
                                    businessId={accountData.id}
                                    productId={product.id}
                                    path={product.picturesLocation ? product.picturesLocation[0] : null}
                                    setPictureData={setPictureData}
                                />

                                <LabelOptions
                                    pickerWidth={pageWidth}
                                    product={product}
                                />

                                {desktopView ?
                                    <ToolTitle
                                        icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                        text={appLanguage.en ? "Product details" : 'Detalles del producto'}
                                    />
                                    : null}
                                {loading ? null :
                                    <div 
                                        ref={headerObservedRef} 
                                        className="product-details-page-botttons-section-main-cont" 
                                        style={{
                                            justifyContent: width > 517 ? "center" : "flex-start",
                                            overflowX: editing ? "hidden" : "auto",
                                            colorScheme: appTheme.dark ? "dark" : "light"
                                        }}
                                    >
                                        <div
                                            className="product-details-page-botttons-section"
                                            style={{ 
                                                minWidth: editing ? "" : "490px",
                                                justifyContent: editing ? "flex-end" : "flex-start" 
                                            }}
                                        >
                                            {editing ? <Fragment>
                                                <button
                                                    onClick={onCancel}
                                                    style={{ margin: "10px 40px 10px 0px"}}
                                                    className="new-customer-bottons-button-save"
                                                >
                                                    <img src={crossWhiteIcon} alt="Cancel" />
                                                    {appLanguage.en ? <p>Cancel</p> : <p>Cancelar</p>}
                                                </button>
                                                <button
                                                    onClick={onSave}
                                                    disabled={disabled}
                                                    className="new-customer-bottons-button-save"
                                                    style={disabled ? {
                                                        backgroundColor: "#3f526698",
                                                        color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                                        cursor: "not-allowed",
                                                        margin: "10px 22px 10px 0px"
                                                    } : {
                                                        margin: "10px 22px 10px 0px"
                                                    }}
                                                >
                                                    <img src={saveIcon} alt="Save" />
                                                    {appLanguage.en ? <p>Save</p> : <p>Guardar</p>}
                                                </button>
                                            </Fragment> : <Fragment>
                                                <button
                                                    onClick={onClickLabel}
                                                    disabled={!(isBarcode())}
                                                    className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                    style={isBarcode() ? {
                                                        margin: "10px 22px 10px 0px"
                                                    } : {
                                                        backgroundColor: "#3f526698",
                                                        color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                                        cursor: "not-allowed",
                                                        margin: "10px 22px 10px 0px"
                                                    }}
                                                >
                                                    <img src={labelIconDark} alt="Label" />
                                                    {appLanguage.en ? <p>Label</p> : <p>Etiqueta</p>}
                                                </button>
                                                <button
                                                    onClick={e => onMovements(e)}
                                                    className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                    style={{ margin: "10px 22px 10px 0px"}}
                                                >
                                                    <img src={reloadIcon} alt="Movements" />
                                                    {appLanguage.en ? <p>Movements</p> : <p>Movimientos</p>}
                                                </button>
                                                <button
                                                    onClick={e => onIndicators(e)}
                                                    className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                    style={{ margin: "10px 22px 10px 0px"}}
                                                >
                                                    <img src={indicatorsIconDark} alt="Indicators" />
                                                    {appLanguage.en ? <p>Indicators</p> : <p>Indicadores</p>}
                                                </button>
                                                <button
                                                    onClick={e => onEdict(e)}
                                                    className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                    style={{ margin: "10px 22px 10px 0px"}}
                                                >
                                                    <img  src={editIcon} alt="Edit"/>
                                                    {appLanguage.en ? <p>Edit</p> : <p>Editar</p>}
                                                </button>
                                                <button
                                                    onClick={e => onDelete(e)}
                                                    className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                    style={{ margin: "10px 22px 10px 0px"}}
                                                >
                                                    <img src={deleteIcon} alt="Delete" />
                                                    {appLanguage.en ? <p>Delete</p> : <p>Eliminar</p>}
                                                </button>
                                                <button
                                                    onClick={e => onAdvanceOptions(e)}
                                                    className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                    style={{ margin: "10px 22px 10px 0px"}}
                                                >
                                                    <img  src={settingsIconDark} alt="Advanced options"/>
                                                    {appLanguage.en ? <p>Advanced options</p> : <p>Opciones avanzadas</p>}
                                                </button>
                                            </Fragment>}
                                        </div>
                                    </div>
                                }

                                <div
                                    style={{
                                        height: desktopView ? `calc(100vh - 107px - ${headerHeight}px)` : `calc(100vh - 65px - ${headerHeight}px)`,
                                        colorScheme: appTheme.dark ? "dark" : "light"
                                    }}
                                    className="customer-details-page-main-container"
                                >
                                    {loading ? <div style={{ marginTop: "90px" }} className="more-details-spinner-container"><Spinner /></div> : <Fragment>
                                        <div ref={observedRef} className="product-details-page-container">
                                            <div className="product-details-page-info-section" style={{ flexWrap: width < 850 ? "wrap" : "nowrap" }}>
                                                <div 
                                                    style={{ 
                                                        minWidth: "145px", 
                                                        width: width < 500 ? "100%" : "", 
                                                        justifyContent: "center", 
                                                        alignItems: "center",
                                                        display: "flex", 
                                                        flexDirection:"column" 
                                                    }}
                                                >

                                                    {(loadingPicture || uploadingPicture) ?
                                                        <div style={{ height: width < 500 ? "200px" : "145px" }} className="products-inventory-product-yes-img-big-cont unselectable">
                                                            <div
                                                                style={{
                                                                    backgroundColor: "rgba(128, 128, 128, 0.13)",
                                                                    borderRadius: "5px",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    width: width < 500 ? "200px" : "145px",
                                                                    height: width < 500 ? "200px" : "145px",
                                                                    cursor: editing ? "not-allowed" : "pointer"
                                                                }}
                                                                className="pro-inv-prod-yes-img-big-con-div"
                                                            >
                                                                <Spinner3 />
                                                            </div>
                                                        </div>
                                                        :
                                                        <Fragment>
                                                            {noImgFile && (!loadingError) ?
                                                                <div style={{ height: width < 500 ? "200px" : "145px" }} className="products-inventory-product-yes-img-big-cont unselectable">
                                                                    <div style={{ width: width < 500 ? "200px" : "145px", height: width < 500 ? "200px" : "145px", cursor: editing ? "not-allowed" : "pointer" }} onClick={editing ? null : onClickPicture} className="pro-inv-prod-yes-img-big-con-div">
                                                                        <img className={`products-inventory-product-yes-img-big ${appTheme.dark ? "border-box-dark" : "border-box-light"}`} src={pictureData ? (pictureProductViewerActive ? noReady : pictureData.pictureFile) : noReady} alt="Product" />
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div style={{ height: width < 500 ? "200px" : "145px" }} className="products-inventory-product-no-img-big-cont unselectable">
                                                                    <div style={{ width: width < 500 ? "200px" : "145px", height: width < 500 ? "200px" : "145px", }} className="pro-inv-prod-no-img-big-con-div">
                                                                        <img className="products-inventory-product-no-img-big" src={loadingError ? error404 : noPicture} alt="Product" />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </Fragment>
                                                    }

                                                    {editing ? null :
                                                        <label
                                                            htmlFor="inputTag"
                                                            style={{ 
                                                                color: appTheme.dark ? "white" : "black", 
                                                                marginBottom: "2px",
                                                                marginTop: "5px",
                                                                width: width < 500 ? "201px" : "146px",
                                                                borderRadius: "10px"
                                                            }}
                                                            className={`${isTouchEnabled() ? "product-picture-upload-butt-no-hover" : "product-picture-upload-butt"} unselectable`}
                                                        >
                                                            <p>{appLanguage.en ? (width < 500 ? "Upload image" : "Upload") : (width < 500 ? "Subir imagen" : "Subir")}</p>

                                                            <img src={appTheme.dark ? uploadIconDark : uploadIconLight} alt="Receipt" />
                                                            <input
                                                                id="inputTag"
                                                                type="file"
                                                                accept="image/*"
                                                                value=""
                                                                onChange={(event) => onChangeImage(event.target.files[0])}
                                                            />
                                                        </label>
                                                    }

                                                </div>

                                                <div style={{ width: width < 500 ? "100%" : "calc(100% - 178px)", maxWidth: width < 850 ? width < 500 ? "100%" : "calc(100% - 178px)" : "330px", marginLeft: width < 500 ? "" : "30px", marginRight: width < 850 ? "" : "30px" }}>
                                                    <div ref={nameAlert.alert ? fieldAlertReference : null} style={{ display: "flex", flexDirection: "column" }}>
                                                        <p className="new-customer-label"><b>{appLanguage.en ? "Name: " : "Nombre: "}</b> {editing ? "*" : ""} {nameAlert.alert ? (appLanguage.en ? nameAlert.enText : nameAlert.esText) : ""}</p>
                                                        <label className="new-customer-helper-label unselectable" htmlFor={inputsNames.name}>{inputsNames.name}</label>
                                                        <input
                                                            name={inputsNames.name}
                                                            id={inputsNames.name}
                                                            value={newProduct ? newProduct.name : ""}
                                                            onChange={(e) => onChangeForm(e)}
                                                            onKeyDown={e => handleKeyPress(e)}
                                                            autoComplete="off"
                                                            autoCorrect="off"
                                                            maxLength={40}
                                                            placeholder={appLanguage.en ? "Enter the name..." : "Introduzca el nombre..."}
                                                            readOnly={editing ? false : true}
                                                            style={editing ? {
                                                                colorScheme: appTheme.dark ? "dark" : "light",
                                                            } : {
                                                                colorScheme: appTheme.dark ? "dark" : "light",
                                                                border: "none",
                                                                outline: "none",
                                                                boxShadow: "none"
                                                            }}
                                                            className={`new-customer-input ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"} ${nameAlert.alert ? 'new-customer-input-alert' : (appTheme.dark ? "new-customer-input-dark" : "new-customer-input-light")}`}
                                                        />
                                                    </div>
                                                    <div className="add-product-service-first-opt-cont">
                                                        {editing ?
                                                            <div className="add-product-service-first-opt-item" style={{ marginRight: "8px" }}>
                                                                <p style={{ whiteSpace: "nowrap" }} className="new-customer-label"><b>{appLanguage.en ? "Category: " : "Categoría: "}</b></p>
                                                                <label className="new-customer-helper-label unselectable" htmlFor={uid(8)} >{uid(8)}</label>
                                                                <select
                                                                    readOnly={editing ? false : true}
                                                                    style={editing ? {
                                                                        colorScheme: appTheme.dark ? "dark" : "light",
                                                                        width: "100%",
                                                                    } : {
                                                                        colorScheme: appTheme.dark ? "dark" : "light",
                                                                        width: "100%",
                                                                        border: "none",
                                                                        outline: "none",
                                                                        boxShadow: "none"
                                                                    }}
                                                                    className={`new-customer-input ${appTheme.dark ? "dar-sec-bg new-customer-input-dark" : "lig-sec-bg new-customer-input-light"}`}
                                                                    name="category"
                                                                    value={newProduct ? newProduct.category : "general"}
                                                                    onChange={(e) => onChangeForm(e)}
                                                                    onKeyDown={e => handleKeyPress(e)}
                                                                >
                                                                    {getThisBusinessProductsCategories(accountData.businessCategory).map((category, index) => {
                                                                        return ( 
                                                                            <option key={index} value={category}>{getCategory(category)}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                        :
                                                            <div className="add-product-service-first-opt-item" style={{ marginRight: "8px", }}>
                                                                <p className="new-customer-label"><b>{appLanguage.en ? "Category: " : "Categoría: "}</b></p>
                                                                <input
                                                                    name="category"
                                                                    id="category"
                                                                    value={getCategory(newProduct ? newProduct.category : "general")}
                                                                    maxLength={10}
                                                                    autoComplete="off"
                                                                    autoCorrect="off"
                                                                    readOnly
                                                                    style={{
                                                                        colorScheme: appTheme.dark ? "dark" : "light",
                                                                        border: "none",
                                                                        outline: "none",
                                                                        boxShadow: "none"
                                                                    }}
                                                                    className={`new-customer-input ${appTheme.dark ? "dar-sec-bg new-customer-input-dark" : "lig-sec-bg new-customer-input-light"}`}
                                                                />
                                                            </div>
                                                        }
                                                        {editing ?
                                                            (newProduct && getUnitsOfMeasurementFor(newProduct.category).length > 1 ?
                                                                <div className="add-product-service-first-opt-item" style={{ marginLeft: "8px" }}>
                                                                    <p style={{ whiteSpace: "nowrap" }} className="new-customer-label"><b>{appLanguage.en ? "Rate: " : "Tarifa: "}</b></p>
                                                                    <label className="new-customer-helper-label unselectable" htmlFor={uid(8)} >{uid(8)}</label>
                                                                    <select
                                                                        readOnly={editing ? false : true}
                                                                        style={editing ? {
                                                                            colorScheme: appTheme.dark ? "dark" : "light",
                                                                            width: "100%",
                                                                        } : {
                                                                            colorScheme: appTheme.dark ? "dark" : "light",
                                                                            width: "100%",
                                                                            border: "none",
                                                                            outline: "none",
                                                                            boxShadow: "none"
                                                                        }}
                                                                        className={`new-customer-input ${appTheme.dark ? "dar-sec-bg new-customer-input-dark" : "lig-sec-bg new-customer-input-light"}`}
                                                                        name="rate"
                                                                        value={newProduct.rate}
                                                                        onChange={(e) => onChangeForm(e)}
                                                                        onKeyDown={e => handleKeyPress(e)}
                                                                    >
                                                                        {getUnitsOfMeasurementFor(newProduct.category).map((unit, index) => {
                                                                            return ( 
                                                                                <option key={index} value={unit}>{getRatePer(unit)}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                </div>
                                                                :
                                                                <div className="add-product-service-first-opt-item" style={{ marginLeft: "8px", }}>
                                                                    <p className="new-customer-label"><b>{appLanguage.en ? "Rate: " : "Tarifa: "}</b></p>
                                                                    <input
                                                                        name="rate"
                                                                        id="rate"
                                                                        value={getRatePer(getUnitsOfMeasurementFor(newProduct.category)[0])}
                                                                        maxLength={10}
                                                                        autoComplete="off"
                                                                        autoCorrect="off"
                                                                        readOnly
                                                                        style={{
                                                                            colorScheme: appTheme.dark ? "dark" : "light",
                                                                            border: "none",
                                                                            outline: "none",
                                                                            boxShadow: "none"
                                                                        }}
                                                                        className={`new-customer-input ${appTheme.dark ? "dar-sec-bg new-customer-input-dark" : "lig-sec-bg new-customer-input-light"}`}
                                                                    />
                                                                </div>
                                                            )
                                                            :
                                                            <div className="add-product-service-first-opt-item" style={{ marginLeft: "8px", }}>
                                                                <p className="new-customer-label"><b>{appLanguage.en ? "Rate: " : "Tarifa: "}</b></p>
                                                                <input
                                                                    name="rate"
                                                                    id="rate"
                                                                    value={getRatePer(newProduct ? newProduct.rate : "perUnit")}
                                                                    maxLength={10}
                                                                    autoComplete="off"
                                                                    autoCorrect="off"
                                                                    readOnly
                                                                    style={{
                                                                        colorScheme: appTheme.dark ? "dark" : "light",
                                                                        border: "none",
                                                                        outline: "none",
                                                                        boxShadow: "none"
                                                                    }}
                                                                    className={`new-customer-input ${appTheme.dark ? "dar-sec-bg new-customer-input-dark" : "lig-sec-bg new-customer-input-light"}`}
                                                                />
                                                            </div>
                                                        }


                                                    </div>
                                                </div>

                                                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                                    <p style={{ whiteSpace: "nowrap" }} className="new-customer-label"><b>{appLanguage.en ? "Description:" : "Descripción:"}</b></p>
                                                    <label className="new-customer-helper-label unselectable" htmlFor={inputsNames.description}>{inputsNames.description}</label>
                                                    <textarea
                                                        autoComplete="off"
                                                        autoCorrect="off"
                                                        placeholder={appLanguage.en ? "You can leave an overview here..." : "Puedes dejar una descripción general aquí..."}
                                                        readOnly={editing ? false : true}
                                                        style={editing ? {
                                                            colorScheme: appTheme.dark ? "dark" : "light",
                                                            marginBottom: 0,
                                                            marginTop: "5px",
                                                            height: "100%",
                                                            minHeight: "100px",
                                                            maxHeight: "110px",
                                                        } : {
                                                            colorScheme: appTheme.dark ? "dark" : "light",
                                                            marginBottom: 0,
                                                            marginTop: "5px",
                                                            border: "none",
                                                            outline: "none",
                                                            height: "100%",
                                                            minHeight: "100px",
                                                            maxHeight: "110px",
                                                            boxShadow: "none"
                                                        }}
                                                        className={`new-custmer-textarea-address ${appTheme.dark ? "new-custmer-textarea-dark dar-sec-bg" : "new-custmer-textarea-light lig-sec-bg"}`}
                                                        id={inputsNames.description}
                                                        name={inputsNames.description}
                                                        value={newProduct ? (newProduct.description.trim() === "" ? (editing ? "" : "--") : newProduct.description) : (editing ? "" : "--")}
                                                        maxLength={340}
                                                        onChange={(e) => onChangeForm(e)}
                                                        onKeyDown={e => handleKeyPress(e)}
                                                    />
                                                </div>
                                            </div>

                                            {editing ?
                                                <div className={`${isTouchEnabled() ? "labelDoNotDefQuaContainer-no-hover" : "labelDoNotDefQuaContainer"} unselectable`} onClick={doNotDefineQuantityHandler}>
                                                    <p>
                                                        <input
                                                            style={{colorScheme: appTheme.dark ? "dark" : "light"}}
                                                            className='settings-auth-user-access-input'
                                                            type="checkbox"
                                                            checked={doNotDefineQuantity}
                                                            readOnly
                                                        />
                                                        <label style={{ paddingLeft: "10px", cursor: "pointer" }}>
                                                            <b>{appLanguage.en ? 'Allow selling without defining quantity.' : 'Permitir vender sin definir cantidad.'}</b>
                                                        </label>
                                                    </p>
                                                </div>
                                            : null}

                                            <div className="product-details-page-inventory-section" style={{ flexWrap: width < 650 ? "wrap" : "nowrap" }}>
                                                <div ref={quantityAlert.alert ? fieldAlertReference : null} className="add-product-service-first-opt-item" style={{ marginRight: "8px", width: width < 650 ? (width < 500 ? "calc(40% - 8px)" : "calc(50% - 8px)") : "33%" }}>
                                                    <p style={{ whiteSpace: "nowrap" }} className="new-customer-label"><b> {appLanguage.en ? "Quantity" : "Cantidad"}: </b> {(editing && (!doNotDefineQuantity) )? "*" : ""}  {((quantityAlert.alert) && (width >= 500)) ? (appLanguage.en ? quantityAlert.enText : quantityAlert.esText) : ""}</p>
                                                    <label className="new-customer-helper-label unselectable" htmlFor={inputsNames.quantity}>{inputsNames.quantity}</label>
                                                    <input
                                                        name={inputsNames.quantity}
                                                        id={inputsNames.quantity}
                                                        inputMode="numeric"
                                                        value={
                                                            doNotDefineQuantity ?
                                                                appLanguage.en ? "Not defined" : "Sin definir"
                                                            :
                                                                newProduct ?
                                                                    newProduct.quantity.trim() === "" ?
                                                                        (editing ? "" : "--") : newProduct.quantity
                                                                : 
                                                                    (editing ? "" : "--")
                                                        }
                                                        onChange={(e) => onChangeForm(e)}
                                                        onKeyDown={e => handleKeyPress(e)}
                                                        maxLength={10}
                                                        autoComplete="off"
                                                        autoCorrect="off"
                                                        placeholder={appLanguage.en ? "Enter quantity..." : "Introduzca la cantidad..."}
                                                        readOnly={(editing && (!doNotDefineQuantity)) ? false : true}
                                                        style={(editing && (!doNotDefineQuantity)) ? {
                                                            colorScheme: appTheme.dark ? "dark" : "light",
                                                        } : {
                                                            colorScheme: appTheme.dark ? "dark" : "light",
                                                            border: "none",
                                                            outline: "none",
                                                            boxShadow: "none"
                                                        }}
                                                        className={`new-customer-input ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"} ${quantityAlert.alert ? 'new-customer-input-alert' : (appTheme.dark ? "new-customer-input-dark" : "new-customer-input-light")}`}
                                                    />
                                                </div>
                                                <div className="add-product-service-first-opt-item" style={{ marginLeft: "8px", marginRight: width < 650 ? "" : "8px", width: width < 650 ? (width < 500 ? "calc(60% - 8px)" : "calc(50% - 8px)") : "33%" }}>
                                                    <p style={{ whiteSpace: "nowrap" }} className="new-customer-label"><b>{appLanguage.en ? "Location" : "Ubicación"}:</b></p>
                                                    <label className="new-customer-helper-label unselectable" htmlFor={inputsNames.location}>{inputsNames.location}</label>
                                                    <input
                                                        name={inputsNames.location}
                                                        id={inputsNames.location}
                                                        value={newProduct ? (newProduct.location.trim() === "" ? (editing ? "" : "--") : newProduct.location) : (editing ? "" : "--")}
                                                        onChange={(e) => onChangeForm(e)}
                                                        onKeyDown={e => handleKeyPress(e)}
                                                        maxLength={16}
                                                        autoComplete="off"
                                                        autoCorrect="off"
                                                        placeholder={appLanguage.en ? "Enter the location..." : "Introduzca la ubicación..."}
                                                        readOnly={editing ? false : true}
                                                        style={editing ? {
                                                            colorScheme: appTheme.dark ? "dark" : "light",
                                                        } : {
                                                            colorScheme: appTheme.dark ? "dark" : "light",
                                                            border: "none",
                                                            outline: "none",
                                                            boxShadow: "none"
                                                        }}
                                                        className={`new-customer-input ${appTheme.dark ? "dar-sec-bg new-customer-input-dark" : "lig-sec-bg new-customer-input-light"}`}
                                                    />
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "column", marginLeft: width < 650 ? "" : "8px", width: width < 650 ? "100%" : "40%" }}>
                                                    <p style={{ whiteSpace: "nowrap" }} className="new-customer-label"><b>{appLanguage.en ? "Barcode: " : "Código de barra: "}</b>{isDuplicated(newProduct.barcode) ? (
                                                        appLanguage.en ? " *** Duplicate ***" : " *** Duplicado *** "
                                                    ) : ""}</p>
                                                    <label className="new-customer-helper-label unselectable" htmlFor={inputsNames.barcode}>{inputsNames.barcode}</label>
                                                    
                                                    <div style={{display: "flex", width: "100%"}}>
                                                        <input
                                                            name={inputsNames.barcode}
                                                            id={inputsNames.barcode}
                                                            value={newProduct ? (newProduct.barcode.trim() === "" ? (editing ? "" : "--") : newProduct.barcode) : (editing ? "" : "--")}
                                                            inputMode="numeric"
                                                            onChange={(e) => onChangeForm(e)}
                                                            onKeyDown={e => handleKeyPress(e)}
                                                            autoComplete="off"
                                                            autoCorrect="off"
                                                            maxLength={16}
                                                            placeholder={appLanguage.en ? "Enter the barcode..." : "Introduzca el código de barra..."}
                                                            readOnly={(editing && (!loadingBarcode)) ? false : true}
                                                            style={editing ? {
                                                                colorScheme: appTheme.dark ? "dark" : "light",
                                                                borderTopRightRadius: newProduct.barcode.trim() === "" ? "0px" : "10px",
                                                                borderBottomRightRadius: newProduct.barcode.trim() === "" ? "0px" : "10px",
                                                                border: loadingBarcode ? "none" : "",
                                                                outline: loadingBarcode ? "none" : "",
                                                                boxShadow: loadingBarcode ? "none" : ""
                                                            } : {
                                                                colorScheme: appTheme.dark ? "dark" : "light",
                                                                border: "none",
                                                                outline: "none",
                                                                boxShadow: "none"
                                                            }}
                                                            className={`new-customer-input ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"} ${isDuplicated(newProduct.barcode) ? 'new-customer-input-alert' : appTheme.dark ? "new-customer-input-dark" : "new-customer-input-light"}`}
                                                        />
                                                        {(editing && newProduct.barcode.trim() === "") ?
                                                            <button
                                                                onClick={getNewBarcode}
                                                                disabled={loadingBarcode}
                                                                style={{ 
                                                                    color: appTheme.dark ? "white" : "black",
                                                                    marginTop: "5px",
                                                                    width: "100px",
                                                                    minWidth: "100px",
                                                                    maxWidth: "100px",
                                                                    borderTopLeftRadius: "0px",
                                                                    borderBottomLeftRadius: "0px",
                                                                    borderTopRightRadius: "10px",
                                                                    borderBottomRightRadius: "10px"
                                                                }}
                                                                className={`${isTouchEnabled() ? "product-picture-upload-butt-no-hover" : "product-picture-upload-butt"} unselectable`}
                                                            >
                                                                {loadingBarcode ? <Spinner2 /> : (appLanguage.en ? "Generate" : "Generar")}
                                                            </button> 
                                                        : null}
                                                    </div>
                                                    
                                                </div>

                                            </div>

                                            <div className="product-details-page-billing-section" style={{ flexWrap: "wrap" }}>
                                                <div ref={priceAlert.alert ? fieldAlertReference : null} className="add-product-service-first-opt-item" style={{ marginRight: width < 500 ? "" : "8px", width: width < 650 ? (width < 500 ? "100%" : "calc(50% - 8px)") : "calc(25% - 8px)" }}>
                                                    <p style={{ whiteSpace: "nowrap" }} className="new-customer-label"><b>{appLanguage.en ? "Price without ITBIS" : "Precio sin ITBIS"}: *</b></p>
                                                    <label className="new-customer-helper-label unselectable" htmlFor={inputsNames.priceWithoutTax}>{inputsNames.priceWithoutTax}</label>
                                                    <input
                                                        name={inputsNames.priceWithoutTax}
                                                        id={inputsNames.priceWithoutTax}
                                                        inputMode="numeric"
                                                        onBlur={formatAmount}
                                                        value={editing ? newProduct.priceWithoutTax : `RD$ ${newProduct.priceWithoutTax}`}
                                                        onChange={(e) => onChangeForm(e)}
                                                        onKeyDown={e => handleKeyPress(e)}
                                                        maxLength={10}
                                                        autoComplete="off"
                                                        autoCorrect="off"
                                                        placeholder="RD$ 0.00"
                                                        readOnly={editing ? false : true}
                                                        style={editing ? {
                                                            colorScheme: appTheme.dark ? "dark" : "light",
                                                        } : {
                                                            colorScheme: appTheme.dark ? "dark" : "light",
                                                            border: "none",
                                                            outline: "none",
                                                            boxShadow: "none"
                                                        }}
                                                        className={`new-customer-input ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"} ${priceAlert.alert ? 'new-customer-input-alert' : (appTheme.dark ? "new-customer-input-dark" : "new-customer-input-light")}`}
                                                    />
                                                </div>

                                                {editing ?
                                                    <div className="add-product-service-first-opt-item" style={{ marginRight: width < 650 ? "" : "8px", marginLeft: width < 500 ? "" : "8px", width: width < 650 ? (width < 500 ? "100%" : "calc(50% - 8px)") : "calc(25% - 16px)" }}>
                                                        <p style={{ whiteSpace: "nowrap" }} className="new-customer-label"><b>% ITBIS:</b></p>
                                                        <label className="new-customer-helper-label unselectable" htmlFor="itbisPercentage">itbisPercentage</label>
                                                        <select
                                                            style={{
                                                                colorScheme: appTheme.dark ? "dark" : "light",
                                                                width: "100%",
                                                            }}
                                                            className={`new-customer-input ${appTheme.dark ? "dar-sec-bg new-customer-input-dark" : "lig-sec-bg new-customer-input-light"}`}
                                                            name="itbisPercentage"
                                                            value={newProduct.itbisPercentage}
                                                            onChange={(e) => onChangeForm(e)}
                                                            onKeyDown={e => handleKeyPress(e)}
                                                        >
                                                            <option value="0%" >0 %</option>
                                                            <option value="16%">16 %</option>
                                                            <option value="18%">18 %</option>
                                                        </select>
                                                    </div>
                                                    : null}

                                                <div className="add-product-service-first-opt-item" style={{ marginRight: editing ? (width < 500 ? "" : "8px") : (width < 500 ? "" : (width < 650 ? "" : "8px")), marginLeft: editing ? (width < 650 ? "" : "8px") : (width < 500 ? "" : "8px"), width: width < 650 ? (width < 500 ? "100%" : "calc(50% - 8px)") : "calc(25% - 16px)" }}>
                                                    <p style={{ whiteSpace: "nowrap" }} className="new-customer-label"><b>ITBIS {editing ? "RD$" : ` (${newProduct.itbisPercentage})`}:</b></p>
                                                    <label className="new-customer-helper-label unselectable" htmlFor="itbisAmount">itbisAmount</label>
                                                    <input
                                                        name={"itbisAmount"}
                                                        id={"itbisAmount"}
                                                        inputMode="numeric"
                                                        onBlur={formatAmount}
                                                        readOnly
                                                        value={editing ? newProduct.itbisAmount : `RD$ ${newProduct.itbisAmount}`}
                                                        onChange={(e) => onChangeForm(e)}
                                                        maxLength={10}
                                                        autoComplete="off"
                                                        autoCorrect="off"
                                                        placeholder="RD$ 0.00"
                                                        style={{
                                                            colorScheme: appTheme.dark ? "dark" : "light",
                                                            border: "none",
                                                            outline: "none",
                                                            boxShadow: "none"
                                                        }}
                                                        className={`new-customer-input ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"} ${priceAlert.alert ? 'new-customer-input-alert' : (appTheme.dark ? "new-customer-input-dark" : "new-customer-input-light")}`}
                                                    />
                                                </div>

                                                <div className="add-product-service-first-opt-item" style={{ marginLeft: editing ? width < 500 ? "" : "8px" : (width < 650 ? "" : "8px"), width: width < 650 ? (width < 500 ? "100%" : "calc(50% - 8px)") : "calc(25% - 8px)" }}>
                                                    <p style={{ whiteSpace: "nowrap" }} className="new-customer-label"><b>{appLanguage.en ? "Final price" : "Precio final"}: </b></p>
                                                    <label className="new-customer-helper-label unselectable" htmlFor={inputsNames.finalPrice} >{inputsNames.finalPrice}</label>
                                                    <input
                                                        name={inputsNames.finalPrice}
                                                        id={inputsNames.finalPrice}
                                                        inputMode="numeric"
                                                        onBlur={formatAmount}
                                                        value={editing ? newProduct.finalPrice : `RD$ ${newProduct.finalPrice}`}
                                                        onChange={(e) => onChangeForm(e)}
                                                        onKeyDown={e => handleKeyPress(e)}
                                                        maxLength={10}
                                                        autoComplete="off"
                                                        autoCorrect="off"
                                                        placeholder="RD$ 0.00"
                                                        readOnly={editing ? false : true}
                                                        style={editing ? {
                                                            colorScheme: appTheme.dark ? "dark" : "light",
                                                        } : {
                                                            colorScheme: appTheme.dark ? "dark" : "light",
                                                            border: "none",
                                                            outline: "none",
                                                            boxShadow: "none"
                                                        }}
                                                        className={`new-customer-input ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"} ${priceAlert.alert ? 'new-customer-input-alert' : (appTheme.dark ? "new-customer-input-dark" : "new-customer-input-light")}`}
                                                    />
                                                </div>
                                            </div>
                                            <div style={{ minHeight: "50px" }} />
                                        </div>
                                    </Fragment>}
                                </div>
                            </Fragment> : null}
                        </div>
                        :
                        <div className="page-app-customers-item">
                            <div className="customers-options-container">
                                <div className="customers-options-container-item">
                                    <SettingsActionRequired />
                                </div>
                            </div>
                        </div>
                    }
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

export default ProductDetailsPage;