import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const ShareViaExternalAppContext = createContext();

const ShareViaExternalAppProvider = (props) => {

    const location = useLocation();

    const [ shareViaExternalAppActive, setShareViaExternalAppActive ] = useState(false);

    const [ contactText, setContactText ] = useState("");
    const [ contactType, setContactType ] = useState(null);

    const [ contactTextAlert, setContactTextAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const resetState = () => {
        setContactText("");
        setContactType(null);
        setContactTextAlert({
            alert: false,
            enText: '',
            esText: ''
        });
    }

    useEffect(() => {
        if (!shareViaExternalAppActive) {
            resetState();
        }
    }, [shareViaExternalAppActive]);

    useEffect(() => {
        if (shareViaExternalAppActive) {
            if (location.pathname !== "/ver-detalles-de-venta" || location.pathname !== "/venta-exitosa") {
                setShareViaExternalAppActive(false);
                resetState();
            }
        }
    // eslint-disable-next-line
    }, [location])

    return (
        <ShareViaExternalAppContext.Provider
            value={{ 
                shareViaExternalAppActive,
                contactText,
                contactType,
                contactTextAlert,
                setShareViaExternalAppActive,
                setContactText,
                setContactType,
                setContactTextAlert,
            }}
        >
            {props.children}
        </ShareViaExternalAppContext.Provider>
    )
}

export default ShareViaExternalAppProvider;