import { Fragment, useContext, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { AppContext } from '../../../context/AppContext';
import { SideMenuContext } from '../../../context/SideMenuContext';

import firebase from '../../../firebase/firebase';
import { doc, getDoc } from "firebase/firestore";

import ConnectionError from '../../../components/connection-error/connectionError';

import SettingsHeader2 from '../../../components/settings-header-2/settingsHeader2';

import SettingsTitle from '../../../components/settings-title/settingsTitle';
import pageIcon from '../../../img/logo192.png';

import settingIconDark from '../../../icons/settings-dark.png';
import settingIconLight from '../../../icons/settings-light.png';

import Spinner from '../../../components/spinner/spinner';

import './termsAndCond.page.scss';
import '../settings.page.scss';

const TermsAndCondPage = () => {

    const { appLanguage, appTheme } = useContext(AppContext);
    const { desktopView } = useContext(SideMenuContext);

    const [ loading, setLoading ] = useState(true);

    const [ showConnectionError, setShowConnectionError ] = useState(true);

    const [ termsAndCondictions, setTermsAndCondictions] = useState(null);

    const tryAgain = () => {
        setLoading(true);
        getTermsAndCondictons();
    }

    async function getTermsAndCondictons() {
        setShowConnectionError(false);

        try {
            const docRef = doc(firebase.db, "publicDocuments", "terms-and-conditions");
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setTermsAndCondictions(docSnap.data());
            } else {
                const err = new Error("Document no found");
                throw err;
            }

            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
            setShowConnectionError(true);
        }
    }

    useEffect(() => {
        getTermsAndCondictons();
    }, [])

    return (
        <Fragment>
            <Helmet  htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Terms and conditions' : 'Términos y condiciones' } </title>
            </Helmet>
            <div 
                className={`page-app-settings-container ${appTheme.dark ? 'dark-text' : 'light-text'}`}
                style={{ 
                    colorScheme: appTheme.dark ?  "dark" : "light"
                }}
            >
                <div className="page-app-settings-item">
                    {desktopView ? 
                        <SettingsHeader2 
                            icon={appTheme.dark ? settingIconDark : settingIconLight}
                            text={appLanguage.en ? "More options" : "Más opciones"}
                            textLink={"/ajustes"}
                            text2={appLanguage.en ? "Terms and conditions" : "Términos y condiciones"}
                        />
                    : null}
                    <div className="settings-options-container">
                        <div className="settings-options-container-item">
                        
                            <SettingsTitle
                                icon={pageIcon}
                                firstText={"HazCuentas v1.2"}
                                secondText={"República Dominicana 2024"}
                            />

                            {loading ? <Spinner/> : <Fragment>
                                {showConnectionError ? <ConnectionError onTryAgain={tryAgain} /> : <Fragment>
                                    {termsAndCondictions ?
                                        <TermsAndCondText terms={
                                            appLanguage.en ? termsAndCondictions.english : termsAndCondictions.spanish 
                                        }/>
                                    : null}
                                </Fragment>}
                            </Fragment>}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const TermsAndCondText = ({terms}) => {
    return (
        <div className='terms-and-cond-container'> 
            <br/>
            <h3>{terms.title}</h3>
            <br/>
            <p>{terms.date}</p>
            <br/>
            {terms.list.map(article => {
                return (
                    <Fragment key={Math.random()}>
                        <br/>
                        <p><b>{article.title}</b></p>
                        {article.body.map(text => {
                            return (
                                <Fragment key={Math.random()}>
                                    <br/>
                                    <p 
                                        className='terms-and-cond-container-p-justify terms-and-cond-container-p-margin'
                                    >{text}</p>
                                </Fragment>
                            );
                        })}
                    </Fragment>
                );
            })}
            <br/>
            {terms.conclusion.map(text => {
                return (
                    <Fragment key={Math.random()}>
                        <br/>
                        <p  className='terms-and-cond-container-p-justify'>{text}</p>
                    </Fragment>
                )
            })}
        </div>
    )
}

export default TermsAndCondPage;