import { Fragment, useEffect, useState } from "react";

import emptyFolderImg from "../../../img/empty-folder.svg";

import "./accountProducts.scss";

import ConnectionError from "../../../components/connection-error/connectionError";
import Spinner from "../../../components/spinner/spinner";
import SaleMiniCard from "../saleMiniCard/saleMiniCard";

const AccountProducts = ({
    headerHeight,
    sales, 
    loadingRecords, 
    recordsConnectionError, 
    tryRecordsAgain,
    noTransactionsTitle,
    noTransactionsMsg
}) => {
    const [ empty, setEmpty ] = useState(() => {
        if (!sales.length > 0) {
            return true;
        } else {
            return false;
        }
    });

    useEffect(() => {
        if (!sales.length > 0) {
            setEmpty(true);
        } else {
            setEmpty(false);
        }
    }, [sales]);

    const onClickLink = (id) => {
        return;
    }

    const [ onTop, ] = useState(false);

    return (
        <div className="customer-products-main-container">
            <div 
                className="cust-account-products-records"
                style={{
                    minHeight: `calc(100vh - ${headerHeight + 175 - 40 + (onTop ? 0 : 40) }px)`, 
                    maxHeight: `calc(100vh - ${headerHeight + 175 - 40 + (onTop ? 0 : 40)}px)`,
                }}   
            >
                {loadingRecords ?
                    <div 
                        className="cust-account-history-spinner-cont"
                    >
                        <Spinner />
                    </div> 
                :
                 <Fragment>
                    {recordsConnectionError ? <ConnectionError onTryAgain={tryRecordsAgain} /> : <Fragment>
                        {empty ? 
                            <div className="no-products-yet-main-container">
                                <img className="no-products-yet-main-img" src={emptyFolderImg} alt="No products yet" />
                                <p><b>{noTransactionsTitle}</b></p>
                                <p>{noTransactionsMsg}</p>
                            </div>
                        : 
                            <div className="cust-prod-all-cards-main-cont">
                                {sales.map((sale) => {
                                    return (
                                        <SaleMiniCard
                                            key={sale.id}
                                            sale={sale}
                                            activeId={null}
                                            setActiveReference={null}
                                            onClickLink={onClickLink}
                                            width={null}
                                        />
                                    );
                                })}
                            </div>
                        }
                    </Fragment>}
                </Fragment>}
            </div>
        </div>
    );
}

export default AccountProducts;